import React, {
  useEffect,
  useState,
  // useCallback,
  useMemo,
} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  StyleSheet,
} from 'react-native'

import DetailView from '@components/detailview'

import Wrapper from '@screens/pit/tablemanagement/layout/wrapper'
import TableList from '@screens/base/tablelist'

import Styles, {
  CommonStyle as cs,
  // scaleSize,
} from '@styles'
// import Styles from '@styles'
const { SC/* , Colors */ } = Styles

// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
// import Log from '@utils/log'

import { useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const PageMain = (props) => {
  const {
    // navigation,
    // route,
    onChangePage,
  } = props

  const table = useSelector(state => state.table)
  const tables = table?.tables || []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [attrsOverview] = useState([
    { label: 'Total Table Opened', key: 'totalOpened' },
    { label: 'Total Table Closed', key: 'totalClosed' },
    { label: 'Total Drop', key: 'totalDrop' },
    { label: 'Total Win/Lose', key: 'totalWinLose' },
    { label: 'Total Hold', key: 'totalHold' },
    { label: 'Total Fill - Credit', key: 'totalFillCredit' },
  ])

  const [dataOverview] = useState({
    totalOpened: 0,
    totalClosed: 0,
    totalDrop: 0,
    totalWinLose: 0,
    totalHold: 0,
    totalFillCredit: 0,
  })

  const [attrsBlank] = useState([
    { label: 'Total Player Check In', key: 'totalPlayer' },
    { label: 'Table Name', key: 'tableName' },
    { label: 'Opening Time', key: 'opening' },
    { label: 'Time', key: 'time' },
    { label: 'Drop', key: 'drop' },
    { label: 'Total Win/Lose', key: 'total' },
    { label: 'Jackpot Amount', key: 'jackpot' },
  ])

  const [dataBlank] = useState({
    totalPlayer: 0,
    tableName: '-',
    opening: '00:00:00',
    time: '00:00:00',
    drop: 0,
    total: 0,
    jackpot: 0,
  })

  const [attrs] = useState([
    { label: 'Number of Game', key: 'totalGame' },
    { label: 'Opening Float', key: 'float', vtype: 'price' },
    { label: 'Duration', key: 'duration' },
    { label: 'Drops', key: 'totalDrops', vtype: 'price' },
    { label: 'Win/Lose', key: 'totalWinLose', vtype: 'price' },
    { label: 'Hold %', key: 'totalHold' },
    { label: 'Last Count', key: 'lastCount' },
    { label: 'Fill - Credit', key: 'fillCredit', vtype: 'price' },
    { label: 'Last Drop', key: 'lastDrop' },
  ])

  const [data] = useState({
    totalGame: 6,
    float: 20530,
    duration: '2:04:00',
    totalDrops: 15000,
    totalWinLose: 5060,
    totalHold: 1000,
    lastCount: '0:00:00',
    fillCredit: 150000,
    lastDrop: '0:00:00/RM0',
  })

  const [list, setList] = useState([])

  useEffect(() => {
    let list = _.map(tables, (tb) => {
      return {
        ...tb,
        isSelected: false,
      }
    })
    setList([].concat(list))
  }, [tables])

  // methods

  const onTableAdd = () => {
    onChangePage('opening')
  }

  const onTableSelect = (item) => {
    console.log('pagemain: onTableSelect', item)
    // sync table data
    let found = _.find(list, (l) => l.uuid === item?.uuid)
    let foundIndex = _.findIndex(list, (l) => l.uuid === item?.uuid)
    if (found && foundIndex >= 0) {
      for (let l in list) {
        let item = list[l]
        if (item.uuid !== found.uuid) {
          item.isSelected = false
        }
      }
      found.isMinMax = true
      found.isSelected = !found.isSelected
      setList([].concat(list))
    }
  }

  const renderSide = (/* props */) => {
    return (
      <TableList
        data={list}
        withAdd={true}
        onAdd={onTableAdd}
        onSelect={onTableSelect}
        canUnselect={true}
        {...props}
      />
    )
  }

  const model = useMemo(() => {
    let filtered = _.filter(list, (l) => l.isSelected)
    let dataResult = {}
    if (list.length <= 0) {
      dataResult = dataBlank
    } else if (filtered.length > 0) {
      dataResult = data
    } else {
      dataResult = dataOverview
    }
    return {
      ...dataResult,
    }
  }, [list])

  const attributes = useMemo(() => {
    let filtered = _.filter(list, (l) => l.isSelected)
    let attrsResult = []
    if (list.length <= 0) {
      attrsResult = attrsBlank
    } else if (filtered.length > 0) {
      attrsResult = attrs
    } else {
      attrsResult = attrsOverview
    }
    return [].concat(attrsResult)
  }, [list])

  const renderMain = (/* props */) => {
    return (
      <DetailView
        style={[styles.summary, cs.pa]}
        model={model}
        labelStyle={{ fontSize: 12 * SC.SCALE }}
        valueStyle={{ fontSize: 12 * SC.SCALE }}
        attributes={attributes}
        {...props}
      />
    )
  }

  return (
    <Wrapper
      sideHeader={'Table No.'}
      renderSide={renderSide}
      renderMain={renderMain}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
})

PageMain.propTypes = {
  // navigation: PropTypes.any,
  // route: PropTypes.object,
  onChangePage: PropTypes.func,
}

export default PageMain
