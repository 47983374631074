import axios from 'axios'
import EnvConstants from '@constants/env'
// import Store from '../store'
import Log from '@utils/log'
// import Storage from '@utils/storage'
// import { logout, getToken } from '../apifire/auth'

// import { store } from '@store'
// import Auth from '@react-native-firebase/auth'

// store
let store
export const injectStore = _store => {
  store = _store
}

const service = axios.create({
  baseURL: EnvConstants.API_BASE,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json',
    // 'Client-Service': 'frontend-client',
    // 'Auth-Key': 'restapi'
  },
})

service.interceptors.request.use(async (config) => {
  // let oldAuthorization = config && config.headers && config.headers.Authorization
  let token = await store.getState().auth.token
  // if (oldAuthorization !== `Bearer ${token}`) {
  // Log.debug('axios: interceptor request config.headers.Authorization invalid', { oldAuthorization, token })
  const authorization  = config.headers.Authorization

  if ((!authorization || authorization.length === 0) && token && token.length > 0) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // }
  return config
}, err => {
  Log.error('axios: interceptor request error', err)
  Promise.reject(err)
})

const wrapDispatchError = async (dispatch, err) => {
  // auth re-get token implementation
  if (err && err.data &&
    (err.data.code === 'auth/id-token-expired')) {
    Log.log('api: auth error ' + err.data.code)
    let oldAuthorization = axios.defaults.headers.common.Authorization
    let token = await store.getState().auth.token
    if (oldAuthorization !== (`Bearer ${token}`)) {
      // only dispatch when different
      Log.log('api: auth bearer changed', {
        oldAuthorization,
        token,
      })
      await dispatch({
        type: 'LOGIN_TOKEN',
        data: token,
      })
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }

    // repeat request with new token
    let res = await axios.request(err.config)
    return res
  } else if (err && err.data &&
    (err.data.code === 'auth-invalid')) {

    // this error is reliant on server
    // response with no authorization header
    await dispatch([
      // { type: 'APPLICATION_RESET' },
      { type: 'LOGIN_RESET' },
      // { type: 'USER_RESET' },
    ])
    throw err
  }
}

service.interceptors.response.use(response => {
  // Log.error('axios: interceptor response data', response)
  if (response.status === 200) {
    if (response.data.status === 204) {
      return Promise.reject(response)
    } else if (response.data.status === 401) {
      return Promise.reject(response)
    } else if (response.data.status === 500) {
      return Promise.reject(response)
    }
  }
  return response.data
}, async (error) => {
  Log.error('axios: interceptor response error', error)
  if (
    error.message && error.message === 'refresh_cancel' ||
    error.message === 'api_cancel'
  ) {
    // Log.log('axios: interceptor cancelled')
    return Promise.reject({ status: 'cancel', data: 'cancel' })
  }
  let dispatch = store?.dispatch
  if (dispatch) {
    await wrapDispatchError(dispatch, error)
  } else {
    Log.error('axios: interceptor response invalid dispatch')
  }
  let status = error.response?.status
  let data = error.response?.data
  let message = error.message
  if (data && Object.prototype.hasOwnProperty.call(data, 'message')) {
    message = data.message
  }
  // Log.log('axios: interceptor error data', data)
  // Log.error('axios: interceptor response error', error.response)
  // Log.error('axios: interceptor response data', { status, data })
  return Promise.reject({ status, data, message })
})

export default service
