import React, {
  forwardRef,
  useImperativeHandle,
  // useEffect,
  // useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
  Text,
} from 'react-native'
import Button from '@components/button'
import Input from '@components/input'
// import Dialog from '@components/dialog'
// import Toast from '@components/toast'

import Styles from '@styles'
const { SC/* , Colors */ } = Styles

// import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'

// import { useSelector } from 'react-redux'
// import useLoading from '@hooks/useLoading'

const LoginView = forwardRef((props, ref) => {
  const {
    noButton,
    onLogin,
    buttonTitle,
    style,
  } = props

  useImperativeHandle(ref, () => ({
    username,
    password,
  }))

  // dispatch actions
  // const dispatch = useDispatch()
  // const [loading, setLoading] = useLoading()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  const [username, setUsername] = useState('admin')
  const [password, setPassword] = useState('11111111')

  return (
    <View style={[styles.container, style]}>
      <View style={styles.inputRow}>
        <Text style={styles.inputLabel}>Username:</Text>
        <Input
          value={username}
          data={{}}
          style={styles.input}
          onChangeValue={(data, value) => setUsername(value)}
          keyboardType={'text'}
        />
      </View>
      <View style={styles.inputRow}>
        <Text style={styles.inputLabel}>Password:</Text>
        <Input
          value={password}
          data={{}}
          style={styles.input}
          onChangeValue={(data, value) => setPassword(value)}
          type={'password'}
          keyboardType={'text'}
        />
      </View>
      {
        !noButton ?
        <Button
          style={styles.button}
          title={buttonTitle || 'Log In'}
          onPress={() => onLogin(username, password)}
        /> : null
      }
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  inputRow: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: SC.GAP * SC.SCALE,
    paddingBottom: 0,
    width: '70%',
  },
  inputLabel: {
    flex: 1,
    color: 'white',
    paddingRight: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP_S * SC.SCALE,
    alignSelf: 'flex-start',
  },
  input: {
    flex: 3,
    backgroundColor: 'white',
    color: 'black',
    paddingVertical: 4 * SC.SCALE,
    borderRadius: 2 * SC.SCALE,
    fontSize: 8 * SC.SCALE,
  },
  button: {
    paddingVertical: 4 * SC.SCALE,
    paddingHorizontal: 24 * SC.SCALE,
    marginTop: 12 * SC.SCALE,
  },
})

LoginView.displayName = 'LoginView'

LoginView.propTypes = {
  noButton: PropTypes.bool,
  onLogin: PropTypes.func,
  buttonTitle: PropTypes.string,
  style: PropTypes.object,
}

export default LoginView
