import React, {
  useEffect,
  // useCallback,
  useState,
  useMemo,
  // useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Dialog from '@components/dialog'
// import LoginView from '@screens/base/loginview'
// import Toast from '@components/toast'
import LoadingView from '@components/loadingview'
// import FlatList from '@components/flatlist'
// import FlatListItem from '@components/flatlistitem'

import StepIdentity from './stepidentity'
import StepProgress from './stepprogress'
import StepClosing from './stepclosing'
import StepSuccess from './stepsuccess'

// import Log from '@utils/log'
// import { useNavigation } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import { useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'
// import { clearTable } from '@actions/table'

import Styles, { } from '@styles'
const { Colors } = Styles

// import ApiTable from '@apis/table'
// import ApiUser from '@apis/user'

const DialogClosing = (props) => {
  const {
    visible,
    setVisible,
    tableUuid,
    withIdentities,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  // const source = axios.CancelToken.source()
  // const dispatch = useDispatch()
  // const navigation = useNavigation()
  const auth = useSelector(state => state.auth)
  const identities = auth?.identities

  const [users, setUsers] = useState(identities)

  const STEP_IDENTITY = 0
  const STEP_PROGRESS = 1
  const STEP_CLOSING = 2
  const STEP_SUCCESS = 3
  const DEF_STEP = () => {
    return withIdentities ? STEP_IDENTITY : STEP_CLOSING
  }

  const [step, setStep] = useState(DEF_STEP())

  const steps = [
    { key: STEP_IDENTITY, label: 'Please scan/swipe your ID to close table' },
    { key: STEP_PROGRESS, label: 'Please scan/swipe your ID card, Progress' },
    { key: STEP_CLOSING, label: 'Please scan/swipe your ID card' },
    { key: STEP_SUCCESS, label: 'Success' },
  ]

  const stepCurr = useMemo(() => {
    if (step < 0 || step > steps.length) return {}
    return steps[step]
  }, [step])

  const stepKey = useMemo(() => {
    if (step < 0 || step > steps.length) return
    return steps[step]?.key
  }, [step])

  useEffect(() => {
    setStep(DEF_STEP())
  }, [visible])

  // const deinit = useCallback(() => {
  //   //
  // }, [])

  const renderIdentities = (step) => {
    return (
      <StepIdentity
        step={step}
        setLoading={setLoading}
        nextStep={() => setStep(STEP_PROGRESS)}
        closeStep={() => setStep(STEP_CLOSING)}
        users={users}
        setUsers={setUsers}
      />
    )
  }

  const renderProgress = (step) => {
    return (
      <StepProgress
        step={step}
        setLoading={setLoading}
        nextStep={() => setStep(STEP_IDENTITY)}
      />
    )
  }

  const renderClosing = (step) => {
    return (
      <StepClosing
        step={step}
        setLoading={setLoading}
        tableUuid={tableUuid}
      />
    )
  }

  const renderSuccess = (step) => {
    return (
      <StepSuccess
        step={step}
        setLoading={setLoading}
      />
    )
  }

  return (
    <Dialog
      visible={visible}
      minWidth={'60%'}
      minHeight={'60%'}
      setVisible={setVisible}
      style={styles.dialog}>
      {
        stepKey === STEP_IDENTITY ?
        renderIdentities(stepCurr) : null
      }
      {
        stepKey === STEP_PROGRESS ?
        renderProgress(stepCurr) : null
      }
      {
        stepKey === STEP_CLOSING ?
        renderClosing(stepCurr) : null
      }
      {
        stepKey === STEP_SUCCESS ?
        renderSuccess(stepCurr) : null
      }
      {
        loading ?
        <View style={styles.loader}>
          <LoadingView />
        </View> : null
      }
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_BG,
  },
  loader: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  // // content
  // title: {
  //   fontSize: 12 * SC.SCALE,
  //   color: 'white',
  // },
  // container: {
  //   // ...StyleSheet.absoluteFill,
  //   width: '100%',
  //   height: '100%',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   // backgroundColor: 'white',
  //   // display: 'flex',
  //   // flexDirection: 'row',
  //   flexDirection: 'column',
  // },
  // content: {
  //   flex: 1,
  //   width: '100%',
  //   alignItems: 'center',
  // },
  // top: {
  //   width: '100%',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  // buttons: {
  //   flexDirection: 'row',
  // },
  // listWrapper: {
  //   flex: 1,
  //   width: '80%',
  //   padding: SC.GAP_S * SC.SCALE,
  //   marginTop: SC.GAP * SC.SCALE,
  // },
  // list: {
  //   flexGrow: 1,
  //   // backgroundColor: Colors.DASH_MAIN,
  // },
  // loginWrapper: {
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  // buttonNext: {
  //   marginTop: SC.GAP * SC.SCALE * 2,
  //   backgroundColor: Colors.BUTTON_WIZ,
  //   paddingHorizontal: 22 * SC.SCALE,
  //   marginHorizontal: 3 * SC.SCALE,
  // },
  // buttonClose: {
  //   marginTop: SC.GAP * SC.SCALE * 2,
  //   backgroundColor: Colors.BUTTON_WIZ,
  //   marginHorizontal: 3 * SC.SCALE,
  // },
  // buttonReset: {
  //   marginTop: SC.GAP * SC.SCALE * 2,
  //   backgroundColor: Colors.BUTTON_RESET,
  //   marginHorizontal: 3 * SC.SCALE,
  // },
  // loaderWrapper: {
  //   flex: 1,
  //   width: '80%',
  //   backgroundColor: 'rgba(255,255,255,0.5)',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   borderRadius: 9,
  //   marginTop: SC.GAP * SC.SCALE * 2,
  // },
})

DialogClosing.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setLoading: PropTypes.func,
  tableUuid: PropTypes.string,
  withIdentities: PropTypes.bool,
}

export default DialogClosing
