import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  // useCallback,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  // StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
} from 'react-native'
// import Button from '@components/button'
// import Tabs from '@components/tabs'
// import Toast from '@components/toast'

// import Table from './layout/table'

import PlayerMain from './pages/main'
import PlayerDetail from './pages/detail'

// import Styles from '@styles'
// const { SC, Colors } = Styles

// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import AddToTable from './pages/addtotable'

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const DEFAULT_PAGE = 'main'

const Player = forwardRef((props, ref) => {
  const {
    navigation,
    route,
    screen,
  } = props

  useImperativeHandle(ref, () => ({
    // onInit, // not working
  }))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  // const isFocused = useIsFocused()
  // const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    const routePage = route.params?.page
    // Log.log('player: init()', routePage)
    setPage(routePage)
  }, [screen, route])

  // useEffect(() => {
  //   Log.log('player: init', { isFocused })
  //   if (isFocused) {
  //     init()
  //   }
  //   return deinit
  // }, [isFocused])

  // const init = useCallback(async () => { // todo: implement
  //   Log.log('player: init')
  //   try {
  //     setLoading(true)
  //     // stub
  //     setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //     Toast.show(`Init error: ${error}`)
  //   }
  // }, [])

  // const deinit = useCallback(() => {
  //   source.cancel('api_cancel')
  // }, [source])

  const [pages] = useState([
    { id: 'main', component: PlayerMain },
    { id: 'detail', component: PlayerDetail },
    { id: 'addtotable', component: AddToTable },
  ])
  const [page, setPage] = useState(route?.params?.player_uuid && route?.params?.page ? route?.params?.page : DEFAULT_PAGE)

  const onChangePage = (page, params = {}) => {
    let pageparams = {
      ...route?.params,
      ...params,
      page,
    }
    navigation.setParams(pageparams)
    // let naviparams = {
    //   name: 'home',
    //   params: pageparams,
    // }
    // navigation.navigate(naviparams)
    setPage(page || DEFAULT_PAGE)
  }

  const renderComponent = () => {
    let info = _.find(pages, (p) => p.id === page)
    let Component = info?.component
    if (!Component) {
      Component = PlayerMain
    }
    return (
      <Component onChangePage={onChangePage} {...props} />
    )
  }

  return (
    renderComponent()
  )
})

// const styles = StyleSheet.create({
// })

Player.displayName = 'TableManagement'
Player.propTypes = {
  navigation: PropTypes.any,
  route: PropTypes.object,
}

export default Player
