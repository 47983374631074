import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  // TouchableOpacity,
  // View,
} from 'react-native'
// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import CheckBox from '@components/checkbox'
// import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC/* , Colors */ } = Styles
// import StringUtil from '@utils/string'

const FlatListText = (props) => {
  const {
    // navigation,
    model,
    attr,
    value,
    style,
  } = props

  return (
    <Text
      style={[
        styles.label,
        attr.style,
        style,
      ]}>
      { value(model, attr) }
    </Text>
  )
}

const styles = StyleSheet.create({
  label: {
    // flex: 1,
    color: 'white',
    fontSize: 9 * SC.SCALE,
    padding: SC.GAP_S * SC.SCALE,
  },
})

FlatListText.propTypes = {
  model: PropTypes.object,
  attr: PropTypes.object,
  value: PropTypes.func,
  style: PropTypes.any,
}

export default FlatListText
