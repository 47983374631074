import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  // Text,
  TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
import CheckBox from '@components/checkbox'
// import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'

import FlatListText from './typetext'
import FlatListTypeMinMax from './typeminmax'
import FlatListTypeStatus from './typestatus'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC, Colors } = Styles
import StringUtil from '@utils/string'

const FlatListItem = (props) => {
  const {
    // navigation,
    isHeader,
    model,
    attrs,
    onPress,
    // checkbox
    withCheckbox,
    onCheck,
    // min-max
    onMinMaxPress,
    // misc
    style,
    containerStyle,
  } = props

  let value = (model, attr) => {
    let value = attr?.value || model?.[attr?.key]
    if (attr?.vtype === 'price') {
      value = StringUtil.formatPrice(value || 0)
    }
    return value
  }

  return (
    <View
      style={[
        styles.containerOuter,
        style,
      ]}>
      {
        !isHeader && withCheckbox ?
          <View style={styles.checkboxContainer}>
            <CheckBox style={styles.checkbox} isSelected={model.isSelected} setSelected={(value) => onCheck(value, model)} />
          </View> :
          isHeader && withCheckbox ?
            <View style={styles.checkboxContainer}>
            </View> : null
      }
      <TouchableOpacity
        style={[
          styles.container,
          (containerStyle ? containerStyle : {}),
          (isHeader ? styles.containerHeader : {}),
          (model?.isSelected ? styles.containerSelected : {}),
        ]}
        onPress={() => onPress(model)}>
        {
          attrs && attrs.map((attr, aindex) => {
            if (isHeader) {
              return (
                <FlatListText key={`${aindex}`}
                  model={model}
                  attr={attr}
                  value={(model, attr) => attr?.label}
                  style={styles.labelHeader}
                />
              )
            }
            switch (attr.type) {
              case 'min-max': {
                return (
                  <FlatListTypeMinMax key={`${aindex}`}
                    model={model}
                    attr={attr}
                    value={value}
                    onMinMaxPress={onMinMaxPress}
                  />
                )
              }
              case 'status': {
                return (
                  <FlatListTypeStatus key={`${aindex}`}
                    model={model}
                    attr={attr}
                  />
                )
              }
              default: {
                return (
                  <FlatListText key={`${aindex}`}
                    model={model}
                    attr={attr}
                    value={value}
                  />
                )
              }
            }
          })
        }
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  containerOuter: {
    // margin: SC.GAP_S * SC.SCALE,
    // marginBottom: 0,
    padding: SC.GAP_S * SC.SCALE,
    paddingBottom: 0,
    flexDirection: 'row',
  },
  checkboxContainer: {
    flex: 0,
    flexBasis: 20 * SC.SCALE,
    padding: SC.GAP * SC.SCALE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
  },
  container: {
    flex: 1,
    alignItems: 'center',
    // backgroundColor: 'blue',
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG_PRESSED,
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'row',
  },
  containerSelected: {
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  label: {
    // flex: 1,
    color: 'white',
    fontSize: 9 * SC.SCALE,
    padding: SC.GAP_S * SC.SCALE,
  },
  containerHeader: {
    backgroundColor: 'transparent',
  },
  labelHeader: {
    fontSize: 6 * SC.SCALE,
  },
})

FlatListItem.propTypes = {
  // navigation: PropTypes.any,
  item: PropTypes.object,
  isHeader: PropTypes.bool,
  model: PropTypes.object,
  attrs: PropTypes.array,
  // onSetSelected: PropTypes.func,
  onPress: PropTypes.func,
  withCheckbox: PropTypes.bool,
  onCheck: PropTypes.func,
  onMinMaxPress: PropTypes.func,
  // misc
  style: PropTypes.any,
  containerStyle: PropTypes.any,
}

export default FlatListItem
