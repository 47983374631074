import React, {
  // useEffect,
  // useState,
  useMemo,
} from 'react'

import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
  // TouchableOpacity,
} from 'react-native'
import DetailView from '@components/detailview'
import PropTypes from 'prop-types'

import Styles, {
  CommonStyle as cs,
  // scaleSize
} from '@styles'
const { SC, Colors } = Styles

const Summary = (props) => {

  const {
    totalCashAmount,
    totalChipAmount,
  } = props

  const amountSummary = useMemo(() => {
    return {
      drop_amount: totalCashAmount,
      chips_amount: totalChipAmount,
    }
  }, [totalChipAmount, totalCashAmount])

  return (<View style={[styles.container, cs.pa]}>
    <DetailView
      model={amountSummary}
      labelStyle={{ fontSize: 20 }}
      valueStyle={{ fontSize: 20 }}
      attributes={[
        'drop_amount',
        {
          labelStyle: { color: 'red' },
          valueStyle: { color: 'red' },
          key: 'chips_amount',
        },
      ]} />
  </View>)
}

const styles = StyleSheet.create({
  container: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
})

Summary.propTypes = {
  totalCashAmount: PropTypes.number,
  totalChipAmount: PropTypes.number,
}


export default Summary