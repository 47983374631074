import { StyleSheet } from 'react-native'
import { default as SC } from './constants'
import Styles, { CommonStyle as cs } from '@styles'
import Colors from './colors'
// const { SC } = Constants


export default StyleSheet.create({
    rounded: {
        borderRadius: SC.BORDER_RADIUS,
    },
    roundedTop: {
        borderTopStartRadius: SC.BORDER_RADIUS,
        borderTopEndRadius: SC.BORDER_RADIUS,
    },
    roundedBottom: {
        borderBottomStartRadius: SC.BORDER_RADIUS,
        borderBottomEndRadius: SC.BORDER_RADIUS,
    },
    roundedStart: {
        borderTopStartRadius: SC.BORDER_RADIUS,
        borderBottomStartRadius: SC.BORDER_RADIUS,
    },
    roundedEnd: {
        borderTopEndRadius: SC.BORDER_RADIUS,
        borderBottomEndRadius: SC.BORDER_RADIUS,
    },
    pa: {
        padding: SC.GAP_S * SC.SCALE,
    },
    pt: {
        paddingTop: SC.GAP_S * SC.SCALE,
    },
    pb: {
        paddingBottom: SC.GAP_S * SC.SCALE,
    },
    ps: {
        paddingStart: SC.GAP_S * SC.SCALE,
    },
    pe: {
        paddingEnd: SC.GAP_S * SC.SCALE,
    },
    pv: {
        paddingHorizontal: SC.GAP_S * SC.SCALE,
    },
    ph: {
        paddingVertical: SC.GAP_S * SC.SCALE,
    },
    card: {
        flexGrow: 1,
        backgroundColor: Colors.DASH_MAIN,
        paddingHorizontal: 15 * SC.SCALE,
    },
})