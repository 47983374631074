import _ from 'lodash'
import { SET_PIT } from '@actions/pit'
import Log from '@utils/log'

const pitDefault = _.assign({}, {})

const pitReducer = (pit = pitDefault, action) => {
  switch (action.type) {
    case SET_PIT:
      Log.log('setPitInfo', action.pitInfo)
      return action.pitInfo
    default:
      return pit
  }
}

export default pitReducer
