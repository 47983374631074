import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  StyleSheet,
} from 'react-native'
import Button from '@components/button'
import Header from './header'

import Styles from '@styles'
const { SC, Colors } = Styles

const Tabs = (props) => {
  const {
    type,
    tabs,
    index,
    onChangeTab,
    styleActive,
    styleInactive,
    tabStyle,
  } = props

  return (
    tabs && index < tabs.length ?
      _.map(tabs, (tab, i) => {
        return (
          <Button
            type={type}
            style={[
              styles.tabButton,
              (i === index ? styles.tabButtonActive : {}),
              (i === index ? styleActive : {}),
              (i !== index ? styleInactive : {}),
              tabStyle,
            ]}
            title={tab.label || tab.name || '(null)'}
            key={`${i}`}
            onPress={() => onChangeTab(i)} />
        )
      }) : null
  )
}

const styles = StyleSheet.create({
  tabButton: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: 60 * SC.SCALE,
    padding: 5 * SC.SCALE,
    backgroundColor: Colors.DASH_TAB_INACTIVE,
  },
  tabButtonActive: {
    backgroundColor: Colors.DASH_TAB,
  },
})

Tabs.propTypes = {
  type: PropTypes.string,
  tabs: PropTypes.any.isRequired,
  index: PropTypes.any,
  onChangeTab: PropTypes.func,
  styleActive: PropTypes.any,
  styleInactive: PropTypes.any,
  tabStyle: PropTypes.any,
}

export {
  Header as TabHeader,
}
export default Tabs
