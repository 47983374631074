import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
// import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import ButtonHeader from '@components/buttonheader'
import Slider from '@components/slider'
// import Tabs from '@components/tabs'
import Toast from '@components/toast'
import DetailView from '@components/detailview'
import PropTypes from 'prop-types'
import ApiPlayer from '@apis/player'
import ApiUser from '@apis/user'

// import Table from '../layout/table'

import Styles from '@styles'
const { SC, Colors } = Styles

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import StringUtil from '@utils/string'
import { LabelValue } from '@components/detailview'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Adjustment from '../layout/adjustment'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import { scaleSize } from '../../../../styles'
import PlayerAvatar from '../layout/playeravatar'

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const PANEL_TABLE_INFO = 1
const PANEL_AVERAGE_BET = 2
const PANEL_WIN_LOSE = 3

const statAttrs = [
  'loyalty_point',
  'number_of_game',
  'play_time',
  { key: 'buy_in_amount', vtype: 'price' },
  { key: 'total_bet', vtype: 'price' },
  { key: 'total_winlose', label: 'Total Win/Lose', vtype: 'price' },
]

const PlayerDetail = (props) => {
  const {
    route,
    onChangePage,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const [panelView, setPanelView] = useState(PANEL_TABLE_INFO)
  const [avgBetVal, setAvgBetVal] = useState(0)
  const [winLoseVal, setWinLoseVal] = useState(0)
  const pit = useSelector(state => state.pit)
  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)
      const resp = await ApiPlayer.detail(source.token, route?.params?.player_uuid)
      setPlayerDetail(resp.data)
      // stub
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])


  const [playerDetail, setPlayerDetail] = useState({ table_uuid: '-' })


  const onAverangeBetPress = () => {
    setPanelView(PANEL_AVERAGE_BET)
  }

  const onWinLosePress = () => {
    setPanelView(PANEL_WIN_LOSE)
  }

  const onDeleteSession = async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      await ApiPlayer.deleteSession(source.token,
        accessToken,
        pit.uuid,
        route?.params?.player_uuid)
      Toast.success('Session Delete Successful')
      // stub
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }

  const getAccessToken = async () => {
    const respLogin = await ApiUser.login(source.token, 'admin', '11111111')
    return respLogin.data.access_token
  }

  const onAdjustmentUpdate = async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      await ApiPlayer.adjustAvgBet(source.token,
        accessToken,
        pit.uuid,
        route?.params?.player_uuid,
        avgBetVal)
      Toast.success('Average Bet Update Successful')
      // stub
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }

  const onWinLoseUpdate = async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      await ApiPlayer.adjustWinLose(source.token,
        accessToken,
        pit.uuid,
        route?.params?.player_uuid,
        winLoseVal)
      Toast.success('WinLose Update Successful')
      // stub
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.left}>
          <PlayerAvatar playerDetail={playerDetail} />
          {panelView === PANEL_AVERAGE_BET && <Adjustment
            label='Averange Bet'
            value={avgBetVal}
            onValueChange={setAvgBetVal}
            onUpdate={onAdjustmentUpdate} />}
          {panelView === PANEL_WIN_LOSE && <Adjustment
            label='Handle/WinLose'
            value={winLoseVal}
            onValueChange={setWinLoseVal}
            onUpdate={onWinLoseUpdate} />}
          {panelView === PANEL_TABLE_INFO && <DetailView
            model={playerDetail}
            attributes={[
              'table_no',
              'position',
              'seat_no',
            ]}
            style={styles.panel}
          />}
          {!playerDetail.table_uuid &&
            <Button title={'Add To Table'}
              style={styles.btnAddToTable}
              onPress={() => onChangePage('addtotable', { player_uuid: playerDetail.player_uuid })} />}
        </View>
        <View style={styles.right}>
          <View style={styles.top}>
            <ButtonHeader
              iconName={'trash-2'}
              onPress={onDeleteSession}
              style={styles.iconWrapper}>
              <Text style={[styles.iconText]}>
                Delete Session
              </Text>
            </ButtonHeader>
          </View>
          <DetailView
            model={playerDetail}
            attributes={statAttrs}
            style={styles.panel}
          />
          <TouchableOpacity onPress={onAverangeBetPress}>
            <LabelValue
              label='Average Bet'
              value={StringUtil.formatPrice(playerDetail.average_bet || 0)}
              style={panelView === PANEL_AVERAGE_BET ? styles.active : styles.inActive} />
          </TouchableOpacity>
          <TouchableOpacity onPress={onWinLosePress}>
            <LabelValue
              label='Handle/WinLose'
              value={StringUtil.formatPrice(playerDetail.handle_win_lose || 0)}
              style={panelView === PANEL_WIN_LOSE ? styles.active : styles.inActive} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: SC.GAP_S * SC.SCALE,
  },
  active: {
    backgroundColor: Colors.BUTTON_BG_PRESSED,
    paddingVertical: 4 * SC.SCALE,
    paddingHorizontal: 2 * SC.SCALE,
    borderRadius: 9,
  },
  inActive: {
    backgroundColor: Colors.BUTTON_BG,
    paddingVertical: 4 * SC.SCALE,
    paddingHorizontal: 2 * SC.SCALE,
    borderRadius: 9,
  },
  content: {
    flex: 1,
    borderRadius: 9,
    borderTopLeftRadius: 0,
    backgroundColor: Colors.DASH_MAIN,
    flexDirection: 'row',
  },
  // layouts
  left: {
    flex: 3,
    padding: SC.GAP * SC.SCALE,
    alignItems: 'stretch',
  },
  right: {
    flex: 5,
    padding: SC.GAP * SC.SCALE,
  },
  top: {
    with: '100%',
    alignItems: 'flex-end',
    marginBottom: SC.GAP * SC.SCALE,
  },
  // header
  iconWrapper: {
    // margin: 10 * SC.SCALE,
    marginBottom: 2 * SC.SCALE,
    marginLeft: 4 * SC.SCALE,
    marginRight: 4 * SC.SCALE,
    // position: 'absolute',
    top: 4 * SC.SCALE,
    right: 4 * SC.SCALE,
  },
  iconText: {
    color: 'white',
    fontSize: 5 * SC.SCALE,
    position: 'absolute',
    bottom: -12 * SC.SCALE,
    width: 40 * SC.SCALE,
    textAlign: 'center',
  },
  // components
  control: {
    width: '100%',
    backgroundColor: Colors.DASH_LIGHT,
    padding: SC.GAP * SC.SCALE,
    borderRadius: 9,
    marginTop: SC.GAP * SC.SCALE,
  },
  panel: {
    marginTop: 6 * SC.SCALE,
    width: '100%',
  },
  btnAddToTable: {
    marginTop: scaleSize(8),
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

PlayerDetail.propTypes = {
  // navigation: PropTypes.any,
  onChangePage: PropTypes.func,
  route: PropTypes.object,
}

export default PlayerDetail
