
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import Button from '@components/button'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles


const JackpotStatus = (props) => {

  const {
    onTrigger,
  } = props

  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const onReset = () => {
    //
  }

  return (
    <View style={[styles.container]}>
      <View>
        <Text style={[styles.text]}>Barracat 66</Text>
        <Text style={[styles.text, cs.rounded, styles.value]}>RM 100,000.00</Text>
      </View>
      <View style={[{ flexDirection: 'row', paddingTop: scaleSize(5), justifyContent: 'space-evenly' }]}>
        <Button
          title="Trigger"
          onPress={onTrigger}
          style={styles.button} />
        <Button
          title="Reset Value"
          disabled={false}
          onPress={onReset}
          style={[styles.button, styles.buttonReset]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: scaleSize(25),
    textAlign: 'center',
  },
  value: {
    marginTop: scaleSize(5),
    color: 'white',
    fontWeight: 'normal',
    borderWidth: scaleSize(1),
    borderColor: 'white',
    paddingHorizontal: scaleSize(25),
    paddingVertical: scaleSize(10),
  },
  buttonReset: {
    backgroundColor: Colors.GREY,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  button: {
    marginTop: scaleSize(8),
    marginHorizontal: scaleSize(10),
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
    width: scaleSize(80),
  },
})

JackpotStatus.propTypes = {
  onTrigger: PropTypes.func,
}

export default JackpotStatus
