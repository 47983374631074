import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import axios from 'axios'
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'

import Log from '@utils/log'
import { useIsFocused } from '@react-navigation/native'

import { useSelector, useDispatch } from 'react-redux'
import Button from '@components/button'
import Dialog from '@components/dialog'
// import Dialog from '@components/dialog'
import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Slider from '@components/slider'
import ApiTable from '@apis/table'
import ApiPlayer from '@apis/player'
import axios from 'axios'

import Styles from '@styles'
const { SC, Colors } = Styles

const source = axios.CancelToken.source()
const DlgAddPlayer = (props) => {
  const {
    visible,
    setVisible,
    onAddPlayer,
    seatNo,
    position = 'sit',
  } = props

  const table = useSelector(state => state.table)
  const onEnter = () => setVisible(false)

  const onAddPlayerClick = useCallback(async (playerId) => {
    try {
      if (playerId) {
        const respCreate = await ApiPlayer.login(source.token, playerId)
        const player = respCreate.data
        const resp = await ApiTable.assignSeat(source.token, table.uuid, player.uuid, position, seatNo)
      }
      else {
        const respCreate = await ApiPlayer.createGuest(source.token, table.uuid)
        const player = respCreate.data
        const resp = await ApiTable.assignSeat(source.token, table.uuid, player.uuid, position, seatNo)
      }
      onAddPlayer?.(playerId)
    } catch (e) {
      Toast.exception(e, 'Add Player Fail')
    }

  }, [onAddPlayer])

  return (
    <Dialog
      visible={visible}
      minWidth={'60%'}
      minHeight={'50%'}
      setVisible={setVisible}>
      <View style={[styles.wrapper]}>
        <Button
          title="Scan/Swipe Member ID"
          titleStyle={styles.buttonFont}
          onPress={() => onAddPlayerClick('cGxheWVyXzE6MTIzMTIz')}
          style={styles.buttonReset} />
        <Button
          title="Check In Anonyous"
          titleStyle={styles.buttonFont}
          onPress={() => onAddPlayerClick(undefined)}
          style={styles.buttonReset} />
        <Button
          title="Enter"
          onPress={onEnter}
          style={styles.button} />
      </View>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  // content
  wrapper: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
  },
  button: {
    // width: '100%',
    // flex: 1,
    marginTop: 10 * SC.SCALE,
    backgroundColor: Colors.BUTTON_WIZ,
  },
  buttonReset: {
    width: '70%',
    height: 30 * SC.SCALE,
    marginTop: 10 * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    marginRight: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonFont: {
    color: "white",
    fontSize: 16,
  },
})

DlgAddPlayer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onAddPlayer: PropTypes.func,
  seatNo: PropTypes.number,
  position: PropTypes.string,
}

export default DlgAddPlayer
