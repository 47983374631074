import React, {
  // useEffect,
  // useState,
  // useCallback,
} from 'react'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import PropTypes from 'prop-types'
import StringUtil from '@utils/string'
import Slider from '@components/slider'

import Styles from '@styles'
const { SC, Colors } = Styles

const Adjustment = (props) => {

  const {
    label,
    value = 0,
    min = 0,
    max = 999,
    onValueChange,
    onUpdate,
  } = props

  return (<View style={styles.container}>
    <Text style={styles.subtitle}>
      {label}
    </Text>
    <Text style={[styles.title, styles.center, styles.padding]}>
      {StringUtil.formatPrice(value)}
    </Text>
    <Slider
      width={130}
      minimumValue={min}
      maximumValue={max}
      step={1}
      value={value}
      setValue={onValueChange}>
    </Slider>
    <View style={styles.controlButtons}>
      <Button title={'Reset'} style={styles.buttonReset} onPress={() => onValueChange?.(0)} />
      <Button title={'Update'} style={styles.buttonSubmit} onPress={onUpdate} />
    </View>
  </View>)
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.DASH_LIGHT,
    padding: SC.GAP * SC.SCALE,
    borderRadius: 9,
    marginTop: SC.GAP * SC.SCALE,
  },
  controlButtons: {
    flexDirection: 'row',
    marginTop: SC.GAP_S * SC.SCALE,
  },
  buttonReset: {
    flex: 1,
    backgroundColor: Colors.BUTTON_RESET,
  },
  buttonSubmit: {
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
    marginLeft: SC.GAP_S * SC.SCALE,
  },
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  subtitle: {
    fontSize: 9 * SC.SCALE,
    color: 'white',
  },
  center: {
    alignSelf: 'center',
  },
})

Adjustment.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onValueChange: PropTypes.func,
  onUpdate: PropTypes.func,
  route: PropTypes.object,
}


export default Adjustment