import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  TextInput,
  // View,
} from 'react-native'

import Styles from '@styles'
const { SC } = Styles

const Input = (props) => {
  const {
    value,
    style,
    data,
    type,
    keyboardType,
    placeholder,
    onFocus,
    onChangeValue,
  } = props

  let inprops = { ...props }
  if (inprops.style) delete inprops.style
  return (
    <TextInput style={[styles.input, style]}
      value={value}
      onFocus={onFocus}
      onChangeText={(value) => onChangeValue(data, value)}
      type={type || 'text'}
      placeholder={placeholder}
      keyboardType={keyboardType || 'numeric'}
      {...inprops}>
    </TextInput>
  )
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    border: '1px solid white',
    borderRadius: 4 * SC.SCALE,
    color: 'white',
    padding: 2 * SC.SCALE,
    textAlign: 'center',
  },
})

Input.propTypes = {
  value: PropTypes.any,
  style: PropTypes.any,
  data: PropTypes.any,
  type: PropTypes.string,
  keyboardType: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChangeValue: PropTypes.func,
}

export default Input
