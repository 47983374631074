
import {
  StyleSheet,
  // Text,
  TouchableOpacity,
  // View,
  // Image,
  // FlatList,
} from 'react-native'
import React, {
  // useEffect,
  // useState,
} from 'react'
// import Log from '@utils/log'
import PropTypes from 'prop-types'
import Styles, {
  // CommonStyle as cs,
  scaleSize,
} from '@styles'
// import ProgressBar from '../progressbar'
import Feather from '@components/icons/feather'

const { SC, Colors } = Styles

const ButtonExpand = (props) => {
  const {
    data,
    isExpand,
    onToggleExpand,
  } = props

  return (
    <TouchableOpacity
      onPress={() => onToggleExpand(data)}
      style={[styles.roundedBtn]}>
      <Feather
        style={[styles.icon]}
        name={isExpand ? 'chevron-up' : 'chevron-down'}
        size={9 * SC.SCALE}>
      </Feather>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    color: 'white',
  },
  roundedBtn: {
    // borderWidth: 1 * SC.SCALE,
    borderRadius: scaleSize(11),
    borderColor: 'white',
    height: scaleSize(11),
    width: scaleSize(11),
    justifyContent: 'center',
    backgroundColor: Colors.BUTTON_BG_PRESSED,
    alignItems: 'center',
  },
  roundedIcon: {
    borderWidth: 0.5 * SC.SCALE,
    borderRadius: scaleSize(11),
    borderColor: 'white',
    height: scaleSize(11),
    width: scaleSize(11),
    justifyContent: 'center',
    alignItems: 'center',
  },
})

ButtonExpand.propTypes = {
  data: PropTypes.object,
  isExpand: PropTypes.bool,
  onToggleExpand: PropTypes.func,
}

export default ButtonExpand
