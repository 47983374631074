import React, { } from 'react'
import PropTypes from 'prop-types'
import {
    ActivityIndicator,
    // SafeAreaView,
    // StatusBar,
    StyleSheet,
    // Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'

// styles
import Styles from '@styles'
const { SC/* , Colors */ } = Styles

const LoadingView = (props) => {
    const {
        children,
        style,
        loading,
    } = props

    return (
        <View style={[styles.container, style]}>
            {
                loading === true || loading > 0 || !children ?
                    <ActivityIndicator style={styles.loader} color={'white'} size={12 * SC.SCALE} />
                    : children
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        // width: '100%',
        // height: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    loader: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
})

LoadingView.propTypes = {
    children: PropTypes.any,
    style: PropTypes.any,
    loading: PropTypes.bool,
}

export default LoadingView
