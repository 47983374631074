import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import InputSearch from '@components/inputsearch'
import Tabs from '@components/tabs'
import Toast from '@components/toast'
import ApiPlayer from '@apis/player'
import Table from '../layout/table'

import Styles from '@styles'
const { SC, Colors } = Styles

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
// import Log from '@utils/log'

import { /*useDispatch,*/ useSelector } from 'react-redux'
import { scaleSize } from '../../../../styles'
import DlgSearchResult from '../layout/dlgsearchresult'
// import { clearAuth } from '@actions/auth'


const TAB_CARD = 0
const TAB_GUEST = 1
const tabs = [{
  id: TAB_CARD,
  name: 'card',
  label: 'Card',
}, {
  id: TAB_GUEST,
  name: 'guest',
  label: 'Guest',
}]

const PlayerMain = (props) => {
  const {
    onChangePage,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const pit = useSelector(state => state.pit)

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)

      let [cardPlayers, guestPlayers] = await Promise.all([
        ApiPlayer.cardPlayers(source.token, pit.uuid),
        ApiPlayer.guestPlayer(source.token, pit.uuid),
      ])

      setCardPlayers(cardPlayers.data)
      setGuestPlayers(guestPlayers.data)


      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.exception(error, 'Fail to get players')
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [tabIndex, setTabIndex] = useState(0)
  const [searchKey] = useState('')
  const [searchResults, setSearchResults] = useState([])

  // const [search, setSearch] = useState('')

  const [cardPlayers, setCardPlayers] = useState([])
  const [guestPlayers, setGuestPlayers] = useState([])
  const [showSearchResult, setShowSearchResult] = useState(false)

  const styleCol = {
    flex: 1,
  }

  const [attrs] = useState([
    { key: 'player_name', label: 'Name', style: { width: scaleSize(180) } },
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'position', label: 'Position', style: styleCol },
    { key: 'check_in_time', label: 'Check In Time', style: styleCol },
    { key: 'total_buy_in', label: 'Total Buy In', style: styleCol, vtype: 'price' },
    { key: 'average_bet', label: 'Average Bet', style: styleCol, vtype: 'price' },
    { key: 'win_lose', label: 'Win/Lose', style: styleCol, vtype: 'price' },
  ])

  const [attrsGuest] = useState([
    // { key: 'name', label: 'Name', style: { flex: 1 } },
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'position', label: 'Position', style: styleCol },
    { key: 'check_in_time', label: 'Check In Time', style: styleCol },
    { key: 'total_buy_in', label: 'Total Buy In', style: styleCol, vtype: 'price' },
    { key: 'average_bet', label: 'Average Bet', style: styleCol, vtype: 'price' },
    { key: 'win_lose', label: 'Win/Lose', style: styleCol, vtype: 'price' },
  ])


  const onPress = useCallback((data) => onChangePage('detail', { player_uuid: data.player_uuid }), [])
  const onSearchChange = async (searchKey) => {
    if (searchKey && searchKey.length < 1) {
      return
    }
    setLoading(true)
    try {
      const resp = await ApiPlayer.search(source.token, pit.uuid, searchKey)

      setSearchResults(resp.data)
      setShowSearchResult(resp.data.length != 0)
    } catch (error) {
      Toast.exception(error, 'Fail to get players')
    }
    setLoading(false)
  }

  const onItemSelected = (item) => {
    setShowSearchResult(false)
    onPress(item)
  }

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <Tabs
          tabs={tabs}
          index={tabIndex}
          onChangeTab={setTabIndex}
        />
        <View style={styles.searchWrap}>
          <View style={styles.searchInner}>
            <InputSearch
              search={searchKey}
              onSearchChanged={onSearchChange}
            />
          </View>
        </View>
      </View>
      <View style={styles.content}>
        {
          tabIndex === TAB_CARD ?
            <Table
              data={cardPlayers}
              attrs={attrs}
              onPress={onPress}
              style={styles.inner}
              {...props}
            /> : null
        }
        {
          tabIndex === TAB_GUEST ?
            <Table
              data={guestPlayers}
              attrs={attrsGuest}
              onPress={onPress}
              style={styles.inner}
              {...props}
            /> : null
        }
      </View>
      <DlgSearchResult
        visible={showSearchResult}
        setVisible={setShowSearchResult}
        searchResults={searchResults}
        onItemSelected={onItemSelected}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    padding: SC.GAP_S * SC.SCALE,
  },
  top: {
    width: '100%',
    flexDirection: 'row',
  },
  searchWrap: {
    flex: 1,
    alignItems: 'flex-end',
  },
  content: {
    flex: 1,
    width: '100%',
    height: '100%',
    borderRadius: 9,
    borderTopLeftRadius: 0,
    backgroundColor: Colors.DASH_MAIN,
  },
})

PlayerMain.propTypes = {
  // navigation: PropTypes.any,
  onChangePage: PropTypes.func,
}

export default PlayerMain
