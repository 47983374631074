import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import Container from '@screens/base/container'
import Toast from '@components/toast'
import Button from '@components/button'
// import Input from '@components/input'
// import Feather from '@components/icons/feather'
// import DialogMessage from '@screens/base/dialogmessage'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Wrapper from '../wiz-layout/wrapper'

import StepSummary from './layout/stepsummary'
import StepRole from './layout/steprole'
import StepIdentity from './layout/stepidentity'
import StepSuccess from './layout/stepsuccess'

import Styles from '@styles'
const { SC, Colors } = Styles

// react-redux
import { useSelector, useDispatch } from 'react-redux'
import { PARAM_TABLE_ROLE_ID, updateTable } from '@actions/table'
import { updateToken, updateName, updateIdentities } from '@actions/auth'

import ApiUser from '@apis/user'
import ApiTable from '@apis/table'

const EnterId = (props) => {
  const { navigation } = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  const tableRoleId = table && table.roleId

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  const [roles, setRoles] = useState([])
  const [roleIndex, setRoleIndex] = useState()

  // steps

  const STEP_SUMMARY = 0
  const STEP_ROLE = 1
  const STEP_IDENTITY = 2
  const STEP_SUCCESS = 3
  const [steps] = useState([{
    name: 'summary',
    title: 'Summary',
  }, {
    name: 'role',
    title: 'Please select your identity',
  }, {
    name: 'identity',
    title: 'Please scan or enter your ID Card',
  }, {
    name: 'success',
    title: 'Table Opened successful!',
  }])
  const [stepIndex, setStepIndex] = useState(0)

  // credential

  const [username, setUsername] = useState('admin')
  const [password, setPassword] = useState('11111111')
  const [status, setStatus] = useState()
  const [tableData, setTableData] = useState({})

  // const roleId = useMemo(() => {
  //   if (roleIndex == null || data == null) return
  //   if (roleIndex < 0 || roleIndex >= data.length) return
  //   return data[roleIndex] && data[roleIndex].id
  // }, [data, roleIndex])

  // init

  useEffect(() => {
    Log.log('wizard-enterid: init', { isFocused, token, STEP_SUCCESS })
    if (isFocused) {
      preload()
      init()
      if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
        Log.log('wizard-enterid: set step index', STEP_SUCCESS)
        setStepIndex(STEP_SUCCESS)
        setStatus('success')
      } else if (token && token.length > 0) {
        Log.log('wizard-enterid: set step index', STEP_SUCCESS)
        setStepIndex(STEP_SUCCESS)
      }
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
      return
    }
    // otherwise reset
    setStepIndex(STEP_SUMMARY)
  }

  const init = async () => {
    try {
      setLoading(true)
      let promises = []
      let promise
      promise = getRoles()
      promises.push(promise)
      promise = getTable()
      promises.push(promise)
      await Promise.all(promises)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const getTable = async () => {
    let response = await ApiTable.get(source.token, tableUuid)
    Log.log('wizard-enterid: getTable', response)
    if (response && response.data) {
      setTableData(response && response.data)
    }
  }

  const getRoles = async () => {
    let response = await ApiUser.getRoles(source.token, tableUuid)
    Log.log('wizard-enterid: getRoles', response)
    if (response && response.data) {
      let data = response.data
      data = _.map(data, (role) => {
        return {
          ...role,
          user: {},
        }
      })
      setRoles(data)
    }
  }

  // const isRoleLoggedIn = useCallback((roleId) => {
  //   let found = _.find(roles, r => r.id === roleId)
  //   return (found && found.user && !_.isEmpty(found.user))
  // }, [roles])

  const isRolesLoggedIn = useCallback(() => {
    for (let r in roles) {
      let role = roles[r]
      if (!role || !role.user || _.isEmpty(role.user)) return false
    }
    return true
  }, [roles])

  // header

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'home':
      case 'closing':
        return false
      case 'back':
      case 'account':
        return true
      default:
        return false
    }
  }, [])

  const isHeaderButtonDisabled = useCallback((name) => {
    switch (name) {
      case 'home':
      case 'closing':
      case 'account':
        return false
      case 'back':
        return false
      // return (stepIndex === STEP_SUCCESS && status === 'success')
      default:
        return false
    }
  }, [/* stepIndex, status */])

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'back':
        onWizardBack()
        break
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [stepIndex])

  const onWizardBack = useCallback(() => {
    Log.log('wizard-enterid: onWizardBack')
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1)
      return
    }
    if (stepIndex === 0) {
      navigation.navigate('wizard-selectfloat')
    }
  }, [stepIndex])

  // const onChangeCredential = useCallback((data, value) => {
  //   setCredential(value)
  // }, [])

  const onWizardNext = useCallback(async () => {
    Log.log('wizard-enterid: onWizardNext', { roles, roleIndex })
    let roleId = roles && roles[roleIndex] && roles[roleIndex].id
    // navigation.navigate('home')
    switch (stepIndex) {
      case STEP_SUMMARY: {
        break
      }
      case STEP_ROLE: {
        await dispatch(updateTable(PARAM_TABLE_ROLE_ID, roleId))
        break
      }
      case STEP_IDENTITY: {
        await onLogin(username, password)

        // clear role id
        await dispatch(updateTable(PARAM_TABLE_ROLE_ID, undefined))
        setRoleIndex(undefined)

        // if not all roles logged in, repeat step role
        if (!isRolesLoggedIn()) {
          setStepIndex(STEP_ROLE)
          return
        }
        break
      }
      case STEP_SUCCESS: {
        if (status !== 'success') {
          let result = await onOpenTable(roleId)
          if (result) setStatus('success')
          return
        } else {
          navigation.navigate('home')
        }
        break
      }
      default:
        break
    }
    if (stepIndex < STEP_SUCCESS) {
      setStepIndex(stepIndex + 1)
      return
    }
    if (stepIndex === STEP_SUCCESS) {
      return
    }
    Toast.show(`Invalid step index: ${stepIndex}`, {
      type: 'danger',
    })
  }, [stepIndex, status, roles, roleIndex])

  const onLogin = useCallback(async (username, password) => {
    try {
      setLoading(true)
      let response = await ApiUser.login(source.token, username, password)
      Log.log('wizard-enterid: login response', response)
      if (!response || !response.data || !response.data.access_token) {
        throw new Error('invalid login response or data or token')
      }
      let data = response.data
      // let token = data.access_token
      // await dispatch(updateToken(token))

      // save role info
      let all = roles
      let found = _.find(all, (a) => a.id === tableRoleId)
      if (found) {
        found.user = {
          username,
          ...data,
          'role_id': tableRoleId,
        }
      }
      let allIdentities = [].concat(all)
      setRoles(allIdentities)

      await dispatch(updateIdentities(allIdentities))

      let isAllLogged = isRolesLoggedIn()
      Toast.show(`Role ID: ${tableRoleId} Logged In!, isAllLogged?: ${isAllLogged ? 'true' : 'false'}`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Error login: ${error}`, {
        type: 'danger',
      })
    }
  }, [tableRoleId, roles])

  const onOpenTable = useCallback(async () => {
    let minBet = parseInt(table.min, 10)
    minBet = isNaN(minBet) ? null : minBet
    let maxBet = parseInt(table.max, 10)
    maxBet = isNaN(maxBet) ? null : maxBet
    let chips = _.map(table.chips, (chip) => {
      return {
        'chip_uuid': chip.chipUuid,
        quantity: chip.quantity,
      }
    })
    Log.log('wizard-enterid: open table', {
      tableUuid,
      minBet,
      maxBet,
      chips,
    })
    try {
      setLoading(true)

      // // validate
      // if (!roleId) throw new Error(`Invalid role ID: ${roleId}, ${JSON.stringify(data)}`)

      // open table
      let users = _.map(roles, (role) => {
        let user = (role && role.user) || {}
        return {
          role_id: role.id,
          user_id: user.user_id,
          name: user.name,
        }
      })
      let response = await ApiTable.open(source.token, tableUuid, minBet, maxBet, users, chips)
      Log.log('wizard-enterid: open table response', response)
      if (!response || !response.data) {
        throw new Error('invalid open table response data')
      }
      // let data = response && response.data
      // let tuuid = data && data.table_uuid

      // assign any access token
      let user = (roles && roles.length > 0 && roles[0].user)
      if (!user) throw new Error('invalid role user(s)')
      // let roleId = user.role_id
      let token = user.access_token // chicken & egg (access_token for assign)
      await dispatch(updateToken(token))
      await dispatch(updateName(user?.name))

      // // assign table UUID
      // response = await ApiTable.assign(source.token, tuuid, roleId)
      // if (!response || !response.data) {
      //   throw new Error('invalid assign table response data')
      // }
      // data = response && response.data

      // // save session data
      // let tableSessionUuid = data && data.table_session_uuid
      // await dispatch(updateTable(PARAM_TABLE_SESSION_UUID, tableSessionUuid))

      Toast.show(`Table opened successfully`, {
        type: 'success',
      })
      setLoading(false)
      return true
    } catch (error) {
      setLoading(false)
      Toast.show(`Error open table: ${error}`, {
        type: 'danger',
      })
      return false
    }
  }, [roles])

  // components

  const getStepTitle = useCallback((index) => {
    // Log.log('wizard-enterid: getStepTitle', index)
    let title = '(Unknown)'
    if (index >= 0 || index < steps.length) {
      title = steps[index] && steps[index].title
    }
    if (index === STEP_SUCCESS) {
      if (status == null || status.length <= 0) {
        title = 'Opening Table'
      } else if (status !== 'success') {
        title = `Error: ${status}`
      }
    }
    return title
  }, [status])

  const onRolePressed = useCallback((index) => {
    Log.log('wizard-enterid: onRolePressed')
    let roleId = roles && roles[index]
    if (roleId == null) {
      Toast.show('Invalid role ID', {
        type: 'danger',
      })
      return
    }
    // await dispatch(updateTable(PARAM_TABLE_ROLE_ID, roleId))
    setRoleIndex(index)
  }, [stepIndex, roles])

  const nextDisabled = useMemo(() => {
    Log.log('wizard-enterid: nextDisabled')
    let disabled = (tableUuid == null || tableUuid.length <= 0)
    switch (stepIndex) {
      case STEP_SUMMARY: {
        return (disabled)
      }
      case STEP_ROLE: {
        let invalidIndex = (roleIndex == null ||
          roleIndex < 0 ||
          roleIndex >= roles.length)
        return (disabled || invalidIndex)
      }
      case STEP_IDENTITY: {
        let invalidLogin = (
          !username || username.length <= 0 ||
          !password || password.length <= 0
        )
        return (disabled || invalidLogin)
      }
      case STEP_SUCCESS: {
        let isAllLogged = isRolesLoggedIn()
        return (disabled || !isAllLogged)
      }
      default:
        break
    }
    return true
  }, [stepIndex, tableUuid, roleIndex, username, password, token])

  const renderStepSummary = () => {
    // Log.log('wizard-enterid: renderStepSuccess')
    return (
      <StepSummary
        title={getStepTitle(stepIndex)}
        tableData={tableData}
        stepIndex={stepIndex}
      />
    )
  }

  const renderStepRole = () => {
    // Log.log('wizard-enterid: renderStepRole')
    return (
      <StepRole
        title={getStepTitle(stepIndex)}
        roles={roles}
        roleIndex={roleIndex}
        onRolePressed={onRolePressed}
      />
    )
  }

  const renderStepIdentity = () => {
    // Log.log('wizard-enterid: renderStepIdentity')
    return (
      <StepIdentity
        title={getStepTitle(stepIndex)}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
      />
    )
  }

  const renderStepSuccess = () => {
    // Log.log('wizard-enterid: renderStepSuccess')
    return (
      <StepSuccess
        title={getStepTitle(stepIndex)}
        status={status}
      />
    )
  }

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      isHeaderButtonDisabled={isHeaderButtonDisabled}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <Wrapper
        title={'Enter Employee ID'}
        {...props}>
        <View style={styles.wrapper}>
          {
            stepIndex === STEP_ROLE ?
              <View style={styles.top}>
                <View style={[styles.item, styles.itemTop]}>
                  <Text style={styles.value}>{getStepTitle(stepIndex)}</Text>
                </View>
              </View> : null
          }
          <View style={styles.center}>
            {
              stepIndex === STEP_SUMMARY ?
                renderStepSummary() : null
            }
            {
              stepIndex === STEP_ROLE ?
                renderStepRole() : null
            }
            {
              stepIndex === STEP_IDENTITY ?
                renderStepIdentity() : null
            }
            {
              stepIndex === STEP_SUCCESS ?
                renderStepSuccess() : null
            }
          </View>
          <View style={styles.bottom}>
            <Button
              title={`${(
                stepIndex === STEP_IDENTITY && !isRolesLoggedIn() ?
                  'Next Role' :
                stepIndex === STEP_SUCCESS ?
                  (status === 'success' ?
                    'Proceed Home' :
                    'Open') :
                    'Next')}`}
              disabled={nextDisabled}
              onPress={onWizardNext}
              style={[
                styles.button,
                (stepIndex === STEP_SUCCESS && status === 'success' ? styles.buttonSuccess : {}),
              ]} />
          </View>
        </View>
        {/* <DialogMessage /> */}
      </Wrapper>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  top: {
    flexWrap: 'wrap',
    // margin: SC.GAP_S * SC.SCALE,
    width: '100%',
    flexDirection: 'row',
  },
  center: {
    flex: 1,
    width: '80%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: 'white',
  },
  bottom: {
    flexWrap: 'wrap',
  },
  item: {
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG,
    alignItems: 'flex-start',
    padding: 6 * SC.SCALE,
    // flex: 1,
    flexDirection: 'row',
    margin: SC.GAP_S * SC.SCALE,
    // marginTop: 0,
    marginBottom: 0,
  },
  itemTop: {
    padding: 6 * SC.SCALE,
    height: 40 * SC.SCALE,
    flex: 1,
    marginTop: SC.GAP * 2 * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
  button: {
    width: 300 * SC.SCALE,
    margin: SC.GAP * 2 * SC.SCALE,
    backgroundColor: Colors.BUTTON_WIZ,
  },
  buttonSuccess: {
    backgroundColor: Colors.SUCCESS,
  },
  fontMini: {
    fontSize: 4 * SC.SCALE,
  },
})

EnterId.propTypes = {
  navigation: PropTypes.any,
}

export default EnterId
