import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  // StyleSheet,
  // Text,
  // View,
} from 'react-native'
// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import Feather from '@components/icons/feather'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
// import Styles from '@styles'
// const { SC /* , Colors */ } = Styles

const LIST_KEY = 'id'

const Table = (props) => {
  const {
    // navigation,
    data,
    attrs,
    // onAdd,
    onSetSelected,
    style,
  } = props

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        {...props}
      />
    )
  }

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={attrs}
        key={`${item?.[LIST_KEY]}`}
        onSetSelected={onSetSelected}
        {...props}
      />
    )
  }
  return (
    <FlatList
      style={[style]}
      data={data}
      renderItem={renderItem}
      ListHeaderComponent={renderHeader}
    />
  )
}

// const styles = StyleSheet.create({
// })

Table.propTypes = {
  // navigation: PropTypes.any,
  data: PropTypes.array,
  attrs: PropTypes.array,
  // onAdd: PropTypes.func,
  onSetSelected: PropTypes.func,
  style: PropTypes.object,
}

export default Table
