import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
  // Image,
} from 'react-native'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
// import Feather from '@components/icons/feather'
import FlatList from '@components/flatlist'
import ApiFillCredit from '@apis/fillcredit'
import Toast from '@components/toast'
// import ChipsControl from '@components/chipscontrol'
// import DetailView from '@components/detailview'

import ListItem from './overviewitem'
import Log from '@utils/log'

// import ApiFillCredit from '@apis/fillcredit'
// import { useSelector } from 'react-redux'
import useLoading from '@hooks/useLoading'

import Styles, {
  // CommonStyle as cs,
  // scaleSize,
} from '@styles'
import FlatListItem from '@components/flatlistitem'
// import FillCreditComponent from './fillcreditcomponent'
const { Colors } = Styles

const attrStyle = {
  flex: 1,
}
const attrs = [
  { key: 'table_no', label: 'Table No.', style: attrStyle },
  { key: 'transaction_type', label: 'Transaction Type', style: attrStyle },
  { key: 'request_time', label: 'Request Time', style: attrStyle },
  { key: 'completion_time', label: 'Completion Time', style: attrStyle },
  { key: 'status', label: 'Status', style: attrStyle },
  { key: 'amount', label: 'Amount', style: attrStyle },
  { key: 'perform_by', label: 'Performed By', style: attrStyle },
]

const Overview = (props) => {
  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  const source = axios.CancelToken.source()

  const {
    pitUuid,
    chipsMap,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  useEffect(() => {
    // console.log('overview: component')
    // if (chipsets.size > 0) {
    //   let chipset = chipsets.get(firstChipsetId)
    //   setChips(chipset.chips)
    // }
    getAllFillCredit()
  }, [])

  const getAllFillCredit = async () => {
    setLoading(true)
    try {
      const resp = await ApiFillCredit.overview(source.token, pitUuid)
      resp.data.forEach(item => item.isExpand = false)
      setItems(resp.data)
      // await getAllChips()
    } catch (e) {
      Log.log('error', e)
      Toast.exception(e, 'Fail to get status')
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   Log.log('overview: tableUuid changed', tableUuid)
  //   if (tableUuid == null) {
  //     setChips([])
  //     return
  //   }
  // }, [tableUuid])
  // const itemInfos = {
  //   isExpand: false,
  // }

  const [items, setItems] = useState([])

  const onSetSelected = (item) => {
    Log.log('overview: onSetSelected', item)
  }

  const onItemPressed = (item) => {
    Log.log('overview: onItemPressed', item)
  }

  // const onItemToggleExpand = useCallback((item) => {
  //   Log.log('overview: onItemToggleExpand', item)
  //   let uuid = item?.uuid
  //   let found = _.find(items, (it) => it.uuid === uuid)
  //   if (found) {
  //     found.isExpand = !found.isExpand
  //     setItems([].concat(items))
  //   }
  // }, [items])

  // const LIST_KEY = 'uuid'

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        onPress={onItemPressed}
        {...props}
      />
    )
  }

  const renderItem = ({ item }) => {
    return (
      <ListItem
        model={item}
        attrs={attrs}
        key={item?.fill_credit_uuid}
        onSetSelected={onSetSelected}
        // onToggleExpand={onItemToggleExpand}
        chipsMap={chipsMap}
        {...props}
      />
    )
  }


  return (
    <FlatList
      style={styles.container}
      data={items}
      renderItem={renderItem}
      keyExtractor={item => item.fill_credit_uuid}
      ListHeaderComponent={renderHeader}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 9,
    borderTopLeftRadius: 0,
  },
})

Overview.propTypes = {
  pitUuid: PropTypes.any.isRequired,
  chipsMap: PropTypes.any.isRequired,
}

export default Overview