import {
  StatusBar,
} from 'react-native'

const NavConstants = {
  HEIGHT_TOP: StatusBar.currentHeight,
  HEIGHT: 50,
}

export default NavConstants
