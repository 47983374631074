import api from '@apis'

// unauthenticated

const getPitUuid = (cancelToken, serialCode) => {
  var data = {
    serial_code: serialCode,
  }
  return api({
    url: `/api/pit/serial-code`,
    method: 'post',
    data,
    cancelToken,
  })
}

export default {
  getPitUuid,
}
