import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import Styles from '@styles'
const { SC, Colors } = Styles

const ListItem = (props) => {
  const {
    onPress,
    data,
    dataInfos, // key, label, style, styleText
    style,
  } = props
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => onPress(data)} style={[styles.wrapper, style]}>
        {
          dataInfos.map((info, iindex) => {
            return (
              <View
                style={[
                  (iindex === 0 ? styles.left : styles.right),
                  info.style,
                ]}
                key={`${iindex}`}>
                <Text
                  style={[
                    styles.text,
                    info.styleText,
                  ]}>
                  {data && data[info.key]}
                </Text>
              </View>
            )
          })
        }
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 9,
    paddingTop: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '80%',
    borderRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
    alignItems: 'center',
    padding: 10 * SC.SCALE,
    flexDirection: 'row',
  },
  left: {
    flex: 1,
  },
  right: {
    flexWrap: 'wrap',
  },
  text: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
  },
})

ListItem.propTypes = {
  onPress: PropTypes.func,
  data: PropTypes.any,
  dataInfos: PropTypes.array,
  style: PropTypes.any,
}

export default ListItem
