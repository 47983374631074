import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC, Colors } = Styles

const TableItem = (props) => {
  const {
    // navigation,
    item,
    isSelected,
    onPress,
  } = props

  return (
    <View style={styles.containerOuter}>
      <TouchableOpacity
        style={[
          styles.container,
          (isSelected ? styles.containerSelected : {}),
          item?.style,
        ]}
        onPress={() => onPress(item)}>
        <Text style={styles.label}>{item?.table_no || item?.label}</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  containerOuter: {
    padding: SC.GAP_S * SC.SCALE,
    paddingBottom: 0,
  },
  container: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'blue',
    padding: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
  containerSelected: {
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  label: {
    color: 'white',
    fontSize: 10 * SC.SCALE,
    // padding: SC.GAP_S * SC.SCALE,
  },
})

TableItem.propTypes = {
  // navigation: PropTypes.any,
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  onPress: PropTypes.func,
}

export default TableItem
