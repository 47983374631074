import { combineReducers } from 'redux'

import auth from './auth'
import user from './user'
import table from './table'
import log from './log'
import loader from './loader'
import pit from './pit'

export default combineReducers({ auth, user, table, log, loader, pit })
