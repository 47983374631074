import React, {
  // useEffect,
  // useState,
  // useCallback,
  // useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC } = Styles

const StepSuccess = (props) => {
  const {
    step,
  } = props

  return (
    <View style={[styles.container]}>
      <View style={styles.top}>
        <Text style={styles.title}>
          {step.label}
        </Text>
        {/* {
          (token == null || token.length <= 0) ?
          <LoginView
            ref={loginRef}
            noButton={true}
            onLogin={onLogin}
            buttonTitle={'Login'} /> : null
        } */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  top: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
})

StepSuccess.propTypes = {
  step: PropTypes.object,
}

export default StepSuccess
