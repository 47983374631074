import {
    StyleSheet,
    Text,
    // TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
import Log from '@utils/log'
import PropTypes from 'prop-types'
import ProgressListItem from './progresslistitem'

import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
const { SC, Colors } = Styles

const ProgressList = (props) => {

    const {
        title,
        listItems,
        allChips,
    } = props

    return (
        <View style={styles.container}>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.listContainer}>
                <FlatList
                    style={[styles.list, cs.rounded, cs.pa]}
                    data={listItems}
                    renderItem={({ item }) => (<ProgressListItem item={item} allChips={allChips}/>)}
                >
                </FlatList>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 2,
        alignContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: 'white',
        fontSize: 25,
        fontWeight: 'bold',
    },
    listContainer: {
        flexGrow: 1,
        alignSelf: 'stretch',
        padding: scaleSize(5),
    },
    list: {
        backgroundColor: Colors.BUTTON_BG,
    },
})

ProgressList.propTypes = {
    title: PropTypes.string,
    listItems: PropTypes.array.isRequired,
    allChips: PropTypes.any.isRequired,
}

export default ProgressList