import React, { forwardRef, useImperativeHandle, useEffect, useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // Alert,
  // Modal,
  // Platform,
  // Pressable,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useIsFocused } from '@react-navigation/native'

// import Constants from '@constants'
import Log from '@utils/log'
// import ChipCalculate from '@components/chips/calculate'

// import Button from '@components/button'
// import Dialog from '@components/dialog'
// import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Chips from '@components/chips'
// import Slider from '@components/slider'
// import ChipInputSlider from '@screens/base/chipinputslider'
// import ChipsControl from '@components/chipscontrol'
import { TabHeader } from '@components/tabs'

// import Fast from './layout/fast'
import FastDrop from './layout/fastdrop'
// import Sit from './layout/sit'
// import Stand from './layout/stand'
// import DialogDrop from './layout/dialogdrop'

import { CommonStyle as cs } from '@styles'
// const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

// import ApiPit from '@apis/pit'
// import ApiTable from '@apis/table'

const FastDropMain = forwardRef((props, ref) => {
  const {
    // navigation,
    setLoading,
  } = props
  const isFocused = useIsFocused()
  // const source = axios.CancelToken.source()
  const fastdropRef = useRef()

  useImperativeHandle(ref, () => ({
    onPreviousPressed: () => {
      // Log.log('fastdrop: onPreviousPressed')
      if (fastdropRef.current && typeof fastdropRef.current.onPreviousPressed === 'function') {
        fastdropRef.current.onPreviousPressed()
      }
    },
  }))

  // init
  const TAB_FAST = 'fast'
  // const TAB_SIT = 'sit'
  // const TAB_STAND = 'stand'

  // init
  const [tabs] = useState([{
    id: TAB_FAST,
    name: 'Fast Drop',
  // }, {
  //   id: TAB_SIT,
  //   name: 'Sitting',
  // }, {
  //   id: TAB_STAND,
  //   name: 'Standing',
  }])
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  useEffect(() => {
    Log.log('fastdrop: init', { isFocused })
    if (isFocused) {
      init()
    }
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('fastdrop: init')
    // try {
    //   setLoading(true)
    //   // API calls here
    //   await getTables()
    //   await getChipsets()
    //   setLoading(false)
    // } catch (error) {
    //   setLoading(false)
    //   Toast.show(`Init error: ${error}`)
    // }
  }, [])

  let childProps = {
    setLoading,
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <TabHeader
        tabs={tabs}
        selectedItem={selectedTab}
        onChangeTab={setSelectedTab}
      />
      <View style={styles.container}>
        {
          selectedTab.id === TAB_FAST &&
          <FastDrop {...childProps} ref={fastdropRef} />
        }
        {/* {
          selectedTab.id === TAB_SIT &&
          <Sit {...childProps} />
        }
        {
          selectedTab.id === TAB_STAND &&
          <Stand {...childProps} />
        } */}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    // borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    // flex: 1,
    flexGrow: 1,
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
})

FastDropMain.displayName = 'FastDropMain'

FastDropMain.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
}

export default FastDropMain
