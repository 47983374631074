// import Log from '@utils/log'

export const extractChipsWithQty = (chipSetMap) => {
  const chipsQty = []
  for (const chipSet of chipSetMap.values()) {
    const chips = chipSet.chips
    for (const chip of chips) {
      if (chip.count === 0) {
        continue
      }

      chipsQty.push({
        chip_uuid: chip.uuid,
        quantity: chip.count,
      })
    }
  }
  return chipsQty
}

export const resetChipQty = (chipSets) => {
  for (const chipSet of chipSets.values()) {
    chipSet.chips.forEach(item => item.count = 0)
  }
}