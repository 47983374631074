
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import Button from '@components/button'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const JackpotPlayers = (props) => {
  const {
    onReset
  } = props

  const [data, setData] = useState([
    { id: 1, player_name: 'Harper', player_id: '7402760', table_no: 'RL02', last_bet_value: '103002', play_time: '4:02:25', game_no: '92' },
    { id: 2, player_name: 'Thomas', player_id: '3790321', table_no: 'RL03', last_bet_value: '512', play_time: '3:37:58', game_no: '82' },
  ])

  const styleCol = {
    flexBasis: scaleSize(50),
  }
  const styleColMore = {
    flexBasis: scaleSize(70),
  }

  const [attrs] = useState([
    { key: 'player_name', label: 'Player Name', style: styleCol },
    { key: 'player_id', label: 'Player ID', style: styleColMore },
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'play_time', label: 'Play Time', style: styleCol },
    { key: 'game_no', label: 'Game#', style: styleCol },
    { key: 'last_bet_value', label: 'Last Bet Amount', style: styleColMore, vtype: 'price' },
  ])

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        {...props}
      />
    )
  }

  const [selectedPlayer, setSelectedPlayer] = useState(undefined)

  const onSetSelected = (item) => {
    setSelectedPlayer(item)
  }

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        containerStyle={selectedPlayer && item.id == selectedPlayer.id ? { backgroundColor: Colors.BUTTON_ACTIVE } : {}}
        model={item}
        attrs={attrs}
        key={`${item.id}`}
        onPress={() => onSetSelected(item)}
        {...props}
      />
    )
  }

  return (
    <View style={[styles.container, cs.roundedEnd, cs.roundedBottom]}>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item?.id}
        ListHeaderComponent={renderHeader}
        style={[styles.list]}
      />
      <View style={{ alignItems: 'center', paddingVertical: scaleSize(5) }}>
        <Button
          title="Enter Result"
          disabled={false}
          onPress={onReset}
          style={[styles.button, styles.buttonReset]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
  },
  button: {
    backgroundColor: Colors.BUTTON_WIZ,
    width: scaleSize(80),
  },
})

JackpotPlayers.propTypes = {
  // navigation: PropTypes.any,
  onReset: PropTypes.func,
}

export default JackpotPlayers
