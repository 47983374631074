import {
    StyleSheet,
    Text,
    // TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
import Log from '@utils/log'
import PropTypes from 'prop-types'
import ReqChips from './reqchips'
import ApiFillCredit from '@apis/fillcredit'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Toast from '@components/toast'

import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'

const { SC, Colors } = Styles

const ItemBody = (props) => {

    const table = useSelector(state => state.table)
    const tableUuid = table && table.uuid
    const source = axios.CancelToken.source()
    const [reqChips, setReqChips] = useState([])

    const {
        item,
        allChips,
    } = props

    // const [chips, setChips] = useState([])

    useEffect(() => {
        // setChips(ChipCalculate.mapChips(chips, [], 1))
        getChips()
    }, [])

    const getChips = async () => {
        try {
            const resp = await ApiFillCredit.chips(source.token, item.fill_credit_uuid)
            const reqChips = []
            resp.data.forEach(reqChip => {
                reqChip.chip = allChips.get(reqChip.chip_uuid)
                reqChips.push(reqChip)
            })
            setReqChips(reqChips)

        } catch (e) {
            Toast.exception(e, 'Fail to get chips')
        }
    }


    return (
        <View style={[styles.container, cs.rounded]}>
            <Text style={{ color: 'white', fontWeight: 'bold' }}>Total Chips Amount</Text>
            <ReqChips reqChips={reqChips} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 2,
        backgroundColor: Colors.BUTTON_BG_PRESSED,
        padding: scaleSize(5),
    },
    chipsContaner: {
        flexDirection: 'row',
        flexGrow: 4,
    },
})

ItemBody.propTypes = {
    item: PropTypes.any,
    allChips: PropTypes.any.isRequired,
}

export default ItemBody