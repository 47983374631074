
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import JackpotPlayers from './jackpotplayers'
import JackpotStatus from './jackpotstatus'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
import JackpotWinner from './jackpotwinner'
const { SC, Colors } = Styles

const SCREEN_JACKPOT = 1
const SCREEN_JACKPOT_PLAYERS = 2
const SCREEN_JACKPOT_WINNER = 3

const JackpotMain = (props) => {

  const {
    onTriggerJackpot,
  } = props

  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [screenState, setScreenState] = useState(SCREEN_JACKPOT)

  const onTrigger = () => {
    setScreenState(SCREEN_JACKPOT_PLAYERS)
    onTriggerJackpot?.()
  }

  return (
    <View style={[styles.container, cs.roundedEnd, cs.roundedBottom]}>
      {screenState === SCREEN_JACKPOT && <JackpotStatus onTrigger={onTrigger} />}
      {screenState === SCREEN_JACKPOT_PLAYERS && <JackpotPlayers onReset={() => setScreenState(SCREEN_JACKPOT_WINNER)} />}
      {screenState === SCREEN_JACKPOT_WINNER && <JackpotWinner onTrigger={onTrigger} onReset={() => setScreenState(SCREEN_JACKPOT)} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
    justifyContent: 'center',
    alignItems: 'stretch',
  },
})

JackpotMain.propTypes = {
  onTriggerJackpot: PropTypes.func,
}

export default JackpotMain
