import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // Alert,
  // Modal,
  // Platform,
  // Pressable,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useIsFocused } from '@react-navigation/native'

import Constants from '@constants'
import Log from '@utils/log'
// import ChipCalculate from '@components/chips/calculate'

// import Button from '@components/button'
// import Dialog from '@components/dialog'
import Toast from '@components/toast'
import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Chips from '@components/chips'
// import Slider from '@components/slider'
// import ChipInputSlider from '@screens/base/chipinputslider'

import Styles from '@styles'
const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'

const JackpotManagement = (props) => {
  const {
    // navigation,
    setLoading,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // init
  const [tabs, setTabs] = useState([{
    id: 1,
    name: 'Enter Result',
  }, {
    id: 2,
    name: 'History',
  }])
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    Log.log('jackpotmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('jackpotmanagement: init')
    try {
      setLoading(true)
      // API calls here
      await getTables()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [getTables, tabs, setTabs])

  const getTables = useCallback(async () => {
    let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    if (!response || !response.data || !response.data.uuid) {
      throw new Error('invalid response or data or uuid')
    }

    let pitUuid = response.data.uuid
    response = await ApiTable.getAll(source.token, pitUuid)
    Log.log('jackpotmanagement: getTables', response)
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={styles.left}>
          <View style={styles.leftTop}>
            <Tabs
              tabs={tabs}
              index={tabIndex}
              onChangeTab={setTabIndex}
            />
          </View>
          <View style={styles.leftMain}>
            <Text>Jackpot Management</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    flex:1,
    flexGrow:1,
    flexDirection:'column',
    justifyContent: 'center',
    alignItems:'center',
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  // left
  left: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'green',
    position: 'relative',
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  leftTop: {
    // width: '100%',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  leftMain: {
    width: '100%',
    // height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
  },
})

JackpotManagement.propTypes = {
  // navigation: PropTypes.any,
  setLoading: PropTypes.func,
}

export default JackpotManagement
