import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  // TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import Feather from '@components/icons/feather'
import Input from '@components/input'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import Log from '@utils/log'

import Styles from '@styles'
const { SC, Colors } = Styles

const InputSearch = (props) => {
  const {
    search,
    onSearchChanged,
    onDismissed,
  } = props

  const [text, setText] = useState('')
  const debounced = useDebouncedCallback((value) => {
    onSearchChanged?.(value)
  }, 1000)


  const _onDismiss = useCallback(() => {
    onSearchChanged('')
    if (onDismissed) {
      onDismissed()
    }
  }, [])

  const onChangeValue = (data, value) => {
    setText(value)
    debounced(value)
  }

  return (
    <View style={styles.container}>
      <View style={styles.searchIconWrap}>
        <Feather
          style={styles.searchIcon}
          name={'search'}
          size={12}
        />
      </View>
      <Input
        style={styles.search}
        value={text}
        placeholder={'Search Player'}
        placeholderTextColor={'darkgray'}
        onChangeValue={onChangeValue}>
      </Input>
      {
        search && search.length > 0 ?
          <View style={styles.dismissIconWrap}>
            <TouchableOpacity onPress={_onDismiss}>
              <Feather
                style={styles.dismissIcon}
                name={'x-circle'}
                size={12}
              />
            </TouchableOpacity>
          </View> : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-end',
  },
  search: {
    width: 80 * SC.SCALE,
    height: '100%',
    backgroundColor: Colors.DASH_LIGHT,
    borderRadius: 3,
    border: '0px solid transparent',
    paddingLeft: 12 * SC.SCALE,
    paddingRight: 12 * SC.SCALE,
    paddingVertical: 4 * SC.SCALE,
    fontSize: 7 * SC.SCALE,
  },
  searchIconWrap: {
    position: 'absolute',
    height: '100%',
    left: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchIcon: {
    color: 'white',
    fontSize: 6 * SC.SCALE,
  },
  dismissIconWrap: {
    position: 'absolute',
    height: '100%',
    right: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dismissIcon: {
    color: 'darkgray',
    fontSize: 6 * SC.SCALE,
  },
})

InputSearch.propTypes = {
  search: PropTypes.any,
  onSearchChanged: PropTypes.func,
  onDismissed: PropTypes.func,
}

export default InputSearch
