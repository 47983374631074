import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'

// import Log from '@utils/log'

import Toast from '@components/toast'
import { TabHeader } from '@components/tabs'
import ApiChip from '@apis/chip'

import Styles, { CommonStyle as cs } from '@styles'
// const { SC, Colors } = Styles

import { useSelector, useDispatch } from 'react-redux'

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

import Fill from '@components/fillcredit/fill'
import Credit from '@components/fillcredit/credit'
import Progress from './layout/progress'

const TAB_FILL = 'fill'
const TAB_CREDIT = 'credit'
const TAB_PROGRESS = 'progress'

const tabs = [{
  id: TAB_FILL,
  name: 'Fill',
}, {
  id: TAB_CREDIT,
  name: 'Credit',
}, {
  id: TAB_PROGRESS,
  name: 'Progress',
}]

const FillCredit = (props) => {
  const {
    // navigation,
    setLoading,
  } = props

  // const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid

  // init


  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [creditChipSets, setCreditChipSet] = useState(new Map())
  const [fillChipSets, setFillChipSets] = useState(new Map())
  const [allIndexChips, setAllIndexChips] = useState([])

  useEffect(() => {
    if (isFocused) {
      init()
    }
  }, [isFocused])


  const init = useCallback(async () => { // todo: implement
    try {
      // await dispatch(getAllChips(tableUuid))
      setLoading(true)
      // const [chipSetMap, chipMap] = await getAllChips(tableUuid)
      const [chipSetMap, chipMap] = await ApiChip.getAllChipSetAndChipInMap(source.token, tableUuid)
      setAllIndexChips(chipMap)
      setCreditChipSet(chipSetMap)
      setFillChipSets(_.cloneDeep(chipSetMap))
      // await getAllChips()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])


  return (
    <View style={[styles.container, cs.pa]}>
      <TabHeader
        tabs={tabs}
        selectedItem={selectedTab}
        onChangeTab={setSelectedTab}
      />
      <View style={styles.container}>
        {selectedTab.id === TAB_FILL && <Fill chipSets={fillChipSets} tableUuid={tableUuid} />}
        {selectedTab.id === TAB_CREDIT && <Credit chipSets={creditChipSets} tableUuid={tableUuid} />}
        {selectedTab.id === TAB_PROGRESS && <Progress allChips={allIndexChips} />}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
})

FillCredit.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
}

export default FillCredit
