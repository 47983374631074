import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'

import LinearGradient from '@components/lineargradient'
import Feather from '@components/icons/feather'
import S from '@styles'
const { SC, Colors } = S
// const { moderateScale } = SC

const SubHeader = (props) => {
  const {
    title,
    info,
  } = props
  const milliseconds = 500000
  const [totalPlayer, setTotalPlayer] = useState(0)
  const [time] = useState(new Date(milliseconds).toISOString().slice(11, 19))

  useEffect(() => {
    setTotalPlayer(info && info.player_count)
  }, [info])

  return (
    <View style={styles.container}>
      <LinearGradient
        style={styles.gradient}
        colors={[Colors.DASH_HEADER_FROM, Colors.DASH_HEADER_TO]}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
      />
      <View style={styles.headerWrap}>
        <Text style={styles.header}>{title || '...'}</Text>
      </View>
      <View style={styles.stat1}>
        <Feather name={'user'} style={styles.header} />
        <Text style={styles.header}>Total Player: {totalPlayer}</Text>
      </View>
      <View style={styles.stat1}>
        <Feather name={'clock'} style={styles.header} />
        <Text style={styles.header}>{time}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 28 * SC.SCALE,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: SC.GAP_S * SC.SCALE,
    marginBottom: 0,
    borderTopLeftRadius: SC.BORDER_RADIUS,
    borderTopRightRadius: SC.BORDER_RADIUS,
  },
  headerWrap: {
    paddingLeft: 24 * SC.SCALE,
    justifyContent: 'center',
    // alignItems: 'center',
    flex: 1,
  },
  header: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
    paddingHorizontal: 5,
  },
  stat1: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 5 * SC.SCALE,
    // minWidth: 200,
  },
  stat2: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 200,
  },
})

SubHeader.propTypes = {
  title: PropTypes.string,
  info: PropTypes.object,
}

export default SubHeader
