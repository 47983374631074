import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Log from '@utils/log'
import Toast from '@components/toast'
import DetailView from '@components/detailview'
import ApiTable from '@apis/table'
import { useSelector /* , useDispatch */ } from 'react-redux'
import useLoading from '@hooks/useLoading'
import axios from 'axios'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
import Button from '@components/button'

import DialogClosing from '@screens/tms/closing/layout/dialogclosing'

const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

const Closing = forwardRef((props, ref) => {

  const source = axios.CancelToken.source()
  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid

  useImperativeHandle(ref, () => ({
  }))

  // const {
  // navigation,
  // setLoadingaa,
  // } = props

  const [tableInfo, setTableInfo] = useState({})
  // const tableInfo = {
  //   opening: 0,
  //   current_float: 0,
  //   fill: 0,
  //   credit: 0,
  //   drop: 0,
  //   tip: 0,
  //   total_amount: 0,
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [dialogClosingVisible, setDialogClosingVisible] = useState(false)

  useEffect(() => { getClosing() }, [])

  const getClosing = async () => {
    setLoading(true)
    try {
      const resp = await ApiTable.getClosingOverView(source.token, tableUuid)
      setTableInfo(resp.data)
      // Toast.success(`Fill Credit Successful`)
    } catch (e) {
      Toast.exception(e, 'Fill Get Overview')
    }
    setLoading(false)
  }

  const onClosePress = async () => {
    setDialogClosingVisible(true)
    // setLoading(true)
    // try {
    //   const resp = await ApiTable.close(source.token, tableUuid)
    //   setTableInfo(resp.data)
    //   Toast.success(`Close Successful`)
    // } catch (e) {
    //   Toast.exception(e, 'Fill Get Overview')
    // }
    // setLoading(false)
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <View style={[cs.card, cs.rounded, cs.pa]}>
        <DetailView
          model={tableInfo}
          attributes={[
            { key: 'opening_float', label: 'Opening', vtype: 'price' },
            { key: 'current_float', label: 'Current Float', vtype: 'price' },
            { key: 'fill', label: 'Fill', vtype: 'price' },
            { key: 'credit', label: 'Credit', vtype: 'price' },
            { key: 'drops', label: 'Drops', vtype: 'price' },
            { key: 'tip', label: 'Tip', vtype: 'price' },
            { key: 'total_amount', label: 'Total Amount', vtype: 'price' },
          ]} />
        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: scaleSize(10) }}>
          <Button
            title="Close"
            disabled={false}
            onPress={onClosePress}
            style={[styles.btn]} />
        </View>
      </View>
      <DialogClosing
        visible={dialogClosingVisible}
        setVisible={setDialogClosingVisible}
        withIdentities={true}
        setLoading={setLoading}
        tableUuid={tableUuid}
      />
    </View>
  )
})

Closing.displayName = "Player"

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    // flex: 1,
    flexGrow: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: Colors.DASH_MAIN,
  },
  header: {
    flexDirection: 'row',
  },
  body: {
    color: 'white',
    flexGrow: 1,
    borderBottomStartRadius: SC.BORDER_RADIUS,
  },
  btn: {
    backgroundColor: Colors.BUTTON_WIZ,
    paddingHorizontal: 22 * SC.SCALE,
  },
})

Closing.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
  onHeaderButtonPressed: PropTypes.func,
}

export default Closing
