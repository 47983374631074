import React, {
  // useEffect,
  // useState,
  useCallback,
  // useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import EnvConstants from '@constants/env'

import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import Toast from '@components/toast'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

// import useLoading from '@hooks/useLoading'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { clearAuth } from '@actions/auth'
import { clearTable } from '@actions/table'

const source = axios.CancelToken.source()
import ApiTable from '@apis/table'

const StepClosing = (props) => {
  const {
    step,
    tableUuid,
    setLoading,
  } = props
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const onCloseRef = useCallback(async () => {
    setLoading(true)
    try {
      await ApiTable.close(source.token, tableUuid)
      // setTableInfo(resp.data)
      Toast.success(`Close Table Successfully`)
    } catch (e) {
      Toast.exception(e, 'Fill Get Overview')
    }
    setLoading(false)
  }, [])

  const onResetRef = useCallback(async () => {
    setLoading(true)
    try {
      await onReset()
      Toast.success(`Reset Table Successfully`)
    } catch (e) {
      Toast.exception(e, 'Fill Get Overview')
    }
    setLoading(false)
  }, [])

  const onReset = useCallback(async () => {
    await dispatch(clearAuth())
    await dispatch(clearTable())
    let name = 'error'
    switch (EnvConstants.APP_TYPE) {
      case 'tms':
        name = 'splash'
        break
      case 'pit':
        name = 'login'
        break
      default:
        break
    }
    navigation.reset({
      index: 0,
      routes: [{ name }],
    })
  }, [])

  return (
    <View style={[styles.container]}>
      <View style={styles.top}>
        <Text style={styles.title}>
          {step.label}
        </Text>
        {/* {
          (token == null || token.length <= 0) ?
          <LoginView
            ref={loginRef}
            noButton={true}
            onLogin={onLogin}
            buttonTitle={'Login'} /> : null
        } */}
      </View>
      <View style={styles.buttons}>
        <Button
          title={'Close Table'}
          onPress={() => onCloseRef()}
          style={styles.buttonClose} />
        <Button
          title={'Reset Table'}
          onPress={() => onResetRef()}
          style={styles.buttonReset} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  top: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  buttons: {
    flexDirection: 'row',
  },
  buttonClose: {
    marginTop: SC.GAP * SC.SCALE * 2,
    backgroundColor: Colors.BUTTON_WIZ,
    marginHorizontal: 3 * SC.SCALE,
  },
  buttonReset: {
    marginTop: SC.GAP * SC.SCALE * 2,
    backgroundColor: Colors.BUTTON_RESET,
    marginHorizontal: 3 * SC.SCALE,
  },
})

StepClosing.propTypes = {
  step: PropTypes.object,
  tableUuid: PropTypes.string,
  setLoading: PropTypes.func,
}

export default StepClosing
