import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
import Log from '@utils/log'
import PropTypes from 'prop-types'
import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
import ProgressBar from '../progressbar'
import Feather from '@components/icons/feather'

const { SC, Colors } = Styles

const ItemHeader = (props) => {

    const {
        isExpanded,
        onToggleExpand,
        item,
    } = props


    return (
        <View style={styles.container}>
            <View style={styles.roundedIcon}>
                <Feather
                    style={[styles.icon]}
                    name='send'
                    size={5 * SC.SCALE}>
                </Feather>
            </View>
            <ProgressBar style={styles.progress} item={item} />
            {isExpanded &&
                <TouchableOpacity onPress={onToggleExpand}
                    style={[styles.roundedBtn]}>
                    <Feather
                        style={[styles.icon]}
                        name='chevron-down'
                        size={9 * SC.SCALE}>
                    </Feather>
                </TouchableOpacity>}
            {!isExpanded && <TouchableOpacity onPress={onToggleExpand}
                style={[styles.roundedBtn]}>
                <Feather
                    style={[styles.icon]}
                    name='chevron-up'
                    size={9 * SC.SCALE}>
                </Feather>
            </TouchableOpacity>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flex: 3,
        justifyContent: 'space-between',
        paddingHorizontal: scaleSize(5),
        paddingVertical: scaleSize(5),
        alignItems: 'end',
    },
    icon: {
        color: 'white',
    },
    roundedBtn: {
        // borderWidth: 1 * SC.SCALE,
        borderRadius: scaleSize(11),
        borderColor: 'white',
        height: scaleSize(11),
        width: scaleSize(11),
        justifyContent: 'center',
        backgroundColor: Colors.BUTTON_BG_PRESSED,
        alignItems: 'center',
    },
    roundedIcon: {
        borderWidth: 0.5 * SC.SCALE,
        borderRadius: scaleSize(11),
        borderColor: 'white',
        height: scaleSize(11),
        width: scaleSize(11),
        justifyContent: 'center',
        alignItems: 'center',
    },
    progress: {
        alignSelf: 'stretch',
    },
})

ItemHeader.propTypes = {
    onToggleExpand: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    item: PropTypes.any.isRequired,
}

export default ItemHeader