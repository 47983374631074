import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'

// components
// import Container from '@screens/base/container'
import LinearGradient from '@components/lineargradient'
import Progress from './progress'

// styles
import Styles from '@styles'
const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

const Wrapper = (props) => {
  // props
  const {
    children,
    title,
    style,
  } = props

  // methods

  return (
    <View style={[styles.wrapper, style]}>
      <LinearGradient
        colors={[Colors.BG_FROM, Colors.BG_TO]}
        style={styles.gradient}
      />
      <View style={[StyleSheet.absoluteFill]}>
        <Progress style={styles.top}
          title={title}
          {...props}>
        </Progress>

        <View style={styles.bottom}>
          <View style={styles.content}>
            {(
              children ? children : null
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    margin: 20,
    backgroundColor: Colors.DASH_BG,
    borderRadius: SC.BORDER_RADIUS,
    borderColor: Colors.BORDER,
    borderWidth: 1 * SC.SCALE,
    flex: 1,
    flexDirection: 'column',
  },
  top: {
  },
  bottom: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    // margin: moderateScale(SC.GAP),
    // marginLeft: moderateScale(SC.GAP),
    // marginBottom: moderateScale(SC.GAP),
    // backgroundColor: 'blue',
  },
})

Wrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.any,
}

export default Wrapper
