// import React from 'react'
// import {
//   Text,
//   View,
// } from 'react-native'
import Styles from '@styles'
const { Colors } = Styles

const isDarkMode = false

const NavOptions = {
  headerBackgroundStyle: {
    backgroundColor: isDarkMode ? '#121C25' : Colors.PRIMARY_DARK,
    shadowColor: isDarkMode ? Colors.PRIMARY_DARK : Colors.PRIMARY_DARK,
    borderBottomColor: '#ffffff',
    height: 50,
  },
  headerTitleStyle: {
    color: isDarkMode ? Colors.PRIMARY_DARK : '#ffffff',
  },
  headerTintColor: isDarkMode ? Colors.PRIMARY_DARK : '#ffffff',
  getNavOptions: (extraParams) => {
    let o = {}
    let ns = NavOptions
    let headerBackgroundStyle = [ns.headerBackgroundStyle, (o && o.headerBackgroundStyle)]
    let headerTitleStyle = [ns.headerTitleStyle, (o && o.headerTitleStyle)]
    let headerBackTitle = (o && o.headerBackTitle) || ''
    let headerTintColor = (o && o.headerTintColor) || ns.headerTintColor
    let headerTitleAlign = (o && o.headerTitleAlign) || 'center'
    let headerShown = false
    return {
      headerStyle: headerBackgroundStyle,
      headerTitleStyle,
      headerBackTitle,
      headerTintColor,
      headerTitleAlign,
      headerShown,
      ...extraParams,
    }
  },
}

export default NavOptions
