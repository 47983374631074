import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  View,
  Text,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

// components
import Toast from '@components/toast'
import Container from '@screens/base/container'
// import Button from '@components/button'
import Wrapper from './layout/wrapper'

// subs
import Dashboard from '@screens/tms/dashboard'
import Player from '@screens/tms/player'
import FastDrop from '@screens/tms/fastdrop'
import FillCredit from '@screens/tms/fillcredit'
import ChipsManagement from '@screens/tms/chipsmanagement'
import GameManagement from '@screens/tms/gamemanagement'
import JackpotManagement from '@screens/tms/jackpotmanagement'

import DialogLock from './layout/dialoglock'
import DialogAccount from './layout/dialogaccount'

// styles
import Styles from '@styles'
const { SC } = Styles

// // react-redux
import { useSelector, useDispatch } from 'react-redux'
import { clearAuth } from '@actions/auth'
import { clearTable } from '@actions/table'

import ApiTable from '@apis/table'
import Closing from '../closing'

const Home = (props) => {
  const {
    navigation,
    route,
  } = props
  // const routeScreen = route?.params?.screen
  const [loading, setLoading, resetLoading] = useLoading()
  const source = axios.CancelToken.source()
  const isFocused = useIsFocused()
  const playerRef = useRef()
  const fastdropRef = useRef()

  const [info, setInfo] = useState({})

  const [items] = useState([
    { title: 'Player', route: 'player', component: Player, ref: playerRef },
    { title: 'Fast Drop', route: 'fastdrop', component: FastDrop, isPrevious: true, ref: fastdropRef },
    { title: 'Fill & Credit', route: 'fillcredit', component: FillCredit },
    { title: 'Chips Management', route: 'chipsmanagement', component: ChipsManagement },
    { title: 'Game Management', route: 'gamemanagement', component: GameManagement, isPrevious: true },
    { title: 'Jackpot Management', route: 'jackpotmanagement', component: JackpotManagement },
    // filtered
    { title: 'Dashboard', route: 'dashboard', component: Dashboard },
    { title: 'Closing', route: 'closing', component: Closing },
  ])
  const [screen, setScreen] = useState(route?.params?.screen || 'player')
  const [isPrevious, setIsPrevious] = useState(false)

  const [dialogLockVisible, setDialogLockVisible] = useState(false)
  const [dialogAccountVisible, setDialogAccountVisible] = useState(false)

  // dispatch actions
  const dispatch = useDispatch()
  const loader = useSelector(state => state.loader)

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  // init
  useEffect(() => {
    // Log.log(`${CNAME}: initialized`)
    // Log.log('-- navigation', navigation)
    // Log.log('-- route', route)
    // let params = route?.params
    // let screen = params?.screen
    Log.log('home: init()', { screen, routeScreen: route?.params?.screen })
    preload()
    if (isFocused) {
      init()
    }
  }, [route, setScreen])

  useEffect(() => {
    Log.log('home: change screen()', { screen, routeScreen: route?.params?.screen })
    preload()
  }, [screen, route, items])

  const preload = useCallback(() => {
    let routeScreen = route?.params?.screen || 'player'
    setScreen(routeScreen)
    let item = _.find(items, (i) => i.route === routeScreen)
    setIsPrevious(item && item.isPrevious)
  }, [screen, route, items])

  const init = useCallback(async () => {
    try {
      // setLoading(true)
      // API calls here
      let promises = []
      let promise
      promise = getTotalPlayer()
      promises.push(promise)
      await Promise.all(promises)
      // setLoading(false)
    } catch (e) {
      // setLoading(false)
      Toast.exception(e, `Failed get total player`)
    }
  }, [])

  const getTotalPlayer = useCallback(async () => {
    let response = await ApiTable.getTotalPlayer(source.token, tableUuid)
    if (response && response.data) {
      let data = response.data
      setInfo((state) => {
        return {
          ...state,
          ...(data || {}),
        }
      })
    }
  }, [])

  // header

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'back':
      case 'home':
      case 'closing':
      case 'account':
        return true
      default:
        return false
    }
  })

  // const headerActionInterceptor = () => false

  const onHeaderButtonPressed = useCallback((info) => {
    if (playerRef.current && playerRef.current.intercepintercep(info)) {
      return
    }


    switch (info?.name) {
      case 'back':
        return onBack()
      case 'home':
        return onHome()
      case 'account':
        return onAccount()
      case 'closing':
        return onClosing()
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [onBack, onHome, screen, route])

  // components

  const onPanelButtonPressed = useCallback((info) => {
    let item = items[info && info.index]
    if (!item) {
      Log.warn('Invalid panel button pressed index', info)
      return
    }
    Log.log('home: panel button changed', item.route)
    setScreen(item.route)
    setIsPrevious(item && item.isPrevious)
    navigation.setParams({ screen: item.route })
    // navigation.navigate('home', { screen: item.route })
  }, [items, screen])

  const onPanelButtonPreviousPressed = useCallback(() => {
    let info = _.find(items, (i) => i.route === screen)
    if (!info) {
      Log.warn(`home: invalid screen: ${screen}`)
      return
    }
    let ref = info.ref
    // Log.log(`home: ref: ${screen}`, ref)
    Log.log('home: onPanelButtonPreviousPressed', ref && ref.current)
    if (ref && ref.current && typeof ref.current.onPreviousPressed === 'function') {
      ref.current.onPreviousPressed()
    }
  }, [screen])

  const title = useMemo(() => {
    let item = _.find(items, (i) => i.route === screen)
    return (item && item.title) || 'Dashboard'
  }, [items, screen])

  // method

  const getScreen = useCallback((screen, props) => {
    let info = _.find(items, (it) => it.route === screen)
    // Log.log('ref==>', info)
    if (info && info.component) {
      let Component = info.component
      return (
        <Component
          setLoading={setLoading}
          resetLoading={resetLoading}
          ref={info.ref}
          {...props}
        >
        </Component>
      )
    }
    return (
      <View>
        <Text>Invalid Component: {screen}</Text>
      </View>
    )
  }, [screen])

  const onBack = useCallback(async () => {
    Log.log('home: onback screen', screen)
    Log.log('home: onback screen (route)', route?.params?.screen)
    if (screen === 'dashboard') {
      Log.log('home: screen locked')
      navigation.navigate('lock')
      // setDialogLockVisible(true)
    } else {
      setScreen('dashboard')
      setIsPrevious(false)
      navigation.setParams({ screen: 'dashboard' })
      // navigation.navigate('home', { screen: 'dashboard' })
    }
  }, [route, screen])

  const onReset = useCallback(async () => {
    await dispatch(clearAuth())
    await dispatch(clearTable())
    navigation.navigate('splash')
  }, [])

  const onHome = useCallback(() => {
    setScreen('dashboard')
    setIsPrevious(false)
    navigation.setParams({ screen: 'dashboard' })
    // navigation.navigate('home', { screen: 'dashboard' })
  }, [screen])

  const onClosing = useCallback(() => {
    setScreen('closing')
    setIsPrevious(false)
    navigation.setParams({ screen: 'closing' })
    // navigation.navigate('home', { screen: 'dashboard' })
  }, [screen])

  const onAccount = useCallback(() => {
    setDialogAccountVisible(true)
  }, [])

  return (
    <Container
      style={styles.container}
      loading={(loading?.count || loader?.count)}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed.bind(this)}>
      <Wrapper
        title={title}
        info={info}
        items={_.filter(items, (it) => (it.route !== 'dashboard' && it.route !== 'closing'))}
        curr={screen}
        onPanelButtonPressed={onPanelButtonPressed}
        isPrevious={isPrevious}
        onPanelButtonPreviousPressed={onPanelButtonPreviousPressed}
        {...props}>
        {getScreen(screen, props)}
      </Wrapper>
      <DialogLock
        visible={dialogLockVisible}
        setVisible={setDialogLockVisible}
        onReset={onReset}
      />
      <DialogAccount
        visible={dialogAccountVisible}
        setVisible={setDialogAccountVisible}
      />
    </Container>
  )
}

Home.propTypes = {
  navigation: PropTypes.any,
  route: PropTypes.any,
  onHeaderButtonPressed: PropTypes.any,
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
})

export default Home
