import React, {
  // useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'

// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import DetailView from '@components/detailview'
// import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'

// import TableList from '@screens/pit/tablemanagement/layout/tablelist'

// import {
//   CommonStyle as cs,
//   // scaleSize,
// } from '@styles'
import Styles, { scaleSize } from '@styles'
const { SC, Colors } = Styles

// import { useIsFocused } from '@react-navigation/native'
// import useLoading from '@hooks/useLoading'
// import Log from '@utils/log'

// import { useDispatch } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const Wrapper = (props) => {
  const {
    // navigation,
    sideHeader,
    renderSide,
    renderMain,
    styleSide,
    styleMain,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [loading, setLoading] = useLoading()

  return (
    <View style={styles.container}>
      {
        sideHeader != null ?
        <View style={styles.top}>
          {
            renderSide ? <View style={styles.sideTop}>
              <Text style={styles.sideHeader}>
                {sideHeader}
              </Text>
            </View> : null
          }
          <View style={styles.mainTop}></View>
        </View> : null
      }
      <View style={styles.main}>
        {
          renderSide ?
          <View
            style={[
              styles.sideWrapper,
              styleSide,
            ]}>
            {renderSide(props)}
          </View> : null
        }
        <View
          style={[
            styles.mainWrapper,
            styleMain,
          ]}>
          {renderMain ? renderMain(props) : null}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  top: {
    flex: 0,
    flexBasis: 10 * SC.SCALE,
    flexDirection: 'row',
    marginTop: SC.GAP_S * SC.SCALE,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
  },
  sideTop: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: SC.GAP_S * SC.SCALE,
    padding: SC.GAP * SC.SCALE,
  },
  sideHeader: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: scaleSize(9),
    textAlign: 'center',
  },
  sideWrapper: {
    flex: 1,
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 9,
    margin: SC.GAP_S * SC.SCALE,
    marginRight: 0,
    padding: SC.GAP * SC.SCALE,
  },
  mainTop: {
    flex: 3,
    marginLeft: SC.GAP_S * SC.SCALE,
    padding: SC.GAP * SC.SCALE,
  },
  mainWrapper: {
    flex: 3,
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 9,
    margin: SC.GAP_S * SC.SCALE,
    // marginRight: 0,
    // padding: SC.GAP * SC.SCALE,
  },
})

Wrapper.propTypes = {
  // navigation: PropTypes.any,
  sideHeader: PropTypes.string,
  renderSide: PropTypes.func,
  renderMain: PropTypes.func,
  styleSide: PropTypes.any,
  styleMain: PropTypes.any,
}

export default Wrapper
