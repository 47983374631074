import * as Linking from 'expo-linking'
import EnvConstants from '@constants/env'

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      // drawer: {
      //   screens: {
      //     splash: 'splash',
      //     home: 'home',
      //   }
      // },
      ...(EnvConstants.APP_TYPE === 'tms' ? {
        // tms
        splash: {
          path: 'splash',
        },
        lock: 'lock',
        home: {
          path: 'home',
        },
        'wizard-opening': {
          path: 'wizard/opening',
        },
        'wizard-selecttable': {
          path: 'wizard/select-table',
        },
        'wizard-selectfloat': {
          path: 'wizard/select-float',
        },
        'wizard-enterid': {
          path: 'wizard/enter-id',
        },
      } : {}),
      ...(EnvConstants.APP_TYPE === 'pit' ? {
        login: {
          path: 'login',
        },
        home: {
          path: 'home',
        },
      } : {}),
      notfound: '*',
    },
  },
}
