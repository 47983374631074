import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  // StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
} from 'react-native'

import PageMain from './pages/pagemain'
import PageOpening from './pages/pageopening'
import PageMinMax from './pages/pageminmax'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
// import Styles from '@styles'
// const { SC, Colors } = Styles

// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

// import { useDispatch } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const TableManagement = forwardRef((props, ref) => {
  const {
    navigation,
    route,
    screen,
  } = props

  let DEFAULT_PAGE = 'main'

  useImperativeHandle(ref, () => ({
    // onInit, // not working
  }))

  useEffect(() => {
    const routePage = route.params?.page
    Log.log('tablemanagement: init()', routePage)
    setPage(routePage)
  }, [screen, route])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [pages] = useState([
    { id: 'main', component: PageMain },
    { id: 'opening', component: PageOpening },
    { id: 'minmax', component: PageMinMax },
  ])
  const [page, setPage] = useState(DEFAULT_PAGE)

  // const onInit = () => {
  //   setPage(page || DEFAULT_PAGE)
  // }

  const onChangePage = (page, params = {}) => {
    let pageparams = {
      ...route?.params,
      ...params,
      page,
    }
    navigation.setParams(pageparams)
    // let naviparams = {
    //   name: 'home',
    //   params: pageparams,
    // }
    // navigation.navigate(naviparams)
    setPage(page || DEFAULT_PAGE)
  }

  const renderComponent = () => {
    let info = _.find(pages, (p) => p.id === page)
    let Component = info?.component
    if (!Component) {
      Component = PageMain
    }
    return (
      <Component onChangePage={onChangePage} {...props} />
    )
  }

  return (
    renderComponent()
  )
})

// const styles = StyleSheet.create({
// })

TableManagement.displayName = 'TableManagement'
TableManagement.propTypes = {
  navigation: PropTypes.any,
  route: PropTypes.object,
}

export default TableManagement
