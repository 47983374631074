import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    // Image,
} from 'react-native'
import React, {
    // useEffect,
    useCallback,
    // useState,
} from 'react'
// import Log from '@utils/log'
import Styles, { CommonStyle as cs } from '@styles'
import Avatar from '@components/avatar'
import PropTypes from 'prop-types'

const { SC } = Styles

const PlayerCard = (props) => {
    // const [imageError, setImageError] = useState(false)
    const {
        uri,
        player,
        onClick,
    } = props

    const playerName = () => {
        let label = 'Empty'
        if (player && player.player_uuid) {
            if (player.player_name) {
                label = player.player_name
            }
            else {
                label = 'Unknown Player'
            }
        }
        return (<Text style={styles.text}>{label}</Text>)
    }

    const onPlayerClick = useCallback(() => {
        onClick?.(player)
    }, [onClick])

    return (
        <TouchableOpacity style={[styles.card, cs.rounded, cs.pa]}
            onPress={() => onPlayerClick()}>
            <Avatar uri={uri} iconBgStyle={styles.avatarBg} />
            <View style={{ paddingTop: 3 }}>
                {playerName()}
                <Text style={styles.text}>{player && player.drop_in_amount ? `RM ${player.drop_in_amount}` : 'RM 0'} </Text>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: '#563D8E',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48 * SC.SCALE,
        height: 48 * SC.SCALE,
    },
    avatarBg: {
        backgroundColor: '#614A9A',
        borderWidth: 0,
    },
    text: {
        color: '#29FB2E',
        fontSize: 11,
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 2,
    },
})

PlayerCard.propTypes = {
    uri: PropTypes.string,
    player: PropTypes.any,
    onClick: PropTypes.func,
}

export default PlayerCard