import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import TableList from '@screens/base/tablelist'
import ChipsManagementView from '@screens/tms/chipsmanagement'
// import DetailView from '@components/detailview'
// import TimeCount from './layout/timecount'
// import Adjustment from './layout/adjustment'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'
import Constants from '@constants'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const ChipsManagement = (/* props */) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  const chipsManagementView = useRef()

  const getTables = async () => {
    let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    if (!response || !response.data || !response.data.uuid) {
      throw new Error('invalid response or data or uuid')
    }
    let pitUuid = response.data.uuid
    let resp = await ApiTable.getAll(source.token, pitUuid)
    let data = resp?.data
    setTables(data)

    // select
    data[0].isSelected = true
    data[0].adjustment = '0'
    await onTableSelect(data[0])
    // return tableUuid
  }

  useEffect(() => {
    // Log.log('chipsmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)
      await getTables()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [tables, setTables] = useState([])

  // const [adjustmentAmt, setAdjustmentAmt] = useState(0)

  // const [tableInfo, setTableInfo] = useState({})

  const onAddTableItem = () => {
    //
  }

  const onTableSelect = async (item) => {
    Log.log('chipsmanagement: selected table', item)
    Log.log('chipsmanagement: selected table uuid', item?.uuid)
    Log.log('chipsmanagement: ref', chipsManagementView?.current)
    // setTableUuid(item?.uuid)
    if (item?.uuid) {
      chipsManagementView?.current?.setTableUuid(item?.uuid)
    }
    // setLoading(true)
    // try {
    //   const resp = await ApiTable.getFloat(source.token, item.uuid)
    //   resp.data.adjustment = 0
    //   setTableInfo(resp.data)
    //   // Toast.success(`Fill Credit Successful`)
    // } catch (e) {
    //   Toast.exception(e, 'Fill Get Overview')
    // }
    // setLoading(false)
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <View style={[styles.tablePanel, cs.pe]}>
        <Text style={[{ color: 'white', fontWeight: 'bold', fontSize: scaleSize(9), textAlign: 'center' }]}>
          Table No.
        </Text>
        <View style={[styles.tableListContainer, cs.rounded]}>
          <TableList
            data={tables}
            withAdd={true}
            onAdd={onAddTableItem}
            onSelect={onTableSelect}
          />
        </View>
      </View>
      <View style={[styles.main, cs.pa, cs.rounded]}>
        <ChipsManagementView
          ref={chipsManagementView}
          compact={true}
        />
        {/* <DetailView
          model={tableInfo}
          attributes={[
            'opening_float',
            'current_float',
            'total_float',
            'adjustment',
          ]}
          style={styles.details} />
        <View style={styles.controls}>
          <View style={[styles.timecount]}>
            <TimeCount />
          </View>
          <View style={[styles.adjustment]}>
            <Adjustment />
          </View>
        </View> */}
      </View>
    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  tablePanel: {
    flex: 1,
    alignItems: 'stretch',
  },
  main: {
    flex: 5,
    backgroundColor: Colors.DASH_MAIN,
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  tableListContainer: {
    backgroundColor: Colors.DASH_MAIN,
    flexGrow: 1,
    padding: scaleSize(6),
    marginTop: scaleSize(3),
  },
  details: {
    flex: 1,
    padding: SC.GAP * SC.SCALE,
  },
  controls: {
    flexDirection: 'row',
    paddingTop: scaleSize(15),
  },
  timecount: {
    flex: 4,
  },
  adjustment: {
    flex: 5,
  },
})

ChipsManagement.propTypes = {
  navigation: PropTypes.any,
}

export default ChipsManagement
