import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import DetailView from '@components/detailview'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import TableList from '@screens/base/tablelist'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles
// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const Dashboard = (/* props */) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    Log.log('dashboard: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('dashboard: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const pitSummary = {
    total_table_opened: 300000,
    total_table_closed: 120000,
    total_drop: 20000,
    total_win_lose: 0,
    total_hold: 0,
    total_fill_credit: 0,
  }

  const [list] = useState([
    { id: 1, label: 'RC01' },
    // { id: 2, label: 'RC02' },
    // { id: 3, label: 'RC03' }, // uncomment to see list
  ])

  const onAddTableItem = () => {
    //
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <View style={[styles.tablePanel, cs.pe]}>
        <Text style={[{ color: 'white', fontWeight: 'bold', fontSize: scaleSize(9), textAlign: 'center' }]}>
          Table No.
        </Text>
        <View style={[styles.tableListContainer, cs.rounded]}>
          <TableList
            data={list}
            withAdd={true}
            onAdd={onAddTableItem}
          />
        </View>
      </View>
      <View style={[styles.main]}>
        <Text style={[{ color: 'white', fontWeight: 'bold', fontSize: scaleSize(9), textAlign: 'center' }]}>
          Overview
        </Text>
        <DetailView
          style={[cs.card, cs.rounded, { marginTop: scaleSize(3) }]}
          model={pitSummary}
          labelStyle={{ fontSize: scaleSize(15) }}
          valueStyle={{ fontSize: scaleSize(15) }}
          attributes={[
            'total_table_opened',
            'total_table_closed',
            'total_drop',
            'total_win_lose',
            'total_hold',
            'total_fill_credit',
          ]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  tablePanel: {
    flex: 1,
    alignItems: 'stretch',
  },
  main: {
    flex: 3,
    alignItems: 'stretch',
  },
  tableListContainer: {
    backgroundColor: Colors.DASH_MAIN,
    flexGrow: 1,
    padding: scaleSize(6),
    marginTop: scaleSize(3),
  },
})

Dashboard.propTypes = {
  navigation: PropTypes.any,
}

export default Dashboard
