// import _ from 'lodash'

const Steps = {
  SELECT_TABLE: 'selecttable',
  OPENING: 'opening',
  SELECT_FLOAT: 'selectfloat',
  ENTER_ID: 'enterid',
}

const Constants = {
  Steps,

  PROGRESS: [{
    key: Steps.SELECT_TABLE,
    title: 'Select Table',
  }, {
    key: Steps.OPENING,
    title: 'Opening Count',
  }, {
    key: Steps.SELECT_FLOAT,
    title: 'Select Float Config',
  }, {
    key: Steps.ENTER_ID,
    title: 'Enter Employee ID',
  }],

  getTitle: (key) => {
    switch (key) {
      case Steps.SELECT_TABLE:
        return 'Select Table'
      case Steps.OPENING:
        return 'Opening Count'
      case Steps.SELECT_FLOAT:
        return 'Select Float Config'
      case Steps.ENTER_ID:
        return 'Enter Employee ID'
      default:
        break
    }
    return 'Unknown'
  },
}

export default Constants
