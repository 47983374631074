import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { StyleSheet, View } from 'react-native'
import 'react-native-gesture-handler'
// import 'expo-dev-client'

import Styles from '@styles'
const { Colors } = Styles

// react-redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '@store'

// apis init
import { injectStore } from '@apis'
injectStore(store)

import Router from './src/router'

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}e>
        <View style={styles.container}>
          <StatusBar backgroundColor={Colors.STATUS_BAR} barStyle={'dark-content'} />
          <Router />
        </View>
      </PersistGate>
    </Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 10, // for debug
    backgroundColor: 'white',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  containerWrap: {
    flex: 1,
    flexWrap: 'wrap',
    flexGrow: 0.5,
    padding: 10,
    backgroundColor: '#ffaa33',
  },
})
