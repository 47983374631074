import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC } = Styles

const ButtonHeader = (props) => {
  const {
    index,
    iconName,
    title,
    style,
    textStyle,
    disabled,
    onPress,
    children,
  } = props

  const onButtonPressed = useCallback(() => {
    if (onPress) onPress({ index })
  }, [index, onPress])

  return (
    <TouchableOpacity
      style={[
        styles.wrapper,
        (disabled === true) ? styles.wrapperDisabled : {},
        style,
      ]}
      onPress={() => onButtonPressed(props)}
      disabled={(disabled === true)}>
      <Feather
        name={iconName}
        size={15 * SC.SCALE}
        style={[
          styles.icon,
          disabled ? styles.iconDisabled : {},
        ]}>
      </Feather>
      {
        (children) ?
          children :
          <View>
            <Text style={[styles.text, textStyle]}>{title}</Text>
          </View>
      }
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: 30 * SC.SCALE,
    height: 30 * SC.SCALE,
    borderRadius: 60 * SC.SCALE,
    borderWidth: 1 * SC.SCALE,
    borderColor: 'white',
    // backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperDisabled: {
    borderColor: 'gray',
  },
  text: {
    fontSize: 22,
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  iconDisabled: {
    color: 'gray',
  },
})

ButtonHeader.propTypes = {
  index: PropTypes.number,
  iconName: PropTypes.string,
  title: PropTypes.string,
  onPress: PropTypes.func,
  style: PropTypes.any,
  disabled: PropTypes.any,
  children: PropTypes.any,
  textStyle: PropTypes.any,
}

export default ButtonHeader
