import _ from 'lodash'

const CHIP_INFOS = [{
  keys: ['white'],
  color: 'white',
  colorBg: 'lightgray',
  colorText: 'black',
}, {
  keys: ['red'],
  color: 'red',
  colorBg: 'darkred',
  colorText: 'white',
}, {
  keys: ['orange'],
  color: 'orange',
  colorBg: 'darkorange',
  colorText: 'white',
}, {
  keys: ['yellow'],
  color: '#ca0',
  colorBg: '#a70',
  colorText: 'white',
}, {
  keys: ['green'],
  color: 'green',
  colorBg: 'darkgreen',
  colorText: 'white',
}, {
  keys: ['blue'],
  color: 'blue',
  colorBg: 'darkblue',
  colorText: 'white',
}, {
  keys: ['gray', 'black'],
  color: '#666',
  colorBg: '#333',
  colorText: 'white',
}, {
  keys: ['purple'],
  color: 'purple',
  colorBg: '#202',
  colorText: 'white',
}, {
  keys: ['red', 'maroon'],
  color: 'maroon',
  colorBg: '#302',
  colorText: 'white',
}]

const Calculate = {
  CHIP_INFOS,

  getChip: (color) => {
    let infos = Calculate.CHIP_INFOS
    for (let i in infos) {
      let info = infos[i]
      let keys = info.keys
      color = color && color.toLowerCase()
      if (keys.includes(color)) {
        return _.assign({}, info)
      }
    }
  },

  mapChips: (chips = [], existing = [], chipsetUuid) => {
    // console.log('-- map chips', { chips, existing, chipsetUuid })
    chips = _.map(chips, (chip) => {
      let chipUuid = chip.uuid
      let colorInfo = Calculate.getChip(chip.colour)
      let count = 0
      let found = _.find(existing, (chip) => (chip.chipsetUuid === chipsetUuid) && (chip.chipUuid === chipUuid))
      if (found && found.quantity > 0) {
        count = found.quantity
      }
      return {
        cid: chipUuid,
        ...chip,
        color: (chip.colour && chip.colour.toLowerCase()),
        colorBg: 'black',
        colorText: 'black',
        ...colorInfo,
        title: `${parseInt(chip.value, 10)}`,
        value: parseInt(chip.value, 10),
        count,
      }
    })
    return chips
  },

  filter: (chips = []) => {
    let filtered = _.filter(chips, (chip) => {
      let count = chip.count
      count = isNaN(parseInt(count, 10)) ? 0 : parseInt(count, 10)
      chip.count = count
      return (count > 0)
    })
    return filtered
  },

  getTotal: (chips = []) => {
    let total = 0
    for (let c in chips) {
      let chip = chips[c]
      let value = chip.value
      let count = parseInt(chip.count, 10)
      if (count == null || isNaN(count)) continue
      total += count * value
    }
    return total
  },

  detabulateChipsByAmount: (chips = [], amount) => {
    let cashlist = [].concat(chips)
    let lastTotal = amount
    if (!lastTotal || lastTotal <= 0 || isNaN(parseInt(lastTotal, 10))) {
      throw new Error(`invalid last drop amount: ${lastTotal}`)
    }
    let cashinfos = []
    cashlist = _.orderBy(cashlist, ['value'], ['desc'])
    for (let c in cashlist) {
      let cl = cashlist[c]
      let value = parseInt(cl.value, 10)
      // if (value === 25) continue
      let count = Math.floor(lastTotal / value)
      let remain = lastTotal % value
      // console.log(`-- push value=${value} with count=${count}, remain=${remain}`)
      cashinfos.push({
        ...cl,
        uuid: c,
        title: `${value}`,
        value: value,
        count,
      })
      lastTotal = remain
    }
    return cashinfos
  },
}

export default Calculate
