import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

// import Constants from '@constants'
import Log from '@utils/log'
import ChipCalculate from '@components/chips/calculate'
// import WizardConstants from '@screens/tms/wizconstants'

import Container from '@screens/base/container'
import Toast from '@components/toast'
import Tabs from '@components/tabs'
import Button from '@components/button'
// import Input from '@components/input'
// import Chip from '@components/chip'
import Chips from '@components/chips'
// import Slider from '@components/slider'
import ChipInputSlider from '@screens/base/chipinputslider'

import Wrapper from '../wiz-layout/wrapper'

import Styles from '@styles'
const { SC, Colors } = Styles

// react-redux
import { useSelector, useDispatch } from 'react-redux'
import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'

// import ApiPit from '@apis/pit'
// import ApiTable from '@apis/table'
import ApiChip from '@apis/chip'

const Opening = (props) => {
  const {navigation} = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  const tableChips = table && table.chips

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  // init
  const [achips, setAchips] = useState([])

  const [chipsetIndex, setChipsetIndex] = useState(0)
  const [chipIndex, setChipIndex] = useState(0)

  // const [cchips, setCchips] = useState(ChipCalculate.CHIP_INFOS)
  // const [nchips, setNchips] = useState(ChipCalculate.CHIP_INFOS)
  // const chipTitles = ['Cash Chips', 'NN Chips']
  // const TAB_CCHIPS = 0
  // const TAB_NCHIPS = 1

  // components

  useEffect(() => {
    Log.log('wizard-opening: init', { isFocused })
    if (isFocused) {
      preload()
      init()
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const init = async () => {
    try {
      setLoading(true)
      if (!tableUuid) {
        setLoading(false)
        Toast.show('Invalid table UUID', {
          type: 'danger',
        })
        return
      }
      await getChipsetsAndChips(tableUuid)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Load tables: ${error}`, {
        type: 'danger',
        placement: 'top',
      })
    }
  }

  const getChipsetsAndChips = async (tableUuid) => {
    if (!tableUuid || tableUuid.length <= 0) {
      throw new Error('invalid table UUID')
    }
    let response
    // response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    // if (!response || !response.data || !response.data.uuid) {
    //   throw new Error('invalid response or data or uuid')
    // }

    // let pitUuid = response.data.uuid
    // response = await ApiTable.getAll(source.token, pitUuid)
    // console.log('-- all tables', response)
    response = await ApiChip.getChipsets(source.token, tableUuid)
    Log.log(`wizard-opening: all chipsets: ${tableUuid}`, response)
    let chipsets = (response && response.data) || []

    let promises = []
    let promise
    for (let c in chipsets) {
      let chipset = chipsets[c]
      let chipUuid = chipset.uuid
      promise = ApiChip.getChips(source.token, chipUuid)
      promises.push(promise)
    }
    let values = await Promise.all(promises)
    Log.log('wizard-opening: all chips', values)

    chipsets = _.map(chipsets, (chipset, csIndex) => {
      let chipsetUuid = chipset.uuid
      let chips = values[csIndex] && values[csIndex].data
      // map chips into proper chips format
      // input: uuid, colour, value, count
      // output: color, colorBg, colorText, title, value, count
      chips = ChipCalculate.mapChips(chips, tableChips, chipsetUuid)
      return {
        ...chipset,
        chips,
      }
    })
    setAchips(chipsets)
  }

  // header controls

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'home':
      case 'closing':
        return false
      case 'back':
      case 'account':
        return true
      default:
        return false
    }
  }, [])

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'back':
        return navigation.navigate('wizard-selecttable')
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [])

  const onWizardNext = async () => {
    let allChips = _.flatMap(achips, (chipset) => {
      let chips = (chipset && chipset.chips)
      chips = _.filter(chips, (chip) => {
        return (chip && chip.count > 0)
      })
      chips = _.map(chips, (chip) => {
        return {
          chipsetUuid: chipset && chipset.uuid,
          chipUuid: chip && chip.uuid,
          value: chip && chip.value,
          quantity: chip && chip.count,
        }
      })
      return chips
    })
    Log.log('wizard-opening: chips', allChips)
    await dispatch(updateTable(PARAM_TABLE_CHIPS, allChips))
    navigation.navigate('wizard-selectfloat')
  }

  // components

  // input
  const onChangeValue = useCallback((chip, count) => {
    const allChipsets = achips
    const allChips = achips[chipsetIndex] && achips[chipsetIndex].chips

    // validate
    if (count == null || isNaN(parseInt(count, 10))) {
      count = 0
    } else {
      count = parseInt(count, 10)
    }

    let val = chip.value
    let cid = chip.cid
    let found = _.find(allChips, (c) => c.value === val && c.cid === cid)
    if (found) {
      found.count = count
    }
    // if (chipsetIndex === TAB_NCHIPS) {
    //   setNchips([...clonedChips])
    // } else {
    //   setCchips([...clonedChips])
    // }
    setAchips([...allChipsets])
  }, [achips, chipsetIndex, chipIndex])

  const total = useMemo(() => {
    let all = 0
    for (let t in achips) {
      let achip = achips[t]
      achip = achip && achip.chips
      for (let c in achip) {
        let chip = achip[c]
        let value = chip.value
        let count = parseInt(chip.count, 10)
        if (count == null || isNaN(count)) continue
        all += count * value
      }
    }
    return all
  }, [achips])

  const nextDisabled = useMemo(() => {
    return (total == null || total <= 0 ||
      tableUuid == null || tableUuid.length <= 0)
  }, [total, tableUuid])

  const setChipTabIndex = useCallback((index) => {
    setChipsetIndex(index)
  }, [chipsetIndex])

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <Wrapper
        title={'Opening Count'}
        {...props}>
        <View style={styles.wrapper}>
          <View style={styles.left}>
            <View style={styles.leftTop}>
              <Tabs
                tabs={achips}
                index={chipsetIndex}
                onChangeTab={setChipTabIndex}
              />
            </View>
            <View style={styles.leftMain}>
              {
                achips && chipsetIndex < achips.length &&
                achips[chipsetIndex] && achips[chipsetIndex].chips ?
                <Chips
                  chips={achips[chipsetIndex].chips}
                  onPress={(chip, index) => setChipIndex(index)}
                  onChangeValue={(chip, value) => onChangeValue(chip, value)}
                /> : null
              }
            </View>
          </View>
          <View style={styles.right}>
            <View style={styles.rightTop}>
              <View style={styles.panel}>
                {
                  (achips && achips.length > 0 &&
                  chipsetIndex < achips.length &&
                  achips[chipsetIndex] &&
                  achips[chipsetIndex].chips &&
                  chipIndex < achips[chipsetIndex].chips.length) ?
                  <ChipInputSlider
                    chip={achips[chipsetIndex].chips[chipIndex]}
                    min={0}
                    max={100}
                    onChangeValue={onChangeValue}
                    width={100}
                  /> : null
                }
              </View>
            </View>
            <View style={styles.rightCenter}>
              <View style={styles.totalWrapper}>
                <Text style={styles.totalLabel}>Total Amount:</Text>
                <Text style={styles.total}>RM&nbsp;&nbsp;&nbsp;{total}</Text>
              </View>
            </View>
            <View style={styles.rightBottom}>
              <Button
                title="Next"
                disabled={nextDisabled}
                onPress={onWizardNext}
                style={styles.button} />
            </View>
          </View>
        </View>
      </Wrapper>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  // left
  left: {
    width: '55%',
    height: '100%',
    // backgroundColor: 'green',
    position: 'relative',
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  leftTop: {
    // width: '100%',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  leftMain: {
    width: '100%',
    // height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: 9,
    borderTopLeftRadius: 0,
    // borderTopRightRadius: 0,
    backgroundColor: Colors.DASH_MAIN,
  },
  // right
  right: {
    width: '45%',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
  },
  rightTop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightCenter: {
    flexWrap: 'wrap',
    padding: SC.GAP_S * SC.SCALE,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  rightBottom: {
    flexWrap: 'wrap',
    padding: SC.GAP_S * SC.SCALE,
    paddingLeft: 0,
  },
  panel: {
    // width: '80%',
    // backgroundColor: 'white',
    borderRadius: 9,
    padding: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  panelText: {
    color: 'white',
    fontSize: 6 * SC.SCALE,
  },
  totalWrapper: {
    borderRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
    padding: SC.GAP_S * SC.SCALE,
  },
  totalLabel: {
    color: 'white',
    fontSize: 6 * SC.SCALE,
  },
  total: {
    marginTop: SC.GAP_S * SC.SCALE,
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'right',
  },
  button: {
    width: '100%',
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

Opening.propTypes = {
  navigation: PropTypes.any,
}

export default Opening
