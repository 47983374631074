import React, {
  // useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  // ActivityIndicator,
  // LogBox,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import LabelValue from './LabelValue'
// import Log from '@utils/log'
import StringUtil from '@utils/string'

// styles
// import Styles from '@styles'
// const { SC, Colors } = Styles

const DetailView = (props) => {
  const {
    model,
    //accept labelStyle, labelStyle, value
    attributes,
    style,
    labelStyle,
    valueStyle,
    rowStyle,
    onPress,
  } = props

  const guessLabel = (attribute) => {
    if (typeof (attribute) === 'string') {
      return attribute.replace(/\_/g, ' ')
    }
    else if (attribute.label !== undefined || attribute.label != null) {
      return attribute.label
    }
    else if (attribute.key) {
      return attribute.key.replace(/\_/g, ' ')
    }

    // if (attribute.key.includes('_')) {
    //     return attribute.label.replace('_', '')
    // }
  }

  const getValue = (attribute) => {
    if (attribute.value) {
      if (typeof attribute.value === 'function') {
        return attribute.value(model)
      }
      return attribute.value
    } else if (typeof (attribute) === 'string') {
      return model[attribute]
    } else if (typeof attribute.key === 'function') {
      return attribute.key(model)
    } else {
      return model[attribute.key]
    }
  }

  const formatValue = (value, attribute) => {
    switch (attribute.vtype) {
      case 'price':
        return StringUtil.formatPrice(value || 0)
      default:
        return value
    }
  }

  return (
    <View style={[style]}>
      {
        attributes && attributes.map((attribute, aindex) => {
          if (attribute.custom) {
            return attribute.custom
          }

          return (<LabelValue
            index={aindex}
            label={guessLabel(attribute)}
            value={formatValue(getValue(attribute), attribute)}
            labelStyle={[{ ...labelStyle }, { ...attribute.labelStyle }]}
            valueStyle={[{ ...valueStyle }, { ...attribute.valueStyle }]}
            style={StyleSheet.flatten([{ ...rowStyle }, { ...attribute.rowStyle }])}
            key={typeof (attribute) === 'string' ? attribute : attribute.key}
            isPressable={attribute.isPressable}
            onPress={attribute.onPress || onPress}
          />)
        })
      }
    </View>
  )
}

// const styles = StyleSheet.create({
// row: {

// },
// })

DetailView.propTypes = {
  model: PropTypes.any,
  style: PropTypes.any,
  attributes: PropTypes.any,
  labelValuePairs: PropTypes.array,
  labelStyle: PropTypes.any,
  valueStyle: PropTypes.any,
  rowStyle: PropTypes.any,
  onPress: PropTypes.func,
}

export default DetailView
export {
  LabelValue,
}