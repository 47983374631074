// import util from 'util'
export const LOG_LOG = 'LOG_LOG'
export const LOG_CLEAR = 'LOG_CLEAR'

export const _onLog = (type, message, data) => ({
  type: LOG_LOG,
  data: {
    type,
    message,
    data,
  },
})

export const _onLogClear = () => ({
  type: LOG_CLEAR,
  data: {
  },
})
