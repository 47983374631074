import {
    StyleSheet,
    // Text,
    // TouchableOpacity,
    // View,
    // Image,
} from 'react-native'
import React, {
    // useEffectƒ
} from 'react'
// import Log from '@utils/log'
// import Feather from '@components/icons/feather'

import PropTypes from 'prop-types'
import Toast from '@components/toast'
import axios from 'axios'
import ApiFillCredit from '@apis/fillcredit'
import useLoading from '@hooks/useLoading'
import FillCreditComponent from './fillcreditcomponent'
import ApiUser from '@apis/user'
import { extractChipsWithQty, resetChipQty } from '@components/chipscontrol/chipcontrolutil'

// import Styles, {
//     CommonStyle as cs,
//     scaleSize,
// } from '@styles'
// const { SC, Colors } = Styles



const Credit = (props) => {

    const source = axios.CancelToken.source()

    const {
        chipSets,
        tableUuid,
    } = props

    const [, setLoading] = useLoading()


    const onEnter = async () => {
        setLoading(true)

        try {
            const resp = await ApiUser.login(source.token, 'admin', '11111111')
            const accessToken = resp.data.access_token
            const chipsQty = extractChipsWithQty(chipSets)

            await ApiFillCredit.createCredit(
                source.token,
                tableUuid,
                chipsQty,
                accessToken,
            )

            resetChipQty(chipSets)
            Toast.success(`Credit Chips Send Successful`)
        } catch (e) {
            Toast.exception(e, 'Fill Credit Failed')
        }
        setLoading(false)
    }

    return (
        <FillCreditComponent onEnter={onEnter} chipSets={chipSets} />
    )
}

Credit.propTypes = {
    chipSets: PropTypes.any,
    tableUuid: PropTypes.string.isRequired,
}

export default Credit