import React, {} from 'react'
import Feather from '@expo/vector-icons/Feather'

const FeatherIcon = (props) => {
  return (
    <Feather {...props}></Feather>
  )
}

export default FeatherIcon
