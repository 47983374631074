import {
    StyleSheet,
    // Text,
    // TouchableOpacity,
    View,
    Image,
} from 'react-native'
import React, {
    // useEffect,
    useState,
} from 'react'
// import Log from '@utils/log'
import Feather from '@components/icons/feather'
import Styles, {
    // CommonStyle as cs
} from '@styles'
import PropTypes from 'prop-types'

const { SC } = Styles



const Avatar = (props) => {
    const [imageError, setImageError] = useState(uri ? false : true)
    const {
        imgStyle,
        uri,
        iconBgStyle,
        iconSize = 15,
        borderSize = iconSize + 12,
        borderWidth = 1 * SC.SCALE,
    } = props

    return (
        imageError ?
            <View style={[styles.border, {
                width: borderSize * SC.SCALE,
                height: borderSize * SC.SCALE,
                borderRadius: borderSize * SC.SCALE,
                borderWidth: borderWidth,
            }, iconBgStyle]}>
                <Feather
                    style={[styles.icon]}
                    name='user'
                    size={iconSize * SC.SCALE}>
                </Feather>
            </View>

            : <Image
                source={{ uri: uri }}
                onError={() => setImageError(true)}
                style={[{ height: 100, width: 100, borderRadius: 50 }, imgStyle]} />

    )
}

const styles = StyleSheet.create({
    icon: {
        color: 'white',
        fontWeight: 'normal',
        // fontSize: 22,
        // size: 22,
    },
    border: {
        borderWidth: 1 * SC.SCALE,
        borderColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

Avatar.propTypes = {
    imgStyle: PropTypes.any,
    uri: PropTypes.string,
    iconBgStyle: PropTypes.any,
    iconSize: PropTypes.number,
    borderSize: PropTypes.number,
    borderWidth: PropTypes.number,
}

export default Avatar