import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import Container from '@screens/base/container'
import Toast from '@components/toast'
import Button from '@components/button'
import Feather from '@components/icons/feather'

import Wrapper from '../wiz-layout/wrapper'

import Styles from '@styles'
const { SC, Colors } = Styles

// // react-redux
import { useSelector, useDispatch } from 'react-redux'
import { PARAM_TABLE_MIN, PARAM_TABLE_MAX, updateTable } from '@actions/table'

import ApiTable from '@apis/table'
// import ApiBet from '@apis/bet'

const SelectFloat = (props) => {
  const {navigation} = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  const tableChips = table && table.chips

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  const [tableInfo, setTableInfo] = useState({})
  const [minMaxInfos, setMinMaxInfos] = useState([])
  const [minMaxIndex, setMinMaxIndex] = useState()

  // init

  useEffect(() => {
    Log.log('wizard-selectfloat: init', { isFocused })
    if (isFocused) {
      preload()
      init()
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const init = async () => {
    try {
      setLoading(true)

      let promises = []
      let promise
      promise = getTable()
      promises.push(promise)
      promise = getMinMax(tableUuid)
      promises.push(promise)
      await Promise.all(promises)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const getTable = async () => {
    let response = await ApiTable.get(source.token, tableUuid)
    Log.log('wizard: selectfloat getTable', response)
    if (response && response.data) {
      setTableInfo(response.data)
    }
  }

  const getMinMax = async (tableUuid) => {
    let response = await ApiTable.getMinMax(source.token, tableUuid)
    Log.log('wizard: selectfloat getMinMax', response)
    if (response && response.data) {
      let data = response.data
      setMinMaxInfos(data)
      let length = (data && data.length > 0 ? 0 : undefined)
      setMinMaxIndex(length)
    }
  }

  // header

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'home':
      case 'closing':
        return false
      case 'back':
      case 'account':
        return true
      default:
        return false
    }
  }, [])

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'back':
        return navigation.navigate('wizard-opening')
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [])

  const onWizardNext = async () => {
    if (nextDisabled) {
      Toast.show('Invalid selection')
      return
    }
    let minMaxInfo = minMaxInfos[minMaxIndex]
    await dispatch(updateTable(PARAM_TABLE_MIN, minMaxInfo.min_bet))
    await dispatch(updateTable(PARAM_TABLE_MAX, minMaxInfo.max_bet))
    navigation.navigate('wizard-enterid')
  }

  // components

  const total = useMemo(() => {
    let all = 0
    for (let t in tableChips) {
      let chip = tableChips[t]
      let value = chip.value
      let quantity = parseInt(chip.quantity, 10)
      if (quantity == null || isNaN(quantity)) continue
      all += quantity * value
    }
    return all
  }, [tableChips])

  const minDisabled = useMemo(() => {
    return (minMaxIndex <= 0)
  }, [minMaxIndex])

  const maxDisabled = useMemo(() => {
    return (minMaxIndex >= (minMaxInfos && minMaxInfos.length - 1))
  }, [minMaxInfos, minMaxIndex])

  const onMinPressed = useCallback(() => {
    if (minMaxIndex == null) {
      Log.error('invalid min max index')
      return
    }
    let index = minMaxIndex - 1
    if (index < 0) {
      index = 0
    }
    setMinMaxIndex(index)
  }, [minMaxIndex])

  const onMaxPressed = useCallback(() => {
    if (minMaxIndex == null) {
      Log.error('invalid min max index')
      return
    }
    let index = minMaxIndex + 1
    if (index >= minMaxInfos && (minMaxInfos.length - 1)) {
      index = minMaxInfos.length - 1
    }
    setMinMaxIndex(index)
  }, [minMaxIndex])

  const nextDisabled = useMemo(() => {
    return (minMaxIndex == null ||
      minMaxIndex < 0 ||
      minMaxIndex >= minMaxInfos.length ||
      tableUuid == null ||
      tableUuid.length <= 0)
  }, [tableUuid, minMaxIndex])

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <Wrapper
        title={'Select Float'}
        {...props}>
        <View style={styles.wrapper}>
          <View style={styles.top}>
            <View style={[styles.item, styles.itemTop]}>
              <Text style={styles.label}>Table No.</Text>
              <Text style={styles.value}>{ tableInfo && tableInfo.table_no }</Text>
            </View>
            <View style={[styles.item, styles.itemTop]}>
              <Text style={styles.label}>Float</Text>
              <Text style={styles.value}>RM { total }</Text>
            </View>
          </View>
          <View style={styles.center}>
            <View style={styles.side}>
              <Button
                disabled={minDisabled}
                style={styles.iconButton}
                onPress={onMinPressed}>
                <Feather
                  style={[
                    styles.icon,
                    (minDisabled ? styles.iconDisabled : {}),
                  ]}
                  name={'arrow-left'}>
                </Feather>
              </Button>
            </View>
            <View style={styles.middle}>
              {
                minMaxIndex != null && minMaxIndex < minMaxInfos.length ?
                <View style={[styles.item, styles.itemMiddle]}>
                  <Text style={styles.label}>Min Bet</Text>
                  <Text style={styles.value}>RM { minMaxInfos[minMaxIndex].min_bet }</Text>
                </View> : null
              }
              {
                minMaxIndex != null && minMaxIndex < minMaxInfos.length ?
                <View style={[styles.item, styles.itemMiddle]}>
                  <Text style={styles.label}>Max Bet</Text>
                  <Text style={styles.value}>RM { minMaxInfos[minMaxIndex].max_bet }</Text>
                </View> : null
              }
              {
                minMaxIndex != null && minMaxIndex < minMaxInfos.length ?
                <View style={[styles.item, styles.itemMiddle]}>
                  <Text style={styles.label}>Float Type</Text>
                  <Text style={styles.value}>{ tableInfo && tableInfo.float_type }</Text>
                </View> : null
              }
            </View>
            <View style={styles.side}>
              <Button
                disabled={maxDisabled}
                style={styles.iconButton}
                onPress={onMaxPressed}>
                <Feather
                  style={[
                    styles.icon,
                    (maxDisabled ? styles.iconDisabled : {}),
                  ]}
                  name={'arrow-right'}>
                </Feather>
              </Button>
            </View>
          </View>
          <View style={styles.bottom}>
            <Button
              title="Next"
              disabled={nextDisabled}
              onPress={onWizardNext}
              style={styles.button} />
          </View>
        </View>
      </Wrapper>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  top: {
    flexWrap: 'wrap',
    // margin: SC.GAP_S * SC.SCALE,
    width: '100%',
    flexDirection: 'row',
  },
  item: {
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG,
    alignItems: 'flex-start',
    padding: 6 * SC.SCALE,
    // flex: 1,
    flexDirection: 'row',
    margin: SC.GAP_S * SC.SCALE,
    // marginTop: 0,
    marginBottom: 0,
  },
  itemTop: {
    padding: 6 * SC.SCALE,
    height: 40 * SC.SCALE,
    flex: 1,
    marginTop: SC.GAP * 2 * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  itemMiddle: {
    flex: 1,
    height: 40 * SC.SCALE,
  },
  label: {
    flex: 1,
    color: 'white',
    fontSize: 8 * SC.SCALE,
  },
  value: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
  center: {
    flex: 1,
    width: '80%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: 'white',
  },
  side: {
    // flexWrap: 'wrap',
  },
  iconButton: {
    backgroundColor: 'transparent',
  },
  icon: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
  },
  iconDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
    fontSize: 12 * SC.SCALE,
  },
  middle: {
    flex: 1,
    // alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // backgroundColor: 'white',
    height: '100%',
  },
  bottom: {
    flexWrap: 'wrap',
  },
  button: {
    width: 300 * SC.SCALE,
    margin: SC.GAP,
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

SelectFloat.propTypes = {
  navigation: PropTypes.any,
}

export default SelectFloat
