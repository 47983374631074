import { LOG_LOG, LOG_CLEAR } from '@actions/log'

const INIT_STATE = {
  loading: false,
  error: null,
  logs: [],
}

const MAX_LOG = 50
const MAX_LOG_MIN = 30

const logReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOG_LOG: {
      // remove extra logs
      let logs = state.logs
      if (logs.length > MAX_LOG) {
        logs.splice(0, logs.length - MAX_LOG_MIN)
      }
      if (Object.prototype.hasOwnProperty.call(action, ('data'))) {
        logs.push(action.data)
        return {
          ...state,
          loading: false,
          error: null,
          logs,
        }
      }
      return INIT_STATE
    }
    case LOG_CLEAR: {
      return {
        ...state,
        loading: false,
        error: null,
        logs: [],
      }
    }
    // case 'LOG_RESET': {
    //   return INIT_STATE
    // }
    default:
      return state
  }
}

export default logReducer
