import React, { /* useCallback, useEffect, */ useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  // ActivityIndicator,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Log from '@utils/log'

// styles
import Styles from '@styles'
import { TouchableOpacity } from 'react-native-gesture-handler'
const { SC } = Styles

const LabelValue = (props) => {
  const {
    index,
    attr,
    label,
    value,
    style,
    labelStyle,
    valueStyle,
    isPressable,
    onPress,
  } = props

  const localLabelStyle = useMemo(() => {
    let finalStyle = {}
    if (Array.isArray(labelStyle)) {
      finalStyle = labelStyle.reduce(((r, c) => Object.assign(r, c)), {})
    }
    else {
      finalStyle = labelStyle
    }
    return finalStyle
  }, [labelStyle])

  const localVlueStyle = useMemo(() => {
    let finalStyle = {}
    if (Array.isArray(valueStyle)) {
      finalStyle = valueStyle.reduce(((r, c) => Object.assign(r, c)), {})
    }
    else {
      finalStyle = valueStyle
    }
    return finalStyle
  }, [valueStyle])

  let Container = View
  if (isPressable) {
    Container = TouchableOpacity
  }

  return (
    <Container style={[styles.row, style]} onPress={() => onPress(index, attr)}>
      <Text style={{ ...styles.label, ...localLabelStyle }}>{label}</Text>
      <Text style={[{ ...styles.value }, { ...localVlueStyle }]}>{value}</Text>
    </Container>
  )
}

const styles = StyleSheet.create({
  row: {
    // paddingTop: 5 * SC.SCALE,
    marginTop: 5 * SC.SCALE,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 12 * SC.SCALE,
    color: 'white',
    textTransform: 'capitalize',
  },
  value: {
    textAlign: 'right',
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
})

LabelValue.propTypes = {
  index: PropTypes.number,
  attr: PropTypes.object,
  label: PropTypes.any,
  value: PropTypes.any,
  style: PropTypes.any,
  labelStyle: PropTypes.any,
  valueStyle: PropTypes.any,
  isPressable: PropTypes.bool,
  onPress: PropTypes.func,
}

export default LabelValue
