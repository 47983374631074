import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // Alert,
  // Modal,
  // Platform,
  // Pressable,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useIsFocused } from '@react-navigation/native'

// import Constants from '@constants'
import Log from '@utils/log'
// import ChipCalculate from '@components/chips/calculate'

// import Button from '@components/button'
// import Dialog from '@components/dialog'
import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Chips from '@components/chips'
// import Slider from '@components/slider'
// import ChipInputSlider from '@screens/base/chipinputslider'
// import DialogMessage from '@screens/base/dialogmessage'

import ChipsInfoPanel from './layout/panel'
import ChipsLayout from './layout/chips'

import DialogUpdate from './layout/dialogupdate'

import Styles from '@styles'
const { SC, Colors } = Styles

// react-redux
import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'
import useLoading from '@hooks/useLoading'

// import ApiPit from '@apis/pit'
import ApiChip from '@apis/chip'
import ApiTable from '@apis/table'

const ChipsManagement = forwardRef((props, ref) => {
  const {
    // navigation,
    compact,
    // setLoading,
  } = props
  // let {
  //   tableUuid,
  // } = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  useImperativeHandle(ref, () => ({
    setTableUuid,
  }))

  // dispatch actions
  // const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const [tableUuid, setTableUuid] = useState(table && table.uuid)
  // if (!isPit) {
  //   tableUuid = table && table.uuid
  // }
  // const tableChips = table && table.chips

  const [chipSets, setChipSets] = useState([])

  const [floatInfo, setFloatInfo] = useState({})
  const [total, setTotal] = useState(0)

  // const total = useMemo(() => {
  //   return ChipCalculate.getTotal(chips)
  // }, [chips])

  const difference = useMemo(() => {
    let floatTotal = floatInfo?.total_float || 0
    let diff = total - floatTotal
    return diff
  }, [total])

  const [dialogUpdateVisible, setDialogUpdateVisible] = useState(false)
  // const [dialogMessage, setDialogMessage] = useState('')
  // const [dialogStatus, setDialogStatus] = useState()

  useEffect(() => {
    Log.log('chipsmanagement: init', { isFocused })
    if (isFocused && tableUuid) {
      init()
    }
    return deinit
  }, [isFocused])

  useEffect(() => {
    Log.log('chipsmanagement: tableUuid changed()')
    if (!tableUuid || tableUuid.length <= 0) {
      Log.log('chipsmanagement: invalid table uuid')
      return
    }
    init()
  }, [tableUuid])

  const init = useCallback(async () => {
    try {
      setLoading(true)
      const promises = []
      let promise = ApiChip.getAllChipSetAndChipInMap(source.token, tableUuid)
      promises.push(promise)
      promise = getFloat(tableUuid)
      promises.push(promise)
      let responses = await Promise.all(promises)
      let [chipset, finfo] = responses
      await tabulateChipsets(chipset, finfo)
    } catch (e) {
      Toast.exception(e, 'Fail to get chips')
    }
    setLoading(false)
  }, [tableUuid])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const getFloat = useCallback(async (tableUuid) => {
    let response = await ApiTable.getFloat(source.token, tableUuid)
    if (!response || !response.data) throw new Error('invalid float data')
    let data = response.data
    setFloatInfo(data)
    return data
  }, [])

  const tabulateChipsets = useCallback((chipset) => {
    const [chipSetMap] = chipset
    setChipSets(chipSetMap)
  })


  const onDialogUpdateSetVisible = useCallback((isVisible) => {
    if (total === 0) {
      Toast.denger('Total Float Cannot be 0')
      return
    }
    setDialogUpdateVisible(isVisible)
  })

  const onUpdateSuccessPress = () => {
    setDialogUpdateVisible(false)
  }

  const onReset = () => {
    //
  }

  const onUpdate = () => {
    onDialogUpdateSetVisible(true)
  }

  return (
    <View style={styles.container}>
      <View style={styles.containerTop}>
        <ChipsInfoPanel
          compact={compact}
          loading={loading}
          info={floatInfo}
          difference={difference}
          total={total}
          onUpdatePressed={() => onDialogUpdateSetVisible(true)}
        />
      </View>
      <View style={[styles.containerBottom]}>
        {
          <ChipsLayout
            compact={compact}
            difference={difference}
            chipSets={chipSets}
            onTotalAmountChanage={setTotal}
            onReset={onReset}
            onUpdate={onUpdate}
          />
        }
      </View>
      <DialogUpdate
        visible={dialogUpdateVisible}
        setVisible={onDialogUpdateSetVisible}
        tableUuid={tableUuid}
        total={total}
        chipSets={chipSets}
        onUpdateSuccessPress={onUpdateSuccessPress}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DASH_MAIN,
    margin: SC.GAP_S * SC.SCALE,
    marginRight: 0,
    padding: SC.GAP_S * SC.SCALE,
  },
  containerTop: {
    // backgroundColor: 'pink',
    flexBasis: 60 * SC.SCALE,
    width: '100%',
    // margin: SC.GAP_S * SC.SCALE,
    // padding: SC.GAP_S * SC.SCALE,
    paddingBottom: 0,
  },
  containerTabs: {
    width: '100%',
    flexDirection: 'row',
    padding: SC.GAP_S * SC.SCALE,
  },
  containerBottom: {
    // ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  // left
  left: {
    width: '65%',
    // height: '100%',
    // backgroundColor: 'green',
    position: 'relative',
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  leftTop: {
    // width: '100%',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  leftMain: {
    width: '100%',
    // height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomLeftRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
  },
  // right
  right: {
    width: '35%',
    // height: '100%',
    position: 'relative',
    flexDirection: 'column',
    paddingRight: SC.GAP_S * SC.SCALE,
    paddingBottom: SC.GAP_S * SC.SCALE,
  },
  rightStub: {
    height: 22 * SC.SCALE,
  },
  rightTop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DASH_MAIN,
    borderTopRightRadius: 9,
  },
  rightCenter: {
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    paddingBottom: 0,
    backgroundColor: Colors.DASH_MAIN,
  },
  rightBottom: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    backgroundColor: Colors.DASH_MAIN,
    marginBottom: SC.GAP_S * SC.SCALE,
    borderBottomRightRadius: 9,
  },
  button: {
    // width: '100%',
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
  },
  buttonReset: {
    backgroundColor: Colors.BUTTON_RESET,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  // // modal
  // modalText: {
  //   marginBottom: 15,
  //   textAlign: 'center',
  //   fontWeight: 'bold',
  // },
})

ChipsManagement.displayName = 'ChipsManagement'

ChipsManagement.propTypes = {
  // navigation: PropTypes.any,
  compact: PropTypes.bool,
  tableUuid: PropTypes.string,
  // setLoading: PropTypes.func,
}

export default ChipsManagement
