import React, {
  useEffect,
  useState,
  // useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
  // Image,
} from 'react-native'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import StringUtil from '@utils/string'

import { useIsFocused } from '@react-navigation/native'

// import Log from '@utils/log'
import Button from '@components/button'
import DetailView from '@components/detailview'

import Styles, {
  // CommonStyle as cs,
  // scaleSize,
} from '@styles'

// import PanelControl from '@components/chipscontrol'
// import Toast from 'react-native-root-toast'
// import ChipCalculate from '@components/chips/calculate'
// import DetailView from '@components/detailview'

const { SC, Colors } = Styles

// react-redux
// import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'
// import useLoading from '@hooks/useLoading'

// import ApiChip from '@apis/chip'

const Panel = (props) => {
  const {
    compact,
    info,
    difference,
    total,
    onUpdatePressed,
  } = props
  const isFocused = useIsFocused()
  // const [loading, setLoading] = useLoading()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()
  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tablePanel = table && table.chips

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [])

  const init = async () => {
    //
  }

  const deinit = () => {
    source.cancel('api_cancel')
  }

  // const styleCol = {
  //   flexBasis: 100,
  // }

  const [timeAttrs] = useState([
    { key: 'value', label: 'Value', style: styles.sectionTextTime },
  ])

  const renderTimes = (times = []) => {
    const renderItem = ({ item }) => {
      return (
        <FlatListItem
          model={item}
          attrs={timeAttrs}
          key={`${item.key}`}
          onPress={() => {
            //
          }}
          style={styles.timeWrapperOuter}
          containerStyle={styles.timeWrapper}
          {...props}
        />
      )
    }

    let timesData = _.map(times, (ti) => {
      return {
        value: ti,
      }
    })

    return (
      <FlatList
        data={timesData}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${index}`}
        style={[styles.timeList]}
      />
    )
    // return times.map((time, tindex) => {
    //   return (
    //     <Text style={[styles.sectionText, styles.sectionTextTime]} key={`${tindex}`}>{time}</Text>
    //   )
    // })
  }

  const detailModel = useMemo(() => {
    return {
      ...info,
      difference,
      total,
    }
  }, [info, difference, total])

  const renderCompact = () => {
    let attrStyle = {
      fontSize: 6 * SC.SCALE,
      flex: 1,
    }
    let diffStyle = {
      ...(difference < 0 ? styles.infoRed : {}),
      ...(difference > 0 ? styles.infoGreen : {}),
    }
    let totalStyle = {
      ...(difference < 0 || difference > 0 ? styles.infoGreen : {}),
    }
    let attrs = [
      { key: 'opening_float', label: 'Opening Float', style: attrStyle, vtype: 'price' },
      { key: 'current_float', label: 'Current Float', style: attrStyle, vtype: 'price' },
      { key: 'difference', label: 'Adjustment', style: attrStyle, valueStyle: diffStyle, vtype: 'price' },
      { key: 'total', label: 'Total Float', style: attrStyle, valueStyle: totalStyle, vtype: 'price' },
    ]
    return (
      <View style={styles.section}>
        <DetailView
          model={detailModel}
          attributes={attrs}
          style={styles.details}
          labelStyle={attrStyle}
          valueStyle={attrStyle}
        />
      </View>
    )
  }

  const renderFull = () => {
    return (
      <>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Opening Float</Text>
          <Text style={styles.sectionText}>{StringUtil.formatPrice(info?.opening_float)}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Current Float</Text>
          <Text style={styles.sectionText}>{StringUtil.formatPrice(info?.current_float)}</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.sectionFull}>
            <Text style={styles.sectionTitle}>Adjustment</Text>
            <Text style={[
              styles.sectionText,
              (difference < 0 ? styles.infoRed : {}),
              (difference > 0 ? styles.infoGreen : {}),
            ]}>
              {
                StringUtil.formatPrice(difference || 0)
              }
            </Text>
          </View>
          <View style={styles.sectionWrap}>
            <Button
              title={'Update'}
              style={styles.buttonCompact}
              disabled={difference === 0}
              onPress={() => onUpdatePressed()}
            />
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Total Float</Text>
          <Text style={[
            styles.sectionText,
            (difference < 0 || difference > 0 ? styles.infoGreen : {}),
          ]}>
            {StringUtil.formatPrice(total)}
          </Text>
        </View>
      </>
    )
  }

  return (
    <View style={styles.sections}>
      <View style={[styles.section, { flex: compact ? 1 : 1.3 }]}>
        <Text style={styles.sectionTitle}>Time of Count</Text>
        {renderTimes(info?.time_of_count)}
      </View>
      {compact ? renderCompact() : (() => renderFull())()}
    </View>
  )
}

const styles = StyleSheet.create({
  // components top
  sections: {
    // width: '80%',
    // backgroundColor: 'white',
    borderRadius: 9,
    padding: 0, // SC.GAP_S * SC.SCALE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
  },
  section: {
    borderRadius: 9,
    margin: SC.GAP_S * SC.SCALE / 2,
    marginBottom: 0,
    padding: SC.GAP_S * SC.SCALE,
    flex: 1,
    height: '100%',
    // justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DASH_LIGHT,
  },
  sectionTitle: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
  },
  sectionText: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
  },
  sectionTextTime: {
    fontSize: 5 * SC.SCALE,
    fontWeight: 'bold',
  },
  sectionFull: {
    flex: 1,
  },
  sectionWrap: {
    flexWrap: 'wrap',
  },
  buttonCompact: {
    padding: 3 * SC.SCALE,
    backgroundColor: Colors.SUCCESS,
  },
  //
  timeWrapperOuter: {
    padding: 0,
  },
  timeWrapper: {
    padding: 0,
    // margin: 0,
    // marginBottom: 0.5 * SC.SCALE,
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  timeList: {
    width: '100%',
  },
  // components
  details: {
    width: '100%',
  },
  panel: {
    width: '80%',
    // backgroundColor: 'white',
    borderRadius: 9,
    padding: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoWrapper: {
    padding: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
  infoLine: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoLabel: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
    flexWrap: 'wrap',
  },
  infoCurrency: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    flexWrap: 'wrap',
    marginLeft: 4 * SC.SCALE,
  },
  infoValue: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
    flex: 1,
    textAlign: 'right',
  },
  infoRed: {
    color: 'red',
    fontWeight: 'bold',
  },
  infoGreen: {
    color: Colors.SUCCESS,
    fontWeight: 'bold',
  },
})

Panel.propTypes = {
  compact: PropTypes.bool,
  info: PropTypes.any,
  difference: PropTypes.number,
  total: PropTypes.number,
  onUpdatePressed: PropTypes.func,
}

export default Panel
