import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
    // Platform,
    // SafeAreaView,
    // StatusBar,
    StyleSheet,
    Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
import Avatar from '@components/avatar'
import DetailView from '@components/detailview'
import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const PlayerInfo = (props) => {
    const {
        player,
    } = props

    return (
        <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center' }}>
                <Avatar
                    style={{ width: '60%' }} i
                    iconSize={40}
                    uri={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-GcsVNOMxYsjLWbS3t72EnCeDlAQVnTNeeg&usqp=CAU'} />
                <Text style={[{ color: 'white' }, cs.pt]}>Level:</Text>
                <Text style={[{ color: 'white' }, cs.pt]}>{player.level || '-'}</Text>
            </View>
            <View style={{ flex: 3 }}>
                <DetailView
                    model={player}
                    labelStyle={{ fontSize: 15 }}
                    valueStyle={{ fontSize: 15 }}
                    attributes={[
                        {
                            key: 'player_name',
                            label: 'Name:',
                            value: (model) => (model.player_name || 'Unknown Player'),
                        },
                        {
                            key: 'loyalty_point',
                            label: 'Loyalty point:',
                            value: (model) => model.loyalty_point || 0,
                        },
                        'play_time',
                        {
                            key: 'buy_in_amount',
                            value: (model) => model.buy_in_amount || 0,
                            label: 'Buy in amount:',
                        },
                        {
                            key: 'average_bet',
                            label: 'Average bet:',
                            value: (model) => model.average_bet || 0,
                        },
                    ]} />
            </View>
        </View>
    )
}

PlayerInfo.propTypes = {
    player: PropTypes.any,
}

export default PlayerInfo