import {
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
  // Image,
} from 'react-native'
import React, {
  useEffect,
  useState,
} from 'react'
// import Log from '@utils/log'
import PropTypes from 'prop-types'
import Button from '@components/button'
import _ from 'lodash'
import Log from '@utils/log'

import Styles, {
  CommonStyle as cs,
  scaleSize,
} from '@styles'
const { SC, Colors } = Styles


const ChipsetsButtons = (props) => {

  const {
    chipSets,
    index = 0,
    onChangeTab,
  } = props

  const [selectedIndex, setSelectedIndex] = useState(0)

  const onChange = (chipSet, index) => {
    setSelectedIndex(index)
    onChangeTab?.(chipSet)
  }

  return (
    <View style={[styles.container]}>
      {chipSets && index < chipSets.size ?
        _.map(Array.from(chipSets.values()), (chipSet, i) => {
          return (
            <Button
              style={[
                styles.tabButton,
                (i === selectedIndex ? styles.tabButtonActive : {}),
                (i === selectedIndex ? styles.styleActive : {}),
                (i !== selectedIndex ? styles.styleInactive : {}),
              ]}
              title={chipSet.name}
              key={`${i}`}
              onPress={() => onChange(chipSet, i)} />
          )
        }) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: SC.GAP * SC.SCALE,
    flexDirection: 'row',
    borderRadius: 9,
  },
  styleActive: {
    height: scaleSize(15),
    marginRight: scaleSize(2),
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  styleInactive: {
    height: scaleSize(15),
    marginRight: scaleSize(2),
  },
})

ChipsetsButtons.propTypes = {
  chipSets: PropTypes.any,
  index: PropTypes.any,
  onChangeTab: PropTypes.func,
}

export default ChipsetsButtons