import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'
import Button from '@components/button'
import ButtonHeader from '@components/buttonheader'

import Styles from '@styles'
const { SC, Colors } = Styles

const TabMain = (/* props */) => {
  // const {
  //   // children,
  // } = props

  const [options] = useState([{
    id: 1,
    label: 'Banker Win',
  }, {
    id: 2,
    label: 'Player Win',
  }, {
    id: 3,
    label: 'Tie Game',
  }])
  const [optionIndex, setOptionIndex] = useState()

  return (
    <View style={styles.content}>
      <Text style={styles.title}>Game #5</Text>
      <View style={styles.sections}>
        {
          options.map((op, oindex) => {
            return (
              <Button
                title={op.label}
                key={`${oindex}`}
                style={styles.button}
                stylePressed={styles.buttonPressed}
                titleStyle={styles.buttonText}
                onPress={() => setOptionIndex(oindex)}
                isPressed={(optionIndex === oindex)}>
              </Button>
            )
          })
        }
      </View>
      <ButtonHeader
        iconName={'fast-forward'}
        onPress={() => {
          //
        }}
        style={styles.iconWrapper}>
        <Text
          style={[
            styles.iconText,
          ]}>
          Next Game
        </Text>
      </ButtonHeader>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems:'center',
    // backgroundColor: 'white',
    paddingVertical: '6%',
  },
  title: {
    color: 'white',
    fontSize: 16 * SC.SCALE,
  },
  sections: {
    width: '65%',
    height: '100%',
    // backgroundColor: 'rgba(255,255,255,0.5)',
    padding: SC.GAP * SC.SCALE,
    paddingTop: '5%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 9 * SC.SCALE,
    flex: 1,
    backgroundColor: Colors.BUTTON_BG,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: SC.GAP_S * SC.SCALE,
  },
  buttonPressed: {
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  buttonText: {
    color: 'white',
    fontSize: 16 * SC.SCALE,
  },
  iconWrapper: {
    // margin: 10 * SC.SCALE,
    marginBottom: 2 * SC.SCALE,
    marginLeft: 4 * SC.SCALE,
    marginRight: 4 * SC.SCALE,
    position: 'absolute',
    top: 4 * SC.SCALE,
    right: 4 * SC.SCALE,
  },
  iconText: {
    color: 'white',
    fontSize: 6 * SC.SCALE,
    position: 'absolute',
    bottom: -12 * SC.SCALE,
    width: 40 * SC.SCALE,
    textAlign: 'center',
  },
})

TabMain.propTypes = {
  // children: PropTypes.any,
}

export default TabMain
