import React, { } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  StyleSheet,
  // TextInput,
  Text,
  View,
} from 'react-native'

import Chip from '@components/chip'

import Styles, { scaleSize } from '@styles'
const { SC } = Styles

const ReqChips = (props) => {
  const {
    reqChips,
    onPress,
    // onChangeValue,
  } = props
  return (
    <View style={styles.container}>
      {
        reqChips ?
          _.map(reqChips, (reqChip, index) => {
            return (
              <View style={styles.chipWrapper} key={`${index}`}>
                <Chip
                  title={reqChip.chip.title}
                  size={15}
                  image={reqChip.chip.image}
                  color={reqChip.chip.color}
                  colorBg={reqChip.chip.colorBg}
                  colorText={reqChip.chip.colorText}
                  onPress={() => onPress(reqChip.chip, index)}
                  styleText={{ fontSize: 12 }}
                  style={styles.chip}
                />
                <Text style={[styles.text]} >
                  x {reqChip.quantity}
                </Text>
              </View>
            )
          }) : null
      }
    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    // width: '100%',
    // flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingTop: scaleSize(1),
  },
  text: {
    color: 'white',
    fontSize: scaleSize(10),
    fontWeight: 'bold',
    marginStart: scaleSize(5),
    paddingBottom: scaleSize(5),
    // backgroundColor: 'red'
  },
  chipWrapper: {
    // width: 50 * SC.SCALE,
    // height: 50 * SC.SCALE,
    // borderRadius: '50%',
    marginHorizontal: scaleSize(4),
    marginTop: 0 * SC.GAP,
    flexDirection: 'row',
    // backgroundColor: 'white',
    // justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    // backgroundColor: 'pink',
    // position: 'relative',
  },
  chip: {
    // position: 'relative',
    // backgroundColor: 'yellow',
  },
})

ReqChips.propTypes = {
  // chips: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  onChangeValue: PropTypes.func,
  reqChips: PropTypes.any,
}

export default ReqChips
