import React, {
  // useEffect,
  // useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'
import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
  // TouchableOpacity,
} from 'react-native'

// import Log from '@utils/log'
// import Feather from '@components/icons/feather'
import PropTypes from 'prop-types'
import Chips from '@components/chips'
import SliderPanel from './sliderpanel'
import ChipsetsButtons from './chipsetsbuttons'
// import LoadingView from '../loadingview'
import { resetChipQty } from '@components/chipscontrol/chipcontrolutil'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const {
  SC,
  Colors,
} = Styles


const ChipsControl = (props) => {
  const {
    chips,
    onChipsChange,
    chipSets,
    onEnter,
    onEnterDisabled,
    isCashMode,
    noCashToggle,
    noButtons,
    prependLeftPanel,
    appendLeftPanel,
    prependButton,
    appendButton,
    leftPanelJustifyContent = 'space-between',
    styleContainer,
    styleLeftPanel,
    styleSliderPanel,
    sliderWidth,
    onTotalAmountChanage,
    // showCash = true,
    // disabled = false,
    /*
    summary function with object params
    {
    total_cash_amount: totalCashAmount,
    total_chip_amount: totalChipAmount,
    }
    refer fill.js for usage
    */
    summary,
  } = props
  const refSliderPanel = useRef()

  const [chipIndex, setChipIndex] = useState(0)
  const [totalChipAmount, setTotalChipAmount] = useState(0)
  const [selectedChips, setSelectedChips] = useState([])
  const [btnDisable, setBtnDisable] = useState(true)

  const onChipsSelect = (chip, index) => setChipIndex(index)

  useEffect(() => {
    if (chips) {
      setSelectedChips(chips)
    }
  }, [chips])

  useEffect(() => {
    if (chipSets && chipSets.size > 0) {
      const first = chipSets.values().next().value
      setSelectedChips(first.chips)
    }
  }, [chipSets])


  const onShowCashToggle = (showCash) => {
    if (refSliderPanel.current && refSliderPanel.current.toggleShowCash) {
      let ref = refSliderPanel.current
      ref.toggleShowCash(showCash)
    }
  }

  const calculateAmount = () => {
    let total = 0
    if (chipSets) {
      for (const chipSet of chipSets.values()) {
        chipSet.chips.forEach(item => total += item.count * item.value)
      }
    }
    else {
      selectedChips.forEach(item => total += item.count * item.value)
    }
    setBtnDisable(total < 1)
    setTotalChipAmount(total)
    onTotalAmountChanage?.(total)
  }

  const onChipChangeValue = (chip, count) => {
    const found = selectedChips.find(item => item.uuid === chip.uuid)
    if (found) {
      found.count = +count
      calculateAmount()
      onChipsChange?.([...selectedChips])
    }
  }

  const onReset = () => {
    if (chipSets) {
      resetChipQty(chipSets)
    }
    else if (selectedChips) {
      selectedChips.forEach(item => item.count = 0)
    }

    onChipsChange?.([...selectedChips])
  }

  const onChangeTab = (chipSet) => setSelectedChips(chipSet.chips)

  return (
    <View style={[styles.container, styleContainer, cs.roundedEnd]}>
      <View style={[styles.leftPanel, styleLeftPanel, { justifyContent: leftPanelJustifyContent }]}>
        {prependLeftPanel}
        {chipSets && <ChipsetsButtons chipSets={chipSets} onChangeTab={onChangeTab} />}
        <View style={[{ paddingVertical: scaleSize(10) }]}>
          <Chips
            chips={selectedChips}
            onPress={(chip, index) => {
              onShowCashToggle(false)
              onChipsSelect(chip, index)
            }}
            onChangeValue={(chip, value) => onChipChangeValue(chip, value)}
            isCashMode={isCashMode}
          />
        </View>
        {appendLeftPanel}
      </View>
      <View style={{ flex: 2 }}>
        <SliderPanel
          ref={refSliderPanel}
          chip={selectedChips[chipIndex] || {}}
          onChipChangeValue={onChipChangeValue}
          onEnter={onEnter}
          onEnterDisabled={onEnterDisabled}
          onReset={onReset}
          isCashMode={isCashMode}
          noCashToggle={noCashToggle}
          noButtons={noButtons}
          totalChipAmount={totalChipAmount}
          prependButton={prependButton}
          appendButton={appendButton}
          summary={summary}
          sliderWidth={sliderWidth}
          stylePanel={styleSliderPanel}
          disabled={btnDisable}
        />
      </View>
    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 2,
  },
  leftPanel: {
    flex: 2,
    backgroundColor: Colors.DASH_MAIN,
    flexGrow: 3,
    paddingHorizontal: SC.GAP_S * SC.SCALE,
    paddingVertical: SC.GAP * SC.SCALE,
    borderBottomLeftRadius: SC.BORDER_RADIUS,
  },
})

ChipsControl.propTypes = {
  chips: PropTypes.array,
  chipSets: PropTypes.any,
  onChipsChange: PropTypes.func,
  onEnter: PropTypes.func.isRequired,
  onEnterDisabled: PropTypes.func,
  isCashMode: PropTypes.bool,
  noCashToggle: PropTypes.bool,
  noButtons: PropTypes.bool,
  prependLeftPanel: PropTypes.element,
  appendLeftPanel: PropTypes.element,
  prependButton: PropTypes.element,
  appendButton: PropTypes.element,
  leftPanelJustifyContent: PropTypes.string,
  styleContainer: PropTypes.any,
  styleLeftPanel: PropTypes.any,
  styleSliderPanel: PropTypes.any,
  sliderWidth: PropTypes.number,
  summary: PropTypes.func,
  laoding: PropTypes.bool,
  showCash: PropTypes.bool,
  onTotalAmountChanage: PropTypes.func,
  // disabled: PropTypes.bool,
}

export default ChipsControl
