import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
} from 'react-native'
// import Feather from '@components/icons/feather'
import DialogMessage from '@screens/base/dialogmessage'

import { useIsFocused } from '@react-navigation/native'

import Log from '@utils/log'
import StringUtil from '@utils/string'
// import ChipsCalculate from '@components/chips/calculate'

import Styles, {
  CommonStyle as cs,
  scaleSize,
} from '@styles'

import ChipsControl from '@components/chipscontrol'
import DetailView from '@components/detailview'
import Toast from 'react-native-root-toast'
import ChipCalculate from '@components/chips/calculate'

import DialogDrop from './dialogdrop'

const { SC, Colors } = Styles

// react-redux
import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'

// import ApiChip from '@apis/chip'
// import ApiPlayer from '@apis/player'
import ApiDrop from '@apis/drop'

import useSubmit from '../hooks/useSubmit'

const FastDrop = forwardRef((props, ref) => {
  const {
    setLoading,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const {onSubmitFastDrop} = useSubmit(source.token)

  useImperativeHandle(ref, () => ({
    onPreviousPressed: () => {
      Log.log('fastdrop: onPreviousPressed!')
      setDialogDropRefresh(StringUtil.random())
      setDialogDropVisible(true)
    },
  }))

  // dispatch actions
  // const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  // const auth = useSelector(state => state.auth)
  // const token = auth && auth.token

  const [chips, setChips] = useState([{
    uuid: '123',
    colour: 'Blue',
    value: 10,
    count: 0,
  }, {
    uuid: '124',
    colour: 'Black',
    value: 20,
    count: 0,
  }])

  const [dialogMessage, setDialogMessage] = useState('')
  const [dialogMessageVisible, setDialogMessageVisible] = useState(false)
  const [dialogDropVisible, setDialogDropVisible] = useState(false)
  const [dialogDropRefresh, setDialogDropRefresh] = useState(StringUtil.random())

  useEffect(() => {
    if (isFocused) {
      init()
    }
    // setChips(ChipCalculate.mapChips(chips, [], 1))
    return deinit
  }, [])

  const init = async () => {
    try {
      setLoading(true)
      // API calls here
      await getCashList(tableUuid)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const getCashList = async (tableUuid) => {
    if (!tableUuid || tableUuid.length <= 0) {
      throw new Error('invalid table UUID')
    }
    let response

    response = await ApiDrop.getCashList(source.token)
    Log.log(`wizard-opening: all cash: ${tableUuid}`, response)
    if (!response || !response.data) throw new Error('invalid cash list data')

    let cashlist = response.data
    cashlist = _.map(cashlist, (cl, index) => {
      // console.log('-- cl', cl)
      // let value = parseInt(cl, 10)
      return {
        uuid: index,
        title: `${parseInt(cl.value, 10)}`,
        value: parseInt(cl.value, 10),
        count: 0,
      }
    })
    setChips(cashlist)
  }

  const deinit = () => {
    source.cancel('api_cancel')
  }

  const onEnterDisabled = useCallback(() => {
    let total = 0
    for (let c in chips) {
      let chip = chips[c]
      if (chip && chip.count > 0) {
        total += chip.count
      }
    }
    return total <= 0
  }, [chips])

  const onEnter = async () => {
    try {
      setLoading(true)
      let total = ChipCalculate.getTotal(chips)
      let response = await onSubmitFastDrop(tableUuid, 'sit', total)
      console.log('fastdrop: sit: add drop response', response)
      setLoading(false)

      setDialogMessage('Fast dropped successfully.')
      setDialogMessageVisible(true)

      Toast.show(`Success`, {
        type: 'success',
      })
    } catch (error) {
      setLoading(false)
      Toast.show(`Failed: ${error && error.message}`, {
        type: 'danger',
      })
    }
  }

  return (
    <View style={styles.container}>
      <ChipsControl
        chips={chips}
        onChipsChange={setChips}
        onEnterDisabled={onEnterDisabled}
        onEnter={onEnter}
        isCashMode={true}
        summary={(data) => {
          return <DetailView
            style={[styles.summary, cs.pa]}
            model={data}
            labelStyle={{ fontSize: 20 }}
            valueStyle={{ fontSize: 20 }}
            attributes={[
              {
                label: 'Fast Drop Amount',
                key: 'fill_amount',
                value: '',
              },
              {
                label: '',
                rowStyle: { paddingTop: scaleSize(20) },
                key: 'total_chip_amount',
              },
            ]} />
        }}
      />
      <DialogMessage
        visible={dialogMessageVisible}
        setVisible={setDialogMessageVisible}
        message={dialogMessage}
        onPress={() => setDialogMessageVisible(false)}
      />
      <DialogDrop
        visible={dialogDropVisible}
        setVisible={setDialogDropVisible}
        refreshCode={dialogDropRefresh}
        onMessage={(message) => {
          setDialogDropVisible(false)
          setDialogMessage(message)
          setDialogMessageVisible(true)
        }}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  summary: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
})

FastDrop.displayName = 'FastDrop'

FastDrop.propTypes = {
  setLoading: PropTypes.func,
}

export default FastDrop
