import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
  // Image,
} from 'react-native'
import React, {
  useEffect,
  useState,
} from 'react'
// import Log from '@utils/log'
// import Feather from '@components/icons/feather'
import PropTypes from 'prop-types'
import ChipsControl from '@components/chipscontrol'
import DetailView from '@components/detailview'

import axios from 'axios'
import { useSelector } from 'react-redux'
import useLoading from '@hooks/useLoading'

import Styles, {
  CommonStyle as cs,
  scaleSize,
} from '@styles'
const { SC, Colors } = Styles



const FillCreditComponent = (props) => {

  const {
    chipSets,
    onEnter,
  } = props

  // const [chips, setChips] = useState([])

  // useEffect(() => {
  //   if (chipSets && chipSets.size > 0) {
  //     setChips(chipSets.values()[0].chips)
  //   }
  // }, [chipSets])

  // const onChangeTab = (chipSet) => {
  //   if (chipSets.get(chipSet.uuid)) {
  //     setChips(chipSets.get(chipSet.uuid).chips)
  //   }
  // }

  return (
    <ChipsControl
      chipSets={chipSets}
      onEnter={onEnter}
      noCashToggle={true}
      summary={(data) => {
        return <DetailView
          style={[styles.summary, cs.pa]}
          model={data}
          labelStyle={{ fontSize: 20 }}
          valueStyle={{ fontSize: 20 }}
          attributes={[
            {
              label: 'Fill Amount',
              key: 'fill_amount',
              value: '',
            },
            {
              label: '',
              rowStyle: { paddingTop: scaleSize(20) },
              key: 'total_chip_amount',
            },
          ]} />
      }}
    />
  )
}

const styles = StyleSheet.create({
  summary: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
})

FillCreditComponent.propTypes = {
  chipSets: PropTypes.any,
  onEnter: PropTypes.func,
}

export default FillCreditComponent