import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'

import LinearGradient from '@components/lineargradient'
import S from '@styles'
const { SC, Colors } = S
// const { moderateScale } = SC

import WizardConstants from '../wizconstants'

const SubHeader = (props) => {
  const {
    route,
    // title,
  } = props

  const [progress] = useState(WizardConstants.PROGRESS)

  return (
    <View style={styles.container}>
      <LinearGradient
        style={styles.gradient}
        colors={[Colors.DASH_HEADER_FROM, Colors.DASH_HEADER_TO]}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
      />

      <View style={styles.lineWrapper}>
        <View style={styles.line}>
        </View>
      </View>

      <View style={styles.itemsWrapper}>
        {
          progress.map((item, index) => {
            const name = route.name && route.name.replace('wizard-', '')
            let isItem = name === item.key
            return (
              <View style={styles.item} key={`progress-${index}`}>
                <View style={[styles.disc, (!isItem ? styles.discHollow : {})]}>
                  {
                    isItem ?
                      <View style={styles.discInner}>
                      </View> : null
                  }
                </View>
                <Text style={styles.header}>{item.title}</Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 36 * SC.SCALE,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: SC.GAP_S * SC.SCALE,
    marginBottom: 0,
    borderTopLeftRadius: SC.BORDER_RADIUS,
    borderTopRightRadius: SC.BORDER_RADIUS,
  },
  header: {
    fontSize: 6 * SC.SCALE,
    color: 'white',
  },
  itemsWrapper: {
    // marginLeft: 20 * SC.SCALE,
    // marginRight: 20 * SC.SCALE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%',
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center',
    // minWidth: 200,
    // backgroundColor: 'red',
  },
  discInner: {
    width: 3 * SC.SCALE,
    height: 3 * SC.SCALE,
    // margin: 2 * SC.SCALE,
    borderRadius: 2 * SC.SCALE,
    backgroundColor: Colors.DASH_MAIN,
  },
  disc: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 8 * SC.SCALE,
    height: 8 * SC.SCALE,
    margin: 2 * SC.SCALE,
    marginTop: 9 * SC.SCALE,
    borderRadius: 4 * SC.SCALE,
    backgroundColor: 'white',
    position: 'relative',
  },
  discHollow: {
    width: 5 * SC.SCALE,
    height: 5 * SC.SCALE,
  },
  lineWrapper: {
    position: 'absolute',
    top: '50%',
    left: '10%',
    right: '10%',
    width: '79%',
  },
  line: {
    width: '100%',
    borderColor: 'white',
    borderWidth: 1,
  },
})

SubHeader.propTypes = {
  route: PropTypes.any,
  // title: PropTypes.string,
}

export default SubHeader
