import React, {
  // useEffect,
  // useState,
  // useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

import { useSelector } from 'react-redux'
import { /* PARAM_TABLE_UUID, PARAM_TABLE_CHIPS, */ PARAM_TABLE_MIN, PARAM_TABLE_MAX, PARAM_TABLE_ROLE_ID /* PARAM_TABLE_SESSION_UUID, */ } from '@actions/table'

const StepSummary = (props) => {
  const {
    title,
    // summary,
    tableData,
    stepIndex,
  } = props

  const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  const tableChips = table && table.chips
  // const tableRoleId = table && table.roleId

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  // summary
  const total = useMemo(() => {
    let all = 0
    for (let t in tableChips) {
      let chip = tableChips[t]
      let value = chip.value
      let quantity = parseInt(chip.quantity, 10)
      if (quantity == null || isNaN(quantity)) continue
      all += quantity * value
    }
    return all
  }, [tableChips])

  const summary = useMemo(() => {
    // Log.log('wizard-enterid: summary changed')
    // { key: PARAM_TABLE_UUID, value: table && table[PARAM_TABLE_UUID] },
    return {
      min_bet: table?.[PARAM_TABLE_MIN],
      max_bet: table?.[PARAM_TABLE_MAX],
      role_id: table?.[PARAM_TABLE_ROLE_ID],
      game_category: '11',
      float: total,
      table_no: tableData?.table_no,
      float_type: tableData?.float_type,
      token: token,
      step: `${stepIndex}` || '(null)',
    }

    // { key: PARAM_TABLE_MIN, value: table && table[PARAM_TABLE_MIN] },
    // { key: PARAM_TABLE_MAX, value: table && table[PARAM_TABLE_MAX] },
    // { key: PARAM_TABLE_ROLE_ID, value: table && table[PARAM_TABLE_ROLE_ID] },
    // { key: 'float', value: total },
    // { key: 'token', value: token, style: styles.fontMini },
    // { key: 'step', value: (`${stepIndex}` || '(null)') },
  }, [total, token, stepIndex, tableData])

  return (
    <View style={styles.middleFilled}>
      <View style={styles.summaryWrapper}>
        <Text style={[styles.title, { marginBottom: SC.GAP * SC.SCALE }]}>
          {title}
        </Text>
        <DetailView
          style={[styles.summaryRow]}
          model={summary}
          attributes={[
            'min_bet',
            'max_bet',
            'game_category',
            'table_no',
            'float_type',
            'float',
          ]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  middleFilled: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.BUTTON_ACTIVE,
    height: '90%',
    borderRadius: 9,
  },
  title: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
  summaryWrapper: {
    width: '80%',
    // height: '100%',
    // backgroundColor: 'pink',
    alignItems: 'center',
    // overflow: 'hidden',
  },
  summaryRow: {
    // flex: 1,
    width: '100%',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    marginTop: SC.GAP / 3 * SC.SCALE,
  },
  summaryLabel: {
    flex: 1,
    color: 'white',
    fontSize: 12 * SC.SCALE,
  },
  summaryValue: {
    flex: 3,
    color: 'white',
    fontSize: 7 * SC.SCALE,
    // flexWrap: 'wrap',
    // flexShrink: 1,
  },
})

StepSummary.propTypes = {
  title: PropTypes.string,
  // summary: PropTypes.object,
  tableData: PropTypes.object,
  stepIndex: PropTypes.number,
}

export default StepSummary
