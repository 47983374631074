import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import TableList from '@screens/base/tablelist'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import { TabHeader } from '@components/tabs'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
import History from './layout/history'
import JackpotStatus from './layout/jackpotstatus'
import TableListPanel from './layout/tablelistpanel'
import JackpotListPanel from './layout/jackpotlistpanel'
import JackpotMain from './layout/jackpotmain'
const { SC, Colors } = Styles

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const JackpotManagement = (/* props */) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    // Log.log('jackpotmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    // Log.log('jackpotmanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [isTriggerJackpot, setIsTriggerJackpot] = useState(false)

  const JACKPOT_STATUS = 1
  const HISTORY = 2

  const [tabs, setTabs] = useState([{
    id: JACKPOT_STATUS,
    name: 'Jackpot Status',
  }, {
    id: HISTORY,
    name: 'History',
  },])

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  return (
    <View style={[styles.container, cs.pa]}>
      <View style={[styles.tablePanel, cs.pe]}>
        {!isTriggerJackpot && <TableListPanel />}
        {isTriggerJackpot && <JackpotListPanel />}
      </View>
      <View style={[styles.main]}>
        <TabHeader
          tabs={tabs}
          selectedItem={selectedTab}
          onChangeTab={setSelectedTab}
          tabStyle={{ width: scaleSize(70) }}
        />
        {selectedTab.id === JACKPOT_STATUS && <JackpotMain onTriggerJackpot={() => setIsTriggerJackpot(true)} />}
        {selectedTab.id === HISTORY && <History />}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  tablePanel: {
    flex: 1,
    alignItems: 'stretch',
  },
  main: {
    flex: 3,
  },
})

JackpotManagement.propTypes = {
  navigation: PropTypes.any,
}

export default JackpotManagement
