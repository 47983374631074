import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'

// components
// import Container from '@screens/base/container'
import LinearGradient from '@components/lineargradient'
import SubHeader from './subheader'
import Panel from './panel'

// styles
import Styles from '@styles'
const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

const Wrapper = (props) => {
  // props
  const {
    children,
    title,
    info,
    style,
    items,
    curr,
    onPanelButtonPressed,
    isPrevious,
    onPanelButtonPreviousPressed,
  } = props

  // methods

  return (
    <View style={[styles.wrapper, style]}>
      <LinearGradient
        colors={[Colors.BG_FROM, Colors.BG_TO]}
        style={styles.gradient}
      />
      <View style={[StyleSheet.absoluteFill]}>
        <SubHeader style={styles.top}
          title={title}
          info={info}
          {...props}>
        </SubHeader>

        <View style={styles.bottom}>
          <View style={styles.content}>
            {(
              children ? children : null
            )}
          </View>

          <Panel
            items={items}
            curr={curr}
            onButtonPressed={onPanelButtonPressed}
            isPrevious={isPrevious}
            onButtonPreviousPressed={onPanelButtonPreviousPressed}>
          </Panel>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    margin: 20,
    backgroundColor: Colors.DASH_BG,
    borderRadius: SC.BORDER_RADIUS,
    borderColor: Colors.BORDER,
    borderWidth: 1 * SC.SCALE,
    flex: 1,
    flexDirection: 'column',
  },
  top: {
  },
  bottom: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    // margin: moderateScale(SC.GAP),
    // marginLeft: moderateScale(SC.GAP),
    // marginBottom: moderateScale(SC.GAP),
    // backgroundColor: 'blue',
  },
})

Wrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  info: PropTypes.object,
  style: PropTypes.any,
  items: PropTypes.array,
  curr: PropTypes.string,
  onPanelButtonPressed: PropTypes.func,
  isPrevious: PropTypes.bool,
  onPanelButtonPreviousPressed: PropTypes.func,
}

export default Wrapper
