// import { Dimensions, Platform } from 'react-native'
// import RNFS from 'react-native-fs'

// const DB_PATH = () => {
//   let path = RNFS.DocumentDirectoryPath
//   if (Platform.OS === 'ios') {
//     path = RNFS.LibraryDirectoryPath + '/LocalDatabase'
//   }
//   return path
// }
const DB_FILENAME = 'appdb'

const Constants = {
  APP_STORE_URL: 'https://apps.apple.com/app/rnbase/id1504081111',
  PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.rnbase.rnbase',
  APP_STORE_LINK_ID: '1446891111',
  APP_STORE_NAME: 'rnbase',
  PLAYSTORE_PACKAGE_NAME: 'com.rnbase.rnbase',
  UPDATE: 'check_version',
  ANDROID_VERSION: '0.0.1',
  ANDROID_BUILD: '1',
  IOS_VERSION: '0.0.1',
  IOS_BUILD: '1',
  // DB_PATH,
  DB_FILENAME,
  NOTIFICATION_TOPIC: 'rnbase-general',
  NOTIFICATION_CHANNEL: 'rnbase-channel',
  // TMS related
  SERIAL_CODE: 'PIT000001',
  CASH_CONST: -2,
  CASH_MIN: 0,
  CASH_MAX: 99999,
}

export default Constants
