import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import Log from '@utils/log'
import { TabHeader } from '@components/tabs'
import Toast from '@components/toast'
import Sitting from './layout/sitting'
import Standing from './layout/standing'
// import Container from '@screens/base/container'
// import Button from '@components/button'
import PlayerChip from './layout/playerchip'

import Styles, { CommonStyle as cs } from '@styles'
const { SC/* , Colors */ } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

const Player = forwardRef((props, ref) => {

  const {
    // navigation,
    setLoading,
  } = props

  useImperativeHandle(ref, () => ({
    intercepintercep(info) {
      switch (info.name) {
        case 'back':
          Log.log('selectedPlayer', selectedPlayer)
          if (selectedPlayer) {
            setSelectedPlayer(undefined)
            return true
          }
          return false
        default:
          return false
      }
    },
  }))

  useEffect(() => {
    init()
  }, [])

  const init = () => { // todo: implement
    try {
      // setLoading(true)
      // API calls here
      // setTimeout(() => {
      //   setLoading(false)
      // }, 200)
    } catch (error) {
      // setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const tabs = [
    { id: 1, name: 'Sitting' },
    { id: 2, name: 'Standing' },
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [selectedPlayer, setSelectedPlayer] = useState(undefined)

  const onPlayerSelect = (player) => {
    setSelectedPlayer(player)
  }

  const onBackPress = () => {
    setSelectedPlayer(undefined)
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <TabHeader
        tabs={tabs}
        selectedItem={selectedTab}
        onChangeTab={setSelectedTab}
      />
      <View style={[styles.body, cs.roundedEnd]}>
        {!selectedPlayer && selectedTab.id === 1 && <Sitting onPlayerSelect={onPlayerSelect} />}
        {!selectedPlayer && selectedTab.id === 2 && <Standing onPlayerSelect={onPlayerSelect} />}
        {selectedPlayer && <PlayerChip onBackPress={onBackPress} player={selectedPlayer} />}
      </View>
    </View>
  )
})

Player.displayName = "Player"

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    // flex: 1,
    flexGrow: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: Colors.DASH_MAIN,
  },
  header: {
    flexDirection: 'row',
  },
  body: {
    color: 'white',
    flexGrow: 1,
    borderBottomStartRadius: SC.BORDER_RADIUS,
  },
})

Player.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
  onHeaderButtonPressed: PropTypes.func,
}

export default Player
