import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'

import Container from '@screens/base/container'
// import Button from '@components/button'

import Styles from '@styles'
const { SC } = Styles

// react-redux
// import { useSelector } from 'react-redux'
// import { updateUsername } from '@actions/users'

const Home = () => {
  return (
    <Container style={styles.container}>
      <View style={{alignItems:'center'}}>
        <Text style={{padding:5,fontSize:16,fontWeight:'bold'}}>Oops, screen not found..</Text>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
})

Home.propTypes = {
  navigation: PropTypes.any,
}

export default Home
