import React, {
  // useEffect,
  useCallback,
  // useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import Dialog from '@components/dialog'
import LoginView from '@screens/base/loginview'
import Toast from '@components/toast'
import LoadingView from '@components/loadingview'

import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import { useSelector, useDispatch } from 'react-redux'
import { clearAuth, updateToken, updateName } from '@actions/auth'

import Styles from '@styles'
const { SC, Colors } = Styles
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import { scaleSize } from '../../../../styles'

const DlgSearchResult = (props) => {
  const {
    visible,
    setVisible,
    searchResults,
    onItemSelected,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, onLoading] = useLoading()
  const source = axios.CancelToken.source()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const token = auth && auth.token
  const username = auth && auth.name

  const loginRef = useRef()

  const searchResultAttrs = [
    { key: 'player_name', label: 'Player Name', style: styles.attrStyle },
  ]

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={searchResultAttrs}
        key={`${item.key}`}
        onPress={() => {
          onItemSelected?.(item)
        }}
        style={styles.timeWrapperOuter}
        containerStyle={styles.timeWrapper}
        {...props}
      />
    )
  }


  return (
    <Dialog
      visible={visible}
      minWidth={'40%'}
      minHeight={'60%'}
      setVisible={setVisible}
      style={styles.dialog}>
      <View style={[styles.wrapper]}>
        <FlatList
          data={searchResults}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}`}
          style={[styles.flatList]}
        />
      </View>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingEnd: scaleSize(12),
  },
  wrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: scaleSize(8),
  },
  attrStyle: {
    alignItems: 'stretch',
  },
  flatList: {
    width: '100%',
  },
})

DlgSearchResult.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onItemSelected: PropTypes.func,
  searchResults: PropTypes.array,
}

export default DlgSearchResult
