import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'
import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const StaffList = (props) => {
  const {
    onStaffSelected,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    Log.log('dealermanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('dealermanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [data, setData] = useState([
    { id: 1, table_no: 'RL02', sit_no: '3', staff_id: '5337086', name: 'Trump Walker', time: '4:02:25', position: 'Manager' },
    { id: 2, table_no: 'RL03', sit_no: '2', staff_id: '9379053', name: 'Bertha Summercille', time: '3:37:58', position: 'Dealer' },
  ])

  const styleCol = {
    flexBasis: scaleSize(120),
    // flexGrow: 1,
  }

  const [attrs] = useState([
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'staff_id', label: 'ID', style: styleCol },
    { key: 'name', label: 'Name', style: styleCol },
    { key: 'time', label: 'Time', style: styleCol },
    { key: 'position', label: 'Position', style: styleCol },
  ])

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        {...props}
      />
    )
  }

  const onSetSelected = (item) => {
    Log.log('onItemselect', item)
    onStaffSelected?.()
  }

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={attrs}
        key={`${item.key}`}
        onPress={() => onSetSelected(item)}
        {...props}
      />
    )
  }

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={item => item?.id}
      ListHeaderComponent={renderHeader}
      style={[styles.container, cs.rounded]}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
  },
})

StaffList.propTypes = {
  onStaffSelected: PropTypes.func,
}

export default StaffList
