import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
import Log from '@utils/log'
import PropTypes from 'prop-types'
import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
import Feather from '@components/icons/feather'

const { SC, Colors } = Styles

const IconButton = (props) => {

    const {
        name,
        size,
        rounded,
        iconStyle,
        height,
        width,
        style,
        onPress,
        disabled,
        color,
        label,
    } = props

    const getHeight = () => height || size + scaleSize(15)
    const getWidth = () => width || size + scaleSize(15)
    const getRadious = () => {
        if (height) {
            return height
        }
        return size + scaleSize(15)
    }

    return (
        <TouchableOpacity
            style={{ justifyContent: 'center', alignItems: "center" }}
            onPress={onPress}
            disabled={disabled}>
            <View style={[rounded ? {
                ...styles.roundedIcon,
                borderRadius: getRadious(),
            } : '',
            { height: getHeight(), width: getWidth(), borderColor: color },
                style,
            ]}>
                <Feather
                    style={[iconStyle, { color: color }]}
                    name={name}
                    size={size}>
                </Feather>
            </View>
            <Text style={[{ color: color }]}>{label}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    roundedIcon: {
        borderWidth: 0.5 * SC.SCALE,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

IconButton.propTypes = {
    name: PropTypes.any,
    size: PropTypes.any,
    rounded: PropTypes.bool,
    iconStyle: PropTypes.any,
    height: PropTypes.number,
    width: PropTypes.number,
    radious: PropTypes.number,
    style: PropTypes.any,
    onPress: PropTypes.any,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    label: PropTypes.string,
}

export default IconButton