import React, {
  // useEffect,
  // useState,
  // useCallback,
  // useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

const StepSuccess = (props) => {
  const {
    title,
    status,
  } = props

  return (
    <View style={styles.middleFilled}>
      <Text style={styles.title}>
        {
          (status === 'success') ?
          'Table Opened successfully!' :
          title
        }
      </Text>
      {/* <DetailView
          style={[styles.summaryRow]}
          model={{
            'table_uuid': tableUuid,
            ...rolesInfo,
          }}
          attributes={[
            'table_uuid',
            ...Object.keys(rolesInfo),
          ]} /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  middleFilled: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.BUTTON_ACTIVE,
    height: '90%',
    borderRadius: 9,
  },
  title: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
})

StepSuccess.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
}

export default StepSuccess
