import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native'

import Styles from '@styles'
const { SC, Colors } = Styles

const Button = (props) => {
  const {
    onPress,
    title,
    disabled,
    isPressed,
    style,
    children,
  } = props
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.button,
        style,
        (disabled && !children ? styles.buttonDisabled : {}),
        (isPressed ? styles.buttonPressed : {}),
      ]
    }>
      {
        children ?
          children :
          <Text
            style={[
              styles.text,
              (disabled ? styles.textDisabled : {}),
            ]}
          >
            {title}
          </Text>
      }
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6 * SC.SCALE,
  },
  buttonDisabled: {
    backgroundColor: Colors.BUTTON_BG_DISABLED,
  },
  buttonPressed: {
    backgroundColor: Colors.BUTTON_BG_PRESSED,
  },
  text: {
    color: Colors.WHITE,
    fontSize: 8 * SC.SCALE,
  },
  textDisabled: {
    color: 'gray',
  },
})

Button.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  style: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any,
}

export default Button
