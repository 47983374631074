import React from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'

// import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'

// import { useSelector } from 'react-redux'
import Button from '@components/button'
import Dialog from '@components/dialog'
// import Dialog from '@components/dialog'
// import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Slider from '@components/slider'
// import ApiTable from '@apis/table'
// import ApiPlayer from '@apis/player'
// import axios from 'axios'

import Styles from '@styles'
const { SC, Colors } = Styles

// const source = axios.CancelToken.source()
const DialogMessage = (props) => {
  const {
    visible,
    setVisible,
    message,
    onPress,
  } = props

  return (
    <Dialog
      visible={visible}
      minWidth={'60%'}
      minHeight={'50%'}
      setVisible={setVisible}
      style={styles.dialog}>
      <View style={[styles.wrapper]}>
        <Button
          title={message}
          titleStyle={styles.buttonFont}
          onPress={onPress}
          style={styles.buttonReset} />
      </View>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_BG,
  },
  // content
  wrapper: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
  },
  buttonReset: {
    width: '70%',
    height: '80%',
    marginTop: 10 * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  buttonFont: {
    color: 'white',
    fontSize: 16,
  },
})

DialogMessage.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  message: PropTypes.string,
  onPress: PropTypes.func,
}

export default DialogMessage
