import _ from 'lodash'
import { UPDATE_USERNAME } from '@actions/users'

const userDefault = _.assign({}, {
  username: '',
})

const userReducer  = (user = userDefault, action) => {
  switch (action.type) {
    case UPDATE_USERNAME:
      return {
        user,
        username: action.username,
      }
    default:
      return user
  }
}

export default userReducer
