import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import Constants from '@constants'

import Toast from '@components/toast'
import Container from '@screens/base/container'
// import Button from '@components/button'
import LoginView from '@screens/base/loginview'

import Styles from '@styles'
const { SC } = Styles

import SplashImage from '@assets/present.png'

// react-redux
import { useSelector, useDispatch } from 'react-redux'
// import { clearTable } from '@actions/table'
import { updateToken, updateName } from '@actions/auth'
import { setPit } from '@actions/pit'

import ApiUser from '@apis/user'
import ApiPit from '@apis/pit'

const Login = (props) => {
  const {
    navigation,
  } = props
  const [loading, onLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()
  // const table = useSelector(state => state.table)

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  // header controls

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'back':
      case 'closing':
      case 'home':
        return false
      case 'account':
        return true
      default:
        return false
    }
  })

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'home':
        return onHome()
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [onHome])

  // components

  useEffect(() => {
    Log.log('Login: init', { isFocused })
    if (isFocused) {
      preload()
      init()
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const init = () => { // todo: implement
    try {
      onLoading(true)
      // API calls here
      setTimeout(() => {
        onLoading(false)
      }, 200)
    } catch (error) {
      onLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const onHome = () => {
    navigation.navigate('home')
  }

  // const onWizardNext = async () => {
  //   // await dispatch(clearTable())
  //   // navigation.navigate('wizard-selecttable')
  //   Log.log('login: PIT')
  // }

  const onLogin = async (username, password) => {
    try {
      onLoading(true)
      let response = await ApiUser.login(source.token, username, password)
      if (!response || !response.data || !response.data.access_token) {
        throw new Error('invalid login data')
      }

      let respPit = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)

      let data = response.data
      let token = data.access_token
      await dispatch(updateToken(token))
      await dispatch(updateName(data?.name))
      await dispatch(setPit(respPit.data))
      Log.log('DialogAccount: login response', response)
      Toast.show('Login successfully.')
      navigation.navigate('home')
      onLoading(false)
    } catch (error) {
      onLoading(false)
    }
  }

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.imageWrapper} onPress={onHome}>
          <Image source={SplashImage} style={styles.image} />
          <View style={styles.loginWrapper}>
            <LoginView onLogin={onLogin} />
          </View>
        </TouchableOpacity>
        {/* <Button
          title="Login PIT"
          onPress={onWizardNext}
          style={styles.button} /> */}
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
  },
  image: {
    width: 300 * SC.SCALE,
    height: 180 * SC.SCALE,
  },
  loginWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 300 * SC.SCALE,
    marginTop: SC.GAP,
  },
})

Login.propTypes = {
  navigation: PropTypes.any,
}

export default Login
