import React, { useState, useCallback } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  StyleSheet,
  // Text,
  View,
} from 'react-native'
// import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import Feather from '@components/icons/feather'
import FlatList from '@components/flatlist'
import Toast from '@components/toast'

import Styles from '@styles'
const { Colors } = Styles

const DATA_ID = 'id'

const TabHistory = (/* props */) => {
  // const {
  //   // children,
  // } = props

  const [data] = useState([{
    id: 1,
    label: 'Banker Win',
  }, {
    id: 2,
    label: 'Player Win',
  }, {
    id: 3,
    label: 'Tie Game',
  }])
  const [dataIndex, setDataIndex] = useState()

  const onPressItem = useCallback((info) => {
    let index = _.findIndex(data, (l) => l[DATA_ID] === info[DATA_ID])
    if (index < 0 || index >= (data && data.length)) {
      Toast.show('Invalid index or data', {
        type: 'warning',
      })
      return
    }
    setDataIndex(index)
  })

  return (
    <View style={styles.content}>
      <FlatList
        index={dataIndex}
        data={data}
        dataKey={DATA_ID}
        dataInfos={[{
          key: 'id',
        }, {
          key: 'label',
        }]}
        onPress={onPressItem}
        style={styles.list}
        styleItem={styles.listitem} />
    </View>
  )
}

const styles = StyleSheet.create({

  content: {
    width: '70%',
    height: '100%',
    justifyContent: 'center',
    alignItems:'center',
    // backgroundColor: 'white',
    // paddingVertical: '6%',
  },
  list: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'rgba(255,255,255,0.5)',
  },
  listitem: {
    backgroundColor: Colors.BUTTON_BG,
  },
})

TabHistory.propTypes = {
  // children: PropTypes.any,
}

export default TabHistory
