import React, {
  // useEffect,
  useState,
  // useMemo,
} from 'react'

import {
  // StyleSheet,
  Text,
  // TouchableOpacity,
  View,
  // Image,
  // TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import Cash from '@components/cash'
import Chip from '@components/chip'

// import Styles,
// {
//     CommonStyle as cs,
//     scaleSize,
// } from '@styles'
// const { SC, Colors } = Styles

const BtnToggleCash = (props) => {
  const {
    isToggle,
    onPress,
  } = props

  const [chip] = useState({
    title: '#',
    color: 'white',
    colorBg: 'gray',
    colorText: 'black',
  })

  return (
    <View style={[{ justifyContent: 'flex-end', flexDirection: 'row' }]}>
      <View style={{ alignItems: 'center' }}>
        {
          isToggle ?
          <Chip
            title={chip.title}
            size={24}
            image={chip.image}
            color={chip.color}
            colorBg={chip.colorBg}
            colorText={chip.colorText}
            onPress={onPress}
            style={{}}></Chip> :
          <Cash scale={0.6} onPress={onPress}></Cash>
        }
        <Text style={{ color: 'white' }}>{isToggle ? 'Chips' : 'Cash'}</Text>
      </View>
    </View>
  )
}

// const styles = StyleSheet.create({
//     text: {
//         color: 'white',
//         alignItems: 'center',
//         // position: 'absolute',
//         // bottom: -10 * SC.SCALE,
//     },
// })

BtnToggleCash.propTypes = {
  isToggle: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
}

export default BtnToggleCash