import React, {
  // useEffect,
  // useState,
  // useCallback,
} from 'react'
// import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Tabs from '@components/tabs'
import PropTypes from 'prop-types'

// import Table from '../layout/table'

import Styles, { scaleSize } from '@styles'
const { SC /*Colors*/ } = Styles


const PlayerAvatar = (props) => {
  const {
    playerDetail,
  } = props

  return (
    <View style={[{ alignItems: 'center' }]}>
      <Image
        source={{ uri: 'https://dummyimage.com/400x400/000/fff' }}
        style={styles.avatar}
      />
      <Text style={styles.title}>{playerDetail.player_name}</Text>
      <Text style={styles.title}>Level: {playerDetail.level}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: scaleSize(12),
    color: 'white',
  },
  avatar: {
    width: scaleSize(80),
    height: scaleSize(80),
    borderRadius: scaleSize(40),
    margin: SC.GAP_S * SC.SCALE,
  },
})

PlayerAvatar.propTypes = {
  // navigation: PropTypes.any,
  playerDetail: PropTypes.object,
}

export default PlayerAvatar
