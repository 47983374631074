import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
// import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import ButtonHeader from '@components/buttonheader'
import Slider from '@components/slider'
// import Tabs from '@components/tabs'
import Toast from '@components/toast'
import DetailView from '@components/detailview'
import PropTypes from 'prop-types'
import ApiPlayer from '@apis/player'
import ApiTable from '@apis/table'
import PlayerAvatar from '../layout/playeravatar'
import useLoading from '@hooks/useLoading'
import { useSelector } from 'react-redux'
import Log from '@utils/log'

// import Table from '../layout/table'

import Styles, { scaleSize } from '@styles'
import GridView from '../layout/gridview'
const { SC, Colors } = Styles


const AddToTable = (props) => {
  const {
    route,
    onChangePage,
  } = props

  const tables = useSelector(state => state.table.tables)
  const [playerDetail, setPlayerDetail] = useState({})
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useLoading()
  const source = axios.CancelToken.source()
  const [positions, setPositions] = useState([{ id: 'sit', key: 'SIT', isSelected: false }, { id: 'stand', key: 'STAND', isSelected: false }])
  const [seatsNo, setSeatsNo] = useState([{ no: '1' }, { no: '2' }, { no: '3' }, { no: '4' }, { no: '5' }, { no: '6' }, { no: '7' }])
  const [selectedTable, setSelectedTable] = useState({})
  const [selectedPosition, setSelectedPosition] = useState({})
  const [selectedSeatNo, setSelectedSeatNo] = useState({})

  useEffect(() => {
    getPlayerDetail()
    setTableList(tables.map(item => ({ ...item, isSelected: false })))
  }, [])

  const getPlayerDetail = async () => {
    try {
      setLoading(true)
      const resp = await ApiPlayer.detail(source.token, route?.params?.player_uuid)
      setPlayerDetail(resp.data)
      // stub
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }

  const onConfirm = async () => {
    setLoading(true)
    try {
      await ApiTable.assignSeat(source.token, selectedTable.uuid, route?.params?.player_uuid, selectedPosition.id, selectedSeatNo.no)
      Toast.success('Player Add To Table Successful')
      onChangePage('main')
    } catch (e) {
      Toast.exception(e, `Failed`)
    }
    setLoading(false)
  }

  const onCancel = () => onChangePage('main')

  const onTableSelected = (selected) => {
    setTableList(tables.map(item => ({ ...item, isSelected: item.uuid === selected.uuid })))
    setSelectedTable(selected)
  }

  const onPositionsSelected = (selected) => {
    setPositions(positions.map(item => ({ ...item, isSelected: item.key === selected.key })))
    setSelectedPosition(selected)
  }

  const onSeatSelected = (selected) => {
    setSeatsNo(seatsNo.map(item => ({ ...item, isSelected: item.no === selected.no })))
    setSelectedSeatNo(selected)
  }


  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.left}>
          <PlayerAvatar playerDetail={playerDetail} />
          <Button title={'Confirm'} style={styles.btnConfirm} onPress={onConfirm} />
          <Button title={'Cancel'} style={styles.btnCancel} onPress={onCancel} />
        </View>
        <View style={styles.right}>
          <Text style={styles.title}>Table No.</Text>
          <GridView items={tableList} attributeKey='table_no' onItemSelected={onTableSelected} />
          <Text style={styles.title}>Position</Text>
          <GridView items={positions} attributeKey='key' onItemSelected={onPositionsSelected} />
          <Text style={styles.title}>Seat No.</Text>
          <GridView items={seatsNo} attributeKey='no' onItemSelected={onSeatSelected} />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: SC.GAP_S * SC.SCALE,
  },
  content: {
    flex: 1,
    borderRadius: 9,
    borderTopLeftRadius: 0,
    backgroundColor: Colors.DASH_MAIN,
    flexDirection: 'row',
  },
  left: {
    flex: 3,
    padding: SC.GAP * SC.SCALE,
    alignItems: 'stretch',
  },
  right: {
    flex: 5,
    padding: SC.GAP * SC.SCALE,
    alignItems: 'stretch',
  },
  btnConfirm: {
    marginTop: scaleSize(8),
    backgroundColor: Colors.YELOW,
  },
  btnCancel: {
    marginTop: scaleSize(8),
    backgroundColor: Colors.BUTTON_RESET,
  },
  title: {
    fontSize: scaleSize(10),
    color: 'white',
    fontWeight: 'bold',
    paddingVertical: scaleSize(5),
  },
})

AddToTable.propTypes = {
  // navigation: PropTypes.any,
  onChangePage: PropTypes.func,
  route: PropTypes.object,
}

export default AddToTable
