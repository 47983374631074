
const Colors = {
  STATUS_BAR: '#75095f',
  WHITE: '#ffffff',
  BORDER: '#ffe42f',
  BG_FROM: '#a40f8a',
  BG_TO: '#0e9ffa',
  DASH_HEADER_FROM: '#3a1948',
  DASH_HEADER_TO: '#af0b57',
  DASH_BG: '#20004b', // use for dashboard background
  DASH_TAB: '#442679',
  DASH_TAB_2: '#5d0689',
  DASH_TAB_INACTIVE: '#20005f',
  DASH_MAIN: '#442679',
  DASH_LIGHT: '#684ca6',
  // DASH_TAB: '#684ca6',
  BUTTON_BG: '#684ca6',
  BUTTON_BG_PRESSED: '#442b89',
  BUTTON_BG_DISABLED: '#eeeeee',
  BUTTON_ACTIVE: '#5d0689',
  BUTTON_WIZ: '#ffaa00',
  BUTTON_RESET: '#ea2151',
  // generic colors:
  SUCCESS: '#2fbb00',
  GREEN_TEXT: '#28F92D',
  GREY: '#666666',
  YELOW: '#ffaa00',
}

export default Colors
