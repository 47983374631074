import {
    StyleSheet,
    Text,
    // TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import Log from '@utils/log'
import PropTypes from 'prop-types'
import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
import ItemHeader from './itemheader'
import ItemBody from './itembody'

const { SC, Colors } = Styles

const ProgressListItem = (props) => {

    const {
        item,
        allChips,
    } = props

    const [isExpanded, setIsExpanded] = useState(false)
    const onToggleExpand = () => setIsExpanded(!isExpanded)

    return (
        <View style={styles.container}>
            <ItemHeader onToggleExpand={onToggleExpand} isExpanded={isExpanded} item={item} />
            {isExpanded && <ItemBody item={item} allChips={allChips} />}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        // flex: 2,
        justifyContent: 'center',
        // alignContent: 'stretch',
        // alignItems: 'stretch',
    },
})

ProgressListItem.propTypes = {
    item: PropTypes.any,
    allChips: PropTypes.any.isRequired,
}

export default ProgressListItem