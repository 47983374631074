import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // FlatList,
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import Constants from '@constants'
import Log from '@utils/log'

import Container from '@screens/base/container'
import Toast from '@components/toast'
import Button from '@components/button'
// import ListItem from './layout/listitem'
import FlatList from '@components/flatlist'

import Wrapper from '../wiz-layout/wrapper'

import Styles from '@styles'
const { SC, Colors } = Styles

// react-redux
import { useSelector, useDispatch } from 'react-redux'
import { PARAM_TABLE_UUID, updateTable } from '@actions/table'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'

const SelectTable = (props) => {
  const {navigation} = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  const [data, setData] = useState([])
  const DATA_ID = 'uuid'

  const [index, setIndex] = useState(null)
  const nextDisabled = useMemo(() => {
    return (index == null || index < 0 ||
      index >= (data && data.length) ||
      tableUuid == null ||
      tableUuid.length <= 0)
  }, [index, tableUuid])

  // init

  useEffect(() => {
    Log.log('wizard-selecttable: init')
    if (isFocused) {
      preload()
      init()
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const init = useCallback(async () => {
    try {
      setLoading(true)
      setData(undefined)

      // get all
      let response
      response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
      if (!response || !response.data || !response.data.uuid) {
        throw new Error('invalid response or data or uuid')
      }

      let pitUuid = response.data.uuid
      response = await ApiTable.getAll(source.token, pitUuid)
      if (!response || !response.data) {
        throw new Error('invalid data')
      }
      let list = response.data
      // // dummy
      // list.push({
      //   uuid: '73ba1454-7a91-11ed-9f37-b489923e82d1',
      //   'table_no': 'RL00',
      //   'img_path': null,
      //   'game_category': 'Roulette',
      //   'flat_type': 'Rolling',
      // })
      setData(list)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Load tables: ${error}`, {
        type: 'danger',
        placement: 'top',
      })
    }
  }, [])

  // header

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'home':
      case 'closing':
        return false
      case 'back':
      case 'account':
        return true
      default:
        return false
    }
  }, [])

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'back':
        return navigation.navigate('splash')
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [])

  const onWizardNext = () => {
    navigation.navigate('wizard-opening')
  }

  // components

  useEffect(() => {
    let list = data
    if (tableUuid && tableUuid.length > 0) {
      let found = _.findIndex(list, (item) => item.uuid === tableUuid)
      if (found !== -1 && found < list.length) {
        setIndex(found)
      }
    } else {
      setIndex(undefined)
    }
  }, [data, tableUuid])

  const onPressItem = useCallback(async (info) => {
    let index = _.findIndex(data, (l) => l[DATA_ID] === info[DATA_ID])
    if (index < 0 || index >= (data && data.length)) {
      Toast.show('Invalid index or data', {
        type: 'warning',
      })
      return
    }
    setIndex(index)

    // update table UUID
    let item = data[index]
    if (item && item.uuid) {
      await dispatch(updateTable(PARAM_TABLE_UUID, item.uuid))
    }
  }, [data])

  // const onRenderItem = ({ item }) => {
  //   let i = _.findIndex(data, (l) => l[DATA_ID] === item[DATA_ID])
  //   // console.log('-- render item', { item, index, i })
  //   return <ListItem
  //     onPress={onPressItem}
  //     data={item}
  //     title={item.game_category}
  //     code={item.table_no}
  //     style={(i === index ? { backgroundColor: Colors.BUTTON_ACTIVE } : {})}
  //   />
  // }

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <Wrapper
        title={'Select Table'}
        {...props}>
        <View style={styles.wrapper}>
          <View style={styles.top}>
            <View style={styles.left}>
              <Text style={styles.header}>Game Categories</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.header}>Table No.</Text>
            </View>
          </View>
          <View style={styles.center}>
            <FlatList
              style={styles.panel}
              index={index}
              data={data}
              dataKey={DATA_ID}
              dataInfos={[{
                key: 'game_category',
              }, {
                key: 'table_no',
              }]}
              onPress={onPressItem} />
          </View>
          <View style={styles.bottom}>
            <Button
              title="Next"
              onPress={onWizardNext}
              disabled={nextDisabled}
              style={styles.button} />
          </View>
        </View>
      </Wrapper>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  top: {
    width: '80%',
    flexDirection: 'row',
  },
  left: {
    flex: 1,
  },
  right: {
    flexWrap: 'wrap',
  },
  header: {
    fontSize: 8 * SC.SCALE,
    color: 'white',
    padding: 6 * SC.SCALE,
    paddingLeft: 12 * SC.SCALE,
    paddingRight: 12 * SC.SCALE,
  },
  center: {
    width: '100%',
    flex: 1,
  },
  panel: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'white',
  },
  bottom: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 300 * SC.SCALE,
    margin: SC.GAP_S * SC.SCALE,
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

SelectTable.propTypes = {
  navigation: PropTypes.any,
}

export default SelectTable
