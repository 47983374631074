
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import Log from '@utils/log'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const History = (props) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    // Log.log('jackpotmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    // Log.log('jackpotmanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [data, setData] = useState([
    { id: 1, jackpot: 'BA66', table_no: 'RL02', sit_no: '3', player_id: '5337086', jackpot_value: '103002', time: '4:02:25', reset_time: '4:03:38' },
    { id: 2, jackpot: 'BA66', table_no: 'RL03', sit_no: '2', player_id: '9379053', jackpot_value: '512', time: '3:37:58', reset_time: '3:38:14' },
  ])

  const styleCol = {
    flexBasis: scaleSize(50),
  }
  const styleColMore = {
    flexBasis: scaleSize(70),
  }

  const [attrs] = useState([
    { key: 'jackpot', label: 'Jackpot', style: styleCol },
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'sit_no', label: 'Sit No', style: styleCol },
    { key: 'player_id', label: 'Player ID', style: styleColMore },
    { key: 'jackpot_value', label: 'Jackpot Value', style: styleColMore, vtype: 'price' },
    { key: 'time', label: 'Time', style: styleCol },
    { key: 'reset_time', label: 'Reset Time', style: styleCol },
  ])

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        {...props}
      />
    )
  }

  const onSetSelected = (item) => {
    Log.log('onItemselect', item)
  }

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={attrs}
        key={`${item.key}`}
        onPress={() => onSetSelected(item)}
        {...props}
      />
    )
  }

  return (
    <View style={[styles.container, cs.roundedEnd, cs.roundedBottom]}>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item?.id}
        ListHeaderComponent={renderHeader}
        style={[styles.list]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
  },
})

History.propTypes = {
  navigation: PropTypes.any,
}

export default History
