import React, {} from 'react'
// import PropTypes from 'prop-types'
// import {
//   Text,
//   TouchableOpacity,
// } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

const LinearGradientComponent = (props) => {
  return (
    <LinearGradient {...props}></LinearGradient>
  )
}

export default LinearGradientComponent
