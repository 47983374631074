import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'
import StaffList from './layout/stafflist'
import TableCloseList from './layout/tablecloselist'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const DealerManagement = (props) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    Log.log('dealermanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('dealermanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [isStaffSelected, setIsStaffSelected] = useState(false)

  const onStaffSelected = (item) => {
    Log.log('onItemselect', item)
    setIsStaffSelected(true)
  }

  return (
    <View style={[styles.container, cs.pa]}>
      {!isStaffSelected && <StaffList onStaffSelected={onStaffSelected} />}
      {isStaffSelected && <TableCloseList />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  list: {
    backgroundColor: Colors.DASH_MAIN,
  },
})

DealerManagement.propTypes = {
  navigation: PropTypes.any,
}

export default DealerManagement
