import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'

import Button from '@components/button'
import ButtonHeader from '@components/buttonheader'

// import LinearGradient from 'react-native-linear-gradient'
import S from '@styles'
const { SC } = S
// const { moderateScale } = SC

const Panel = (props) => {
  const {
    items,
    curr,
    onButtonPressed,
    isPrevious,
    onButtonPreviousPressed,
  } = props

  const PanelButton = (props) => {
    let isPressed = (props.route === curr)
    return (
      <Button
        index={props.index}
        title={props.title}
        isPressed={isPressed}
        onPress={() => onButtonPressed(props)}
        style={styles.buttonWrapper}>
      </Button>
    )
  }

  PanelButton.propTypes = {
    route: PropTypes.string,
    index: PropTypes.number,
    title: PropTypes.string,
  }

  return (
    <View style={styles.containerOuter}>
      <View style={styles.container}>
        {/* <LinearGradient
          style={[StyleSheet.absoluteFill, styles.gradient]}
          colors={[Colors.DASH_HEADER_FROM, Colors.DASH_HEADER_TO]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
        /> */}

        <View style={styles.wrapContainer}>
          {items.map((item, i) => {
            return (
              <PanelButton
                key={i}
                title={item.title}
                route={item.route}
                index={i}
                style={styles.buttonWrapper}>
              </PanelButton>)
          })}
        </View>
      </View>
      {
        isPrevious ?
        <View style={styles.containerBottom}>
          <ButtonHeader
            iconName={'clock'}
            onPress={() => onButtonPreviousPressed()}
            disabled={false}
            style={styles.button}>
            <Text
              style={[
                styles.title,
                // isDisabled(name) ? styles.titleDisabled : {},
              ]}>
              Previous
            </Text>
          </ButtonHeader>
        </View> : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  containerOuter: {
    width: 100 * SC.SCALE,
    flexDirection: 'column',
  },
  container: {
    flexDirection: 'column',
    width: 100 * SC.SCALE,
    flex: 1,
  },
  containerBottom: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
  },
  gradient: {
    margin: SC.GAP_S * SC.SCALE,
    marginTop: 0,
  },
  wrapContainer: {
    flex:1,
    // padding: SC.GAP,
    paddingTop: SC.GAP_S * SC.SCALE,
    paddingLeft: SC.GAP_S * SC.SCALE,
    paddingRight: SC.GAP_S * SC.SCALE,
    // justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: 0,
    alignSelf: 'stretch',
    marginBottom: SC.GAP_S * SC.SCALE,
  },
  iconDisabled: {
    color: 'gray',
  },
  title: {
    color: 'white',
    fontSize: 6 * SC.SCALE,
    position: 'absolute',
    bottom: -10 * SC.SCALE,
  },
  titleDisabled: {
    color: 'gray',
  },
})

Panel.propTypes = {
  items: PropTypes.array,
  curr: PropTypes.string,
  onButtonPressed: PropTypes.func,
  isPrevious: PropTypes.bool,
  onButtonPreviousPressed: PropTypes.func,
}

export default Panel
