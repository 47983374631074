import {
    StyleSheet,
    // Text,
    // TouchableOpacity,
    View,
    // Image,
    FlatList,
} from 'react-native'
import React, {
    // useEffect,
    useCallback,
    // useState,
} from 'react'
// import Log from '@utils/log'
// import Styles, { CommonStyle as cs } from '@styles'
import PlayerCard from './PlayerCard'
import AddPlayerCard from './AddPlayerCard'
import PropTypes from 'prop-types'

// const { SC } = Styles

const PlayerGrid = (props) => {
    // const [imageError, setImageError] = useState(false)
    // const [seatNo, setSeatNo] = useState(-1)
    const {
        players,
        onPlayerClick,
        onAddPlayerClick,
    } = props

    const onCardClick = useCallback((player) => {
        onPlayerClick?.(player)
    }, [onPlayerClick])

    const onAddCardClick = useCallback(() => {
        onAddPlayerClick?.()
    }, [onAddPlayerClick])

    const renderItem = ({ item }) => {
        if (item.isLast) {
            return (<View style={styles.item}>
                <AddPlayerCard player={item} onClick={(player) => onAddCardClick()} />
            </View>)
        }
        else {
            return (<View style={styles.item}>
                <PlayerCard player={item} onClick={(player) => onCardClick(player)} />
            </View>)
        }

    }

    return (
        <View style={[styles.grid]}>
            <FlatList
                data={players}
                numColumns={8}
                renderItem={renderItem}
                contentContainerStyle={{ alignItems: "stretch" }}
                keyExtractor={(item) => item.id}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    grid: {
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    item: {
        margin: 5,
        // width: 40 * SC.SCALE,
    },
})

PlayerGrid.propTypes = {
    players: PropTypes.any,
    onPlayerClick: PropTypes.func,
    onAddPlayerClick: PropTypes.func,
}

export default PlayerGrid