import React, {
  // useEffect,
  // useState,
  useCallback,
  // useMemo,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import Feather from '@components/icons/feather'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

const StepRole = (props) => {
  const {
    // title,
    roles,
    roleIndex,
    onRolePressed,
  } = props

  const isRoleLoggedIn = useCallback((roleId) => {
    let found = _.find(roles, r => r.id === roleId)
    return (found && found.user && !_.isEmpty(found.user))
  }, [roles])

  // const isRolesLoggedIn = useCallback(() => {
  //   for (let r in roles) {
  //     let role = roles[r]
  //     if (!role || !role.user || _.isEmpty(role.user)) return false
  //   }
  //   return true
  // }, [roles])

  return (
    <View style={styles.middle}>
      {
        roles && roles.length > 0 ?
          roles.map((item, i) => {
            return (
              <TouchableOpacity
                style={[
                  styles.item,
                  styles.itemMiddle,
                  (i === roleIndex ? styles.itemSelected : {}),
                ]}
                key={`${i}`}
                onPress={() => onRolePressed(i)}
                disabled={isRoleLoggedIn(item.id)}>
                <Text
                  style={[
                    styles.value,
                    isRoleLoggedIn(item.id) ? styles.valueDisabled : {},
                  ]}>
                  {item && item.name}
                </Text>
                {
                  isRoleLoggedIn(item.id) ?
                  <Feather
                    name={'check'}
                    size={12 * SC.SCALE}
                    style={[
                      styles.icon,
                      isRoleLoggedIn(item.id) ? styles.iconDisabled : {},
                    ]}
                  /> : null
                }
              </TouchableOpacity>
            )
          }) : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  middle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    // backgroundColor: 'white',
    height: '100%',
  },
  item: {
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG,
    alignItems: 'flex-start',
    padding: 6 * SC.SCALE,
    // flex: 1,
    flexDirection: 'row',
    margin: SC.GAP_S * SC.SCALE,
    // marginTop: 0,
    marginBottom: 0,
  },
  itemTop: {
    padding: 6 * SC.SCALE,
    height: 40 * SC.SCALE,
    flex: 1,
    marginTop: SC.GAP * 2 * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemMiddle: {
    // flex: 1,
    width: '100%',
    height: 30 * SC.SCALE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemSelected: {
    backgroundColor: Colors.BUTTON_ACTIVE,
  },
  value: {
    flex: 1,
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  valueDisabled: {
    color: '#dedede',
  },
  icon: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
    marginLeft: SC.GAP * SC.SCALE,
  },
  iconDisabled: {
    color: '#dedede',
  },
})

StepRole.propTypes = {
  // title: PropTypes.string,
  roles: PropTypes.array,
  roleIndex: PropTypes.number,
  onRolePressed: PropTypes.func,
}

export default StepRole
