import api from '@apis'

// unauthenticated

const login = (cancelToken, credential) => {
  var data = {
    credential,
  }
  return api({
    url: `/api/player/login`,
    method: 'post',
    data,
    cancelToken,
  })
}

const createGuest = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/player/guest/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const suspend = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/suspend`,
    method: 'post',
    data,
    cancelToken,
  })
}

const resume = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/resume`,
    method: 'post',
    data,
    cancelToken,
  })
}

const checkout = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/table/player/position/checkout`,
    method: 'post',
    data,
    cancelToken,
  })
}

const detail = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/details`,
    method: 'post',
    data,
    cancelToken,
  })
}
const cardPlayers = (cancelToken, pitUuid) => {
  var data = { 'pit_uuid': pitUuid }
  return api({
    url: `/api/player/card/all`,
    method: 'post',
    data,
    cancelToken,
  })
}

const guestPlayer = (cancelToken, pitUuid) => {
  var data = { 'pit_uuid': pitUuid }
  return api({
    url: `/api/player/guest/all`,
    method: 'post',
    data,
    cancelToken,
  })
}

const adjustAvgBet = (cancelToken, accessToken, pitUuid, playerUuid, amount) => {
  var data = { 'pit_uuid': pitUuid, 'player_uuid': playerUuid, 'amount': amount }
  return api({
    url: `/api/player/adjust/average-bet`,
    method: 'post',
    data,
    cancelToken,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

const adjustWinLose = (cancelToken, accessToken, pitUuid, playerUuid, amount) => {
  var data = { 'pit_uuid': pitUuid, 'player_uuid': playerUuid, 'amount': amount }
  return api({
    url: `/api/player/adjust/handle_win_lose`,
    method: 'post',
    data,
    cancelToken,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

const deleteSession = (cancelToken, accessToken, pitUuid, playerUuid) => {
  var data = { 'pit_uuid': pitUuid, 'player_uuid': playerUuid, }
  return api({
    url: `/api/player/session/delete`,
    method: 'post',
    data,
    cancelToken,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

const search = (cancelToken, pitUuid, searchKey) => {
  var data = { 'pit_uuid': pitUuid, 'keyword': searchKey, }
  return api({
    url: `/api/player/search`,
    method: 'post',
    data,
    cancelToken,
    // headers: { Authorization: `Bearer ${accessToken}` },
  })
}


// authenticated

export default {
  login,
  createGuest,
  suspend,
  resume,
  detail,
  checkout,
  cardPlayers,
  guestPlayer,
  adjustAvgBet,
  adjustWinLose,
  deleteSession,
  search,
}
