import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import Button from '@components/button'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'
import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const TableCloseList = (props) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    Log.log('dealermanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('dealermanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [data, setData] = useState([
    { id: 1, jackpot: 'BA01', table_no: 'RL02', current_float: '5337086', opening_float: '103002', opening_time: '4:02:25', table_status: 'Open' },
    { id: 2, jackpot: 'RL02', table_no: 'RL03', current_float: '9379053', opening_float: '512', opening_time: '3:37:58', table_status: 'Closed' },
  ])

  const styleCol = {
    flexBasis: scaleSize(120),
  }

  const [attrs] = useState([
    { key: 'table_no', label: 'Table No.', style: styleCol },
    { key: 'opening_time', label: 'Opening Time', style: styleCol },
    { key: 'opening_float', label: 'Opening Float', style: styleCol, vtype: 'price' },
    { key: 'current_float', label: 'Current Float', style: styleCol, vtype: 'price' },
    { key: 'table_status', label: 'Table Status', style: styleCol },
  ])

  const renderHeader = () => {
    return (
      <FlatListItem
        model={{}}
        attrs={attrs}
        key={`-1`}
        isHeader={true}
        {...props}
      />
    )
  }

  const onSetSelected = (item) => {
    Log.log('onItemselect', item)
  }

  const onClose = () => {
    Toast.success('Close Successful')
  }

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={attrs}
        key={`${item.key}`}
        onPress={() => onSetSelected(item)}
        {...props}
      />

    )
  }

  return (
    <View style={[styles.container, cs.roundedEnd, cs.roundedBottom]}>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item?.id}
        ListHeaderComponent={renderHeader}
        style={[styles.list]}
      />
      <View style={{ alignItems: 'center', paddingVertical: scaleSize(5) }}>
        <Button
          title="Close Table"
          disabled={false}
          onPress={onClose}
          style={[styles.button]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
  },
  button: {
    backgroundColor: Colors.BUTTON_WIZ,
    width: scaleSize(80),
  },
})

TableCloseList.propTypes = {
  navigation: PropTypes.any,
}

export default TableCloseList
