import { /* useState /* , useCallback */ } from 'react'
import _ from 'lodash'
import ChipsCalculate from '@components/chips/calculate'

// import Toast from 'react-native-root-toast'

import ApiPlayer from '@apis/player'
import ApiDrop from '@apis/drop'

const useSubmit = (cancelToken) => {
  // const [response, setResponse] = useState()

  const onSubmitDropIn = async (tableUuid, position, chips) => {
    let response
    response = await ApiPlayer.login(cancelToken, 'cGxheWVyXzE6MTIzMTIz')
    if (!response || !response.data || !response.data.uuid) throw new Error('invalid player data')

    let playerUuid = response.data.uuid
    let amount = ChipsCalculate.getTotal(chips)
    let allChips = ChipsCalculate.filter(chips)
    allChips = _.map(allChips, (chip) => {
      return {
        chip_uuid: chip.uuid,
        quantity: parseInt(chip.count, 10),
      }
    })
    // cancelToken, tableUuid, playerUuid, position, amount, chips = []
    console.log(`fastdrop: ${position}: add drop in`, {
      tableUuid,
      playerUuid,
      position: 'sit',
      amount,
      allChips,
    })
    response = await ApiDrop.addDropIn(cancelToken, tableUuid, playerUuid, position, amount, allChips)
    // setResponse(response)
    return response
  }

  const onSubmitFastDrop = async (tableUuid, position, amount) => {
    let response
    // response = await ApiPlayer.login(cancelToken, 'cGxheWVyXzE6MTIzMTIz')
    // if (!response || !response.data || !response.data.uuid) throw new Error('invalid player data')

    // let playerUuid = response.data.uuid
    let playerUuid
    // cancelToken, tableUuid, playerUuid, position, amount, chips = []
    console.log(`fastdrop: ${position}: add fast drop`, {
      tableUuid,
      playerUuid,
      amount,
    })
    response = await ApiDrop.addFastDrop(cancelToken, tableUuid, playerUuid, position, amount)
    // setResponse(response)
    return response
  }

  const onUpdateFastDrop = async (dropInUuid, position, amount) => {
    let response
    // response = await ApiPlayer.login(cancelToken, 'cGxheWVyXzE6MTIzMTIz')
    // if (!response || !response.data || !response.data.uuid) throw new Error('invalid player data')

    // cancelToken, dropInUuid, amount, chips = []
    console.log(`fastdrop: ${position}: add fast drop`, {
      dropInUuid,
      amount,
    })
    response = await ApiDrop.updateLast(cancelToken, dropInUuid, amount, [])
    // setResponse(response)
    return response
  }

  return {onSubmitDropIn, onSubmitFastDrop, onUpdateFastDrop}
}

export default useSubmit
