import React, {
  // useEffect,
  // useState,
  // useCallback,
  // useMemo,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import Input from '@components/input'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

const StepIdentity = (props) => {
  const {
    title,
    username,
    password,
    setUsername,
    setPassword,
  } = props

  // const [username, setUsername] = useState('admin')
  // const [password, setPassword] = useState('11111111')

  return (
    <View style={styles.middleFilled}>
      <Text style={styles.title}>
        {title}
      </Text>
      <View style={[styles.inputRow, styles.inputRowFirst]}>
        <Text style={styles.inputLabel}>Username:</Text>
        <Input
          value={username}
          data={{}}
          style={styles.input}
          onChangeValue={(data, value) => setUsername(value)}
          keyboardType={'text'}
        />
      </View>
      <View style={styles.inputRow}>
        <Text style={styles.inputLabel}>Password:</Text>
        <Input
          value={password}
          data={{}}
          style={styles.input}
          onChangeValue={(data, value) => setPassword(value)}
          type={'password'}
          keyboardType={'text'}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  middleFilled: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.BUTTON_ACTIVE,
    height: '90%',
    borderRadius: 9,
  },
  title: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: SC.GAP * SC.SCALE,
    paddingBottom: 0,
    width: '50%',
  },
  inputRowFirst: {
    paddingTop: SC.GAP * SC.SCALE * 3,
  },
  inputLabel: {
    flex: 1,
    color: 'white',
    paddingRight: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP_S * SC.SCALE,
    fontSize: 8 * SC.SCALE,
  },
  input: {
    flex: 3,
    backgroundColor: 'white',
    color: 'black',
    paddingVertical: 4 * SC.SCALE,
    borderRadius: 2 * SC.SCALE,
    fontSize: 8 * SC.SCALE,
  },
})

StepIdentity.propTypes = {
  title: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  setUsername: PropTypes.func,
  setPassword: PropTypes.func,
}

export default StepIdentity
