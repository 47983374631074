import React, {
  useEffect,
  // useState,
  useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles, { CommonStyle as cs } from '@styles'
const { SC, Colors } = Styles

import { useSelector } from 'react-redux'

const StepIdentity = (props) => {
  const {
    step,
    nextStep,
    closeStep,
    users,
    setUsers,
  } = props

  const auth = useSelector(state => state.auth)
  const identities = auth?.identities

  // const [users, setUsers] = useState(identities)

  useEffect(() => {
    console.log(`dialogclosing: step: ${step}`)
    console.log(`dialogclosing: users`, users)
    // init()
    // return deinit
    refresh()
  }, [step, identities])

  const refresh = useCallback(() => {
    let all = _.map(identities, (identity) => {
      let found = _.find(users, (user) => user.id === identity.id)
      let role = identity.name
      return {
        ...identity,
        ...identity.user,
        role,
        isChecked: found.hasOwnProperty('isChecked') ? found.isChecked : false,
      }
    })
    setUsers(all)
  }, [identities, users])

  const onIdentityNext = () => {
    closeStep()
  }

  const onSelectIdentity = (item) => {
    let found = _.find(users, (user) => user.id === item.id)
    found.isChecked = true
    setUsers([].concat(users))
    nextStep()
  }

  const identityNextDisabled = useMemo(() => {
    if (!users || users.length <= 0) return true
    for (let u in users) {
      let user = users[u]
      if (!user.isChecked) return true
    }
    return false
  }, [users])

  const styleCol = {
    flex: 1,
    // flexBasis: scaleSize(120),
    // flexGrow: 1,
  }
  const attrs = [
    { key: 'role', label: 'Role', style: styleCol },
    // { key: 'id', label: 'ID', style: {} },
    { key: 'name', label: 'Name', style: styleCol },
    { key: 'isChecked', label: 'Checked', style: { flexBasis: 15 * SC.SCALE }, type: 'status' },
  ]
  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={attrs}
        key={`${item?.id}${item?.user_id}`}
        onPress={() => onSelectIdentity(item)}
        disabled={item.disabled}
        {...props}
      />
    )
  }
  return (
    <View style={[styles.container]}>
      <View style={styles.top}>
        <Text style={styles.title}>
          {step.label}
        </Text>
      </View>
      <View style={styles.listWrapper}>
        <FlatList
          data={users}
          renderItem={renderItem}
          keyExtractor={item => `${item?.id}${item?.user_id}`}
          style={[styles.list, cs.rounded]}
        />
      </View>
      <View>
        <Button
          title={'Next'}
          onPress={onIdentityNext}
          disabled={identityNextDisabled}
          style={styles.buttonNext}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  top: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  listWrapper: {
    flex: 1,
    width: '80%',
    padding: SC.GAP_S * SC.SCALE,
    marginTop: SC.GAP * SC.SCALE,
  },
  list: {
    flexGrow: 1,
    // backgroundColor: Colors.DASH_MAIN,
  },
  buttons: {
    flexDirection: 'row',
  },
  buttonNext: {
    marginTop: SC.GAP * SC.SCALE * 2,
    backgroundColor: Colors.BUTTON_WIZ,
    paddingHorizontal: 22 * SC.SCALE,
    marginHorizontal: 3 * SC.SCALE,
  },
})

StepIdentity.propTypes = {
  step: PropTypes.object,
  nextStep: PropTypes.func,
  closeStep: PropTypes.func,
  users: PropTypes.array,
  setUsers: PropTypes.func,
}

export default StepIdentity
