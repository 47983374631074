import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  // Text,
  View,
} from 'react-native'
import Button from '@components/button'
import Toast from '@components/toast'
// import DetailView from '@components/detailview'

import Wrapper from '@screens/pit/tablemanagement/layout/wrapper'
// import TableList from '@screens/base/tablelist'

import TableOpening from '../layout/tableopening'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { Colors } = Styles

import Constants from '@constants'
import Log from '@utils/log'

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import { useSelector, useDispatch } from 'react-redux'
import { syncTables } from '@actions/table'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'

const PageOpening = (props) => {
  const {
    // navigation,
    onChangePage,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()
  const table = useSelector(state => state.table)
  const tables = table?.tables || []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [list, setList] = useState([])

  const attrStyle = { flex: 1 }
  const attrMinMaxStyle = { flex: 2, alignItems: 'center', textAlign: 'center' }
  const [attrs] = useState([
    { key: 'table_no', label: 'Table No.', style: attrStyle },
    { key: 'game_category', label: 'Game Category', style: attrStyle },
    { key: 'opening_amount', label: 'Opening Chip Amount', style: attrMinMaxStyle, type: 'min-max', min: 0, max: 100000, step: 100 },
  ])

  useEffect(() => {
    Log.log('pageopening: init', { isFocused })
    if (isFocused) {
      init(tables)
    }
    return deinit
  }, [isFocused, init, deinit, tables])

  const init = useCallback(async (initTables = []) => { // todo: implement
    Log.log('pageopening: init')
    try {
      setLoading(true)
      // API calls here
      await getTables(initTables)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [getTables])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const getTables = useCallback(async (initTables = []) => {
    let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    if (!response || !response.data || !response.data.uuid) {
      throw new Error('invalid response or data or uuid')
    }

    let pitUuid = response.data.uuid
    response = await ApiTable.getAll(source.token, pitUuid)
    Log.log('pageopening: getTables', response)
    let data = response?.data
    if (data) {
      let tables = _.map(data, (item) => {
        let found = _.find(initTables, (tb) => tb.uuid === item.uuid)
        return {
          ...item,
          ...found,
          opening_amount: (found != null && found?.uuid) ? found.opening_amount : 0,
          isSelected: (found != null && found?.uuid) ? true : false,
        }
      })
      setList(tables)
    }
  }, [])

  // methods

  const onCheck = useCallback((value, item) => {
    let it = _.find(list, (i) => i.table_no === item.table_no)
    if (!it) {
      Log.error(`invalid selection: ${item.table_no}`)
      return
    }
    it.isSelected = !it.isSelected
    setList([].concat(list))
  }, [list])

  const onPress = useCallback((info) => {
    Log.log('pageopening: on press', info)
    let uuid = info?.uuid
    let found = _.find(list, (tb) => tb.uuid === uuid)
    if (found) {
      found.isSelected = !found.isSelected
      setList([].concat(list))
    }
  }, [list])

  const onMinMaxPress = useCallback((type, model, attr) => {
    // Log.log('pageopening: on min-max press', { type, data, attr })
    let uuid = model?.uuid
    let found = _.find(list, (it) => it.uuid === uuid)
    if (!found) return
    let amount = found.opening_amount
    let step = attr?.step || 1
    let min = attr?.min || 0
    let max = attr?.max || 100000
    if (type === 'min') {
      amount -= step
      if (amount < min) {
        amount = min
      }
      found.opening_amount = amount
    } else if (type === 'max') {
      amount += step
      if (amount > max) {
        amount = max
      }
      found.opening_amount = amount
    }
    setList([].concat(list))
  }, [list])

  const onNext = useCallback(async () => {
    let items = _.filter(list, (item) => item.isSelected === true)

    // validate
    for (let i in items) {
      let item = items[i]
      let amount = parseInt(item.opening_amount, 10)
      if (isNaN(amount) || amount <= 0) {
        Toast.show('Invalid opening amount', { type: 'warning' })
        return
      }
    }
    Log.log('pageopening: sync tables', items)
    await dispatch(syncTables(items))
    onChangePage('minmax')
  }, [list])

  const nextDisabled = useMemo(() => {
    let items = _.filter(list, (item) => item.isSelected === true)
    return (items.length <= 0)
  }, [list])

  const renderSide = null
  const renderMain = (/* props */) => {
    return (
      <View style={styles.container}>
        <TableOpening
          data={list}
          attrs={attrs}
          onCheck={onCheck}
          onPress={onPress}
          onMinMaxPress={onMinMaxPress}
          style={styles.inner}
          {...props}
        />
        <View style={styles.buttonWrapper}>
          <Button
            title={'Next'}
            style={styles.buttonSubmit}
            onPress={onNext}
            disabled={nextDisabled}
          />
        </View>
      </View>
    )
  }

  return (
    <Wrapper
      renderSide={renderSide}
      renderMain={renderMain}
      styleMain={{backgroundColor:'transparent'}}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  inner: {
    flex: 1,
  },
  buttonWrapper: {
    // flex: 0,
    // flexWrap: 'wrap', // no use
    // width: '100%',
    alignItems: 'center',
  },
  buttonSubmit: {
    backgroundColor: Colors.BUTTON_WIZ,
    width: '50%',
    // flexWrap: 'wrap', // no use
    // flexGrow: 10,
  },
})

PageOpening.propTypes = {
  // navigation: PropTypes.any,
  onChangePage: PropTypes.func,
}

export default PageOpening
