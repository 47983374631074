import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  // Text,
  View,
} from 'react-native'
import Button from '@components/button'
import Toast from '@components/toast'
// import DetailView from '@components/detailview'

import Wrapper from '@screens/pit/tablemanagement/layout/wrapper'
// import TableList from '@screens/base/tablelist'

import TableClosing from './layout/tableclosing'
import DialogClosing from '@screens/tms/closing/layout/dialogclosing'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC, Colors } = Styles

import Constants from '@constants'
import Log from '@utils/log'

import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import { useSelector/*, useDispatch */ } from 'react-redux'
// import { syncTables } from '@actions/table'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'

const PageClosing = (props) => {
  // const {
  //   // navigation,
  // } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()
  const table = useSelector(state => state.table)
  const tables = table?.tables || []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [list, setList] = useState([])

  const attrStyle = { flex: 1 }
  // const attrMinMaxStyle = { flex: 2, alignItems: 'center', textAlign: 'center' }
  const [attrs] = useState([
    { key: 'table_no', label: 'Table No.', style: attrStyle },
    { key: 'opening_time', label: 'Opening Time', style: attrStyle },
    { key: 'opening_amount', label: 'Opening Float', style: attrStyle, vtype: 'price' },
    { key: 'opening_amount', label: 'Current Float', style: attrStyle, vtype: 'price' },
    { key: 'status', label: 'Table Status', style: attrStyle },
  ])

  const [dialogClosingVisible, setDialogClosingVisible] = useState(false)

  useEffect(() => {
    Log.log('pageclosing: init', { isFocused })
    if (isFocused) {
      init(tables)
    }
    return deinit
  }, [isFocused, init, deinit, tables])

  const init = useCallback(async (initTables = []) => { // todo: implement
    Log.log('pageclosing: init')
    try {
      setLoading(true)
      // API calls here
      await getTables(initTables)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [getTables])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const getTables = useCallback(async (initTables = []) => {
    let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    if (!response || !response.data || !response.data.uuid) {
      throw new Error('invalid response or data or uuid')
    }

    let pitUuid = response.data.uuid
    response = await ApiTable.getAll(source.token, pitUuid)
    Log.log('pageclosing: getTables', response)
    let data = response?.data
    if (data) {
      let tables = _.map(data, (item) => {
        let found = _.find(initTables, (tb) => tb.uuid === item.uuid)
        let isOpened = (found != null && found?.uuid) ? true : false
        return {
          ...item,
          ...found,
          opening_amount: (found != null && found?.uuid) ? found.opening_amount : 0,
          isSelected: false,
          isOpened: isOpened,
          status: isOpened ? 'Open' : 'Closed',
        }
      })
      setList(tables)
    }
  }, [])

  // methods

  const onCheck = useCallback((value, info) => {
    // let it = _.find(list, (i) => i.table_no === info.table_no)
    // if (!it) {
    //   Log.error(`invalid selection: ${info.table_no}`)
    //   return
    // }
    // it.isSelected = !it.isSelected
    // setList([].concat(list))
    setTableUuid(info?.uuid)
    for (let l in list) {
      let item = list[l]
      item.isSelected = (item.uuid === info?.uuid)
    }
    setList([].concat(list))
  }, [list])

  const onPress = useCallback((info) => {
    Log.log('pageclosing: on press', info)
    let uuid = info?.uuid
    setTableUuid(uuid)
    for (let l in list) {
      let item = list[l]
      item.isSelected = (item.uuid === uuid)
    }
    setList([].concat(list))
    // let found = _.find(list, (tb) => tb.uuid === uuid)
    // if (found) {
    //   found.isSelected = !found.isSelected
    //   setList([].concat(list))
    // }
  }, [list])

  const onMinMaxPress = useCallback((type, model, attr) => {
    // Log.log('pageclosing: on min-max press', { type, data, attr })
    let uuid = model?.uuid
    let found = _.find(list, (it) => it.uuid === uuid)
    if (!found) return
    let amount = found.opening_amount
    let step = attr?.step || 1
    let min = attr?.min || 0
    let max = attr?.max || 100000
    if (type === 'min') {
      amount -= step
      if (amount < min) {
        amount = min
      }
      found.opening_amount = amount
    } else if (type === 'max') {
      amount += step
      if (amount > max) {
        amount = max
      }
      found.opening_amount = amount
    }
    setList([].concat(list))
  }, [list])

  const onClose = useCallback(async () => {
    // let items = _.filter(list, (item) => item.isSelected === true)
    // // validate
    // for (let i in items) {
    //   let item = items[i]
    //   let amount = parseInt(item.opening_amount, 10)
    //   if (isNaN(amount) || amount <= 0) {
    //     Toast.show('Invalid opening amount', { type: 'warning' })
    //     return
    //   }
    // }
    // Log.log('pageclosing: sync tables', items)
    // await dispatch(syncTables(items))
    // onChangePage('minmax')
  }, [list])

  const closeDisabled = useMemo(() => {
    let items = _.filter(list, (item) => item.isSelected === true && item.isOpened)
    return (items.length <= 0)
  }, [list])

  const [tableUuid, setTableUuid] = useState()

  const renderSide = null
  const renderMain = (/* props */) => {
    return (
      <View style={styles.container}>
        <TableClosing
          data={list}
          attrs={attrs}
          onCheck={onCheck}
          onPress={onPress}
          onMinMaxPress={onMinMaxPress}
          style={styles.inner}
          {...props}
        />
        <View style={styles.buttonWrapper}>
          <Button
            title={'Close Table'}
            style={styles.buttonSubmit}
            onPress={onClose}
            disabled={closeDisabled}
          />
        </View>
        <DialogClosing
          visible={dialogClosingVisible}
          setVisible={setDialogClosingVisible}
          setLoading={setLoading}
          tableUuid={tableUuid}
        />
      </View>
    )
  }

  return (
    <Wrapper
      renderSide={renderSide}
      renderMain={renderMain}
      styleMain={{backgroundColor:'transparent'}}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 9,
  },
  inner: {
    flex: 1,
  },
  buttonWrapper: {
    // flex: 0,
    // flexWrap: 'wrap', // no use
    // width: '100%',
    alignItems: 'center',
    padding: SC.GAP_S * SC.SCALE,
  },
  buttonSubmit: {
    backgroundColor: Colors.BUTTON_WIZ,
    width: '50%',
    // flexWrap: 'wrap', // no use
    // flexGrow: 10,
  },
})

PageClosing.propTypes = {
  // navigation: PropTypes.any,
}

export default PageClosing
