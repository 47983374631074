import React, {
  // useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  CheckBox,
  StyleSheet,
} from 'react-native'

const CheckBoxComponent = (props) => {
  const {
    isSelected,
    setSelected,
  } = props

  return (
    <CheckBox
      value={isSelected}
      onValueChange={setSelected}
      style={styles.checkbox}
    />
  )
}

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
  },
})

CheckBoxComponent.propTypes = {
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func,
}

export default CheckBoxComponent