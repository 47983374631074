import api from '@apis'
import base64 from 'base-64'

// unauthenticated

const getRoles = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/user-role/all`,
    method: 'post',
    data,
    cancelToken,
  })
}

const login = (cancelToken, username, password) => {
  let credential = `<data><function>3api_TMS_user_authentication</function><user_password>${password}</user_password><user_username>${username}</user_username></data>`
  credential = base64.encode(credential)
  var data = {
    credential,
  }
  return api({
    url: `/api/user/login`,
    method: 'post',
    data,
    cancelToken,
  })

  // // response:
  // return {
  //   data: {
  //     "user_id": 38,
  //     "access_token": "ZXlKMWMyVnlibUZ0WlNJNkltMWxjbU5vWVc1MElpd2laMlZ1WlhKaGRHVmZkR2x0WlNJNk1UWTNNakV5T0RrNU0zMD0=",
  //   },
  // }
}

// authenticated

export default {
  getRoles,
  login,
}
