import api from '@apis'

// unauthenticated

const addDropIn = (cancelToken, tableUuid, playerUuid, position, amount, chips = []) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  if (amount == null) throw new Error('invalid amount')
  var data = {
    'table_uuid': tableUuid,
    'player_uuid': playerUuid,
    position,
    amount,
    chips,
  }
  return api({
    url: `/api/drop-in/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const addFastDrop = (cancelToken, tableUuid, playerUuid, position, amount) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  if (amount == null) throw new Error('invalid amount')
  var data = {
    'table_uuid': tableUuid,
    'player_uuid': playerUuid,
    position,
    amount,
  }
  return api({
    url: `/api/fastdrop/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getLast = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/drop-in/last-drop`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getCashList = (cancelToken) => {
  var data = {
  }
  return api({
    url: `/api/fastdrop/cash/list`,
    method: 'post',
    data,
    cancelToken,
  })
}

const updateLast = (cancelToken, dropInUuid, amount, chips = []) => {
  var data = {
    'drop_in_uuid': dropInUuid,
    amount,
    chips, // chip_uuid, quantity
  }
  return api({
    url: `/api/drop-in/last-drop/update`,
    method: 'post',
    data,
    cancelToken,
  })
}

// authenticated

export default {
  addDropIn,
  addFastDrop,
  getLast,
  getCashList,
  updateLast,
}
