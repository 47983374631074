import React, {
  useEffect,
  // useState,
  // useCallback,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
} from 'react-native'
// import Feather from '@components/icons/feather'

import { useIsFocused } from '@react-navigation/native'

// import Log from '@utils/log'
// import ChipsCalculate from '@components/chips/calculate'

// import Styles, {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'

import Button from '@components/button'
import ChipsControl from '@components/chipscontrol'
import Toast from 'react-native-root-toast'
// import ChipCalculate from '@components/chips/calculate'
// import DetailView from '@components/detailview'

import Styles, {
  // CommonStyle as cs,
  // scaleSize,
} from '@styles'
const { SC, Colors } = Styles

// react-redux
// import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'
import useLoading from '@hooks/useLoading'

// import ApiChip from '@apis/chip'
// import ApiPlayer from '@apis/player'
// import ApiDrop from '@apis/drop'

const Chips = (props) => {
  const {
    compact, // true === PIT
    difference,
    chipSets,
    onTotalAmountChanage,
    onReset,
    onUpdate,
  } = props
  const isFocused = useIsFocused()
  const [loading] = useLoading()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  // const auth = useSelector(state => state.auth)
  // const token = auth && auth.token

  // const [chips, setChips] = useState([{
  //   uuid: '123',
  //   colour: 'Blue',
  //   value: 10,
  //   count: 0,
  // }, {
  //   uuid: '124',
  //   colour: 'Black',
  //   value: 20,
  //   count: 0,
  // }])

  useEffect(() => {
    if (isFocused) {
      init()
    }
    // setChips(ChipCalculate.mapChips(chips, [], 1))
    return deinit
  }, [])

  const init = async () => {
    try {
      // setLoading(true)
      // API calls here
      // await getChipsetsAndChips(tableUuid)
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  // const getChipsetsAndChips = async (tableUuid) => {
  //   if (!tableUuid || tableUuid.length <= 0) {
  //     throw new Error('invalid table UUID')
  //   }
  //   let response
  //   // response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
  //   // if (!response || !response.data || !response.data.uuid) {
  //   //   throw new Error('invalid response or data or uuid')
  //   // }

  //   // let pitUuid = response.data.uuid
  //   // response = await ApiTable.getAll(source.token, pitUuid)
  //   // console.log('-- all tables', response)
  //   response = await ApiChip.getChipsets(source.token, tableUuid)
  //   Log.log(`wizard-opening: all chipsets: ${tableUuid}`, response)
  //   let chipsets = (response && response.data) || []

  //   let promises = []
  //   let promise
  //   for (let c in chipsets) {
  //     let chipset = chipsets[c]
  //     let chipUuid = chipset.uuid
  //     promise = ApiChip.getChips(source.token, chipUuid)
  //     promises.push(promise)
  //   }
  //   let values = await Promise.all(promises)
  //   Log.log('wizard-opening: all chips', values)

  //   chipsets = _.map(chipsets, (chipset, csIndex) => {
  //     let chipsetUuid = chipset.uuid
  //     let chips = values[csIndex] && values[csIndex].data
  //     // map chips into proper chips format
  //     // input: uuid, colour, value, count
  //     // output: color, colorBg, colorText, title, value, count
  //     chips = ChipCalculate.mapChips(chips, [], chipsetUuid)
  //     return {
  //       ...chipset,
  //       chips,
  //     }
  //   })
  //   let chipset = _.find(chipsets, (cset) => cset.name && cset.name.toLowerCase() === 'cash chip')
  //   chipset = (chipset && chipset.chips) || []
  //   setChips(chipset)
  // }

  const deinit = () => {
    source.cancel('api_cancel')
  }

  // const onEnterDisabled = useCallback(() => {
  //   let total = 0
  //   for (let c in chips) {
  //     let chip = chips[c]
  //     if (chip && chip.count > 0) {
  //       total += chip.count
  //     }
  //   }
  //   return total <= 0
  // }, [chips])

  const onEnter = async () => {
    try {
      // setLoading(true)
      // let response = await onSubmit(tableUuid, 'sit', chips)
      // console.log('fastdrop: sit: add drop response', response)
      // setLoading(false)
      Toast.show(`Success`, {
        type: 'success',
      })
    } catch (error) {
      // setLoading(false)
      Toast.show(`Failed: ${error && error.message}`, {
        type: 'danger',
      })
    }
  }

  return (
    <ChipsControl
      loading={loading}
      chipSets={chipSets}
      noCashToggle={true}
      noButtons={true}
      onEnter={onEnter}
      onTotalAmountChanage={onTotalAmountChanage}
      summary={(/* data */) => {
        return <View></View>
      }}
      appendButton={(
        compact ?
        <View style={{flexDirection:'row',paddingTop:SC.GAP*SC.SCALE}}>
          <Button
            title="Reset"
            disabled={difference === 0}
            style={styles.buttonReset}
            onPress={onReset}>
          </Button>
          <Button
            title="Update"
            disabled={difference === 0}
            style={styles.buttonUpdate}
            onPress={onUpdate}>
          </Button>
        </View> : null
      )}
      styleContainer={styles.container}
      styleLeftPanel={styles.leftPanel}
      styleSliderPanel={styles.sliderPanel}
    />
  )
}

const styles = StyleSheet.create({
  // summary: {
  //   marginTop: SC.GAP * SC.SCALE,
  //   backgroundColor: Colors.BUTTON_BG,
  //   borderRadius: 9,
  // },
  buttonReset: {
    backgroundColor: Colors.BUTTON_WIZ,
    paddingVertical: SC.GAP_S * SC.SCALE,
  },
  buttonUpdate: {
    backgroundColor: Colors.SUCCESS,
    marginLeft: SC.GAP_S * SC.SCALE,
    paddingVertical: SC.GAP_S * SC.SCALE,
  },
  container: {
    height: '100%',
    paddingTop: SC.GAP * SC.SCALE,
  },
  leftPanel: {
    height: '100%',
    paddingVertical: 0,
  },
  sliderPanel: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
})

Chips.propTypes = {
  compact: PropTypes.bool,
  difference: PropTypes.number,
  chipSets: PropTypes.any.isRequired,
  onTotalAmountChanage: PropTypes.func,
  onReset: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default Chips
