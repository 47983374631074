import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
} from 'react-native'

import Button from '@components/button'
import DetailView from '@components/detailview'
import Feather from '@components/icons/feather'

import Wrapper from '@screens/pit/tablemanagement/layout/wrapper'
import TableList from '@screens/base/tablelist'

import {
  CommonStyle as cs,
  // scaleSize,
} from '@styles'
import Styles from '@styles'
const { SC, Colors } = Styles

// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import { useDispatch, useSelector } from 'react-redux'
import { syncTables } from '@actions/table'

import ApiTable from '@apis/table'

const PageMinMax = (props) => {
  const {
    // navigation,
    // route,
    onChangePage,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  // const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  const dispatch = useDispatch()
  const table = useSelector(state => state.table)
  const tables = table?.tables || []

  const [minMax, setMinMax] = useState([])

  const [attrs] = useState([
    { label: 'Float', key: 'float' },
    { label: 'Min Bet', key: 'min' },
    { label: 'Max Bet', key: 'max' },
    { label: 'Float Type', key: 'float_type' },
  ])

  const [list, setList] = useState([])
  const [listIndex, setListIndex] = useState(-1)

  useEffect(() => {
    // if (isFocused) {
    //   init()
    // }
    return deinit
  }, [deinit])

  const refreshMinMax = useCallback(async (tableUuid) => {
    try {
      setLoading(true)
      let response = await ApiTable.getMinMax(source.token, tableUuid)
      let data = response?.data
      // Log.log('pageminmax: getMinMax', data)
      if (data) {
        setMinMax(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  useEffect(() => {
    Log.log('pageminmax: init tables()', tables)
    let list = _.map(tables, (tb) => {
      return {
        ...tb,
        isSelected: false,
        isMinMax: tb.hasOwnProperty('isMinMax') ? tb.isMinMax : false,
        minMaxIndex: tb.hasOwnProperty('minMaxIndex') ? tb.minMaxIndex : 0,
      }
    })
    setList(list)
  }, [tables])

  // const [data] = useState({
  //   float: 100000,
  //   min: 10,
  //   max: 100,
  //   floatType: 'Flexible',
  // })

  const getItemAndMinMaxInfo = useCallback(() => {
    if (listIndex < 0 || listIndex >= list.length) {
      // Log.warn('pageminmax: invalid list index', listIndex)
      return null
    }
    let item = list[listIndex]
    if (item.minMaxIndex >= minMax.length) {
      Log.warn('pageminmax: invalid min max index', { listIndex, minMaxIndex: item.minMaxIndex })
      return null
    }
    let minMaxIndex = item.minMaxIndex
    return { listItem: item, listIndex, minMaxInfo: minMax[minMaxIndex], minMaxIndex }
  }, [list, listIndex, minMax])

  const data = useMemo(() => {
    const def = {
      float: '',
      min: '',
      max: '',
      floatType: '-',
    }
    let info = getItemAndMinMaxInfo()
    if (info == null || info.listItem == null) {
      return def
    }
    let item = info.listItem
    let minMaxInfo = info.minMaxInfo
    let float = item.opening_amount
    let float_type = item.float_type
    // Log.log('pageminmax: table info', item)
    let min = minMaxInfo.min_bet
    let max = minMaxInfo.max_bet
    return {
      float,
      float_type,
      min,
      max,
    }
  }, [list, listIndex, minMax, getItemAndMinMaxInfo])

  const minDisabled = useMemo(() => {
    let info = getItemAndMinMaxInfo()
    if (info == null || info.listItem == null) {
      return true
    }
    // let listItem = info.listItem
    let minMaxIndex = info.minMaxIndex
    return (minMaxIndex <= 0)
  }, [list, listIndex, minMax, getItemAndMinMaxInfo])

  const maxDisabled = useMemo(() => {
    let info = getItemAndMinMaxInfo()
    if (info == null || info.listItem == null) {
      return true
    }
    // let listItem = info.listItem
    let minMaxIndex = info.minMaxIndex
    return (minMaxIndex >= (minMax && minMax.length - 1))
  }, [list, listIndex, minMax, getItemAndMinMaxInfo])

  const onMinPressed = useCallback(() => {
    let info = getItemAndMinMaxInfo()
    if (info == null || info.listItem == null) {
      return
    }
    let minMaxIndex = info.minMaxIndex
    if (minMaxIndex == null) {
      Log.error('invalid min max index')
      return
    }
    let index = minMaxIndex - 1
    if (index < 0) {
      index = 0
    }
    let listItem = info.listItem
    listItem.minMaxIndex = index
    setList([].concat(list))
  }, [list, listIndex, minMax])

  const onMaxPressed = useCallback(() => {
    let info = getItemAndMinMaxInfo()
    if (info == null || info.listItem == null) {
      return
    }
    let minMaxIndex = info.minMaxIndex
    if (minMaxIndex == null) {
      Log.error('invalid min max index')
      return
    }
    let index = minMaxIndex + 1
    if (index >= minMax && (minMax.length - 1)) {
      index = minMax.length - 1
    }
    let listItem = info.listItem
    listItem.minMaxIndex = index
    setList([].concat(list))
  }, [list, listIndex, minMax])

  const nextDisabled = useMemo(() => {
    if (list.length <= 0) return true
    let items = _.filter(list, (l) => l.isMinMax)
    return items.length < list.length
  }, [list])

  // methods

  const onTableAdd = () => {
    onChangePage('opening')
  }

  const onNext = useCallback(async () => {
    Log.log('pageminmax: sync table', list)
    await dispatch(syncTables(list))
    onChangePage('main')
  }, [list])

  const onTableSelect = useCallback(async (item) => {
    // console.log('pageminmax: onTableSelect', item)
    // sync table data
    let found = _.find(list, (l) => l.uuid === item?.uuid)
    let foundIndex = _.findIndex(list, (l) => l.uuid === item?.uuid)
    if (found && foundIndex >= 0) {
      await refreshMinMax(item.uuid)
      setListIndex(foundIndex)

      for (let l in list) {
        let item = list[l]
        item.isSelected = false
      }
      found.isMinMax = true
      found.isSelected = true
      setList([].concat(list))
    }
  }, [list])

  const renderSide = (/* props */) => {
    return (
      <TableList
        data={list}
        withAdd={true}
        onAdd={onTableAdd}
        onSelect={onTableSelect}
        {...props}
      />
    )
  }

  const renderMain = (/* props */) => {
    return (
      <View style={styles.detailContainer}>
        <View style={styles.side}>
          <Button
            disabled={minDisabled}
            style={styles.iconButton}
            onPress={onMinPressed}>
            <Feather
              style={[
                styles.icon,
                (minDisabled ? styles.iconDisabled : {}),
              ]}
              name={'arrow-left'}>
            </Feather>
          </Button>
        </View>
        <View style={styles.center}>
          <DetailView
            style={[styles.detail, cs.pa]}
            rowStyle={styles.detailRow}
            model={data}
            labelStyle={{ fontSize: 12 * SC.SCALE }}
            valueStyle={{ fontSize: 12 * SC.SCALE }}
            attributes={attrs}
            {...props}
          />
          <View styles={styles.nextWrapper}>
            <Button
              title={'Next'} disabled={nextDisabled} onPress={onNext}
              style={styles.next} />
          </View>
        </View>
        <View style={styles.side}>
          <Button
            disabled={maxDisabled}
            style={styles.iconButton}
            onPress={onMaxPressed}>
            <Feather
              style={[
                styles.icon,
                (maxDisabled ? styles.iconDisabled : {}),
              ]}
              name={'arrow-right'}>
            </Feather>
          </Button>
        </View>
      </View>
    )
  }

  return (
    <Wrapper
      sideHeader={'Min Max.'}
      renderSide={renderSide}
      renderMain={renderMain}
      styleMain={{backgroundColor:'transparent'}}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  icon: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
  },
  iconButton: {
    backgroundColor: 'transparent',
  },
  iconDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
    fontSize: 12 * SC.SCALE,
  },
  detailContainer: {
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
  },
  detail: {
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
  },
  side: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    flexDirection: 'column',
    flex: 1,
  },
  detailRow: {
    flex: 1,
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 9,
    margin: SC.GAP_S * SC.SCALE,
    marginBottom: 0,
    padding: SC.GAP * SC.SCALE,
  },
  nextWrapper: {
    flexWrap: 'wrap',
  },
  next: {
    flexWrap: 'wrap',
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

PageMinMax.propTypes = {
  // navigation: PropTypes.any,
  // route: PropTypes.object,
  onChangePage: PropTypes.func,
}

export default PageMinMax
