import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC } = Styles

const Chip = (props) => {
  const {
    onPress,
    title,
    size,
    image,
    color,
    colorBg,
    colorText,
    style,
    styleText,
    isCashMode,
  } = props

  const base64image = image && image.length > 0 ? `data:image/png;base64,${image}` : undefined
  // const base64image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAwBQTFRF7c5J78kt+/Xm78lQ6stH5LI36bQh6rcf7sQp671G89ZZ8c9V8c5U9+u27MhJ/Pjv9txf8uCx57c937Ay5L1n58Nb67si8tVZ5sA68tJX/Pfr7dF58tBG9d5e8+Gc6chN6LM+7spN1pos6rYs6L8+47hE7cNG6bQc9uFj7sMn4rc17cMx3atG8duj+O7B686H7cAl7cEm7sRM26cq/vz5/v767NFY7tJM78Yq8s8y3agt9dte6sVD/vz15bY59Nlb8txY9+y86LpA5LxL67pE7L5H05Ai2Z4m58Vz89RI7dKr+/XY8Ms68dx/6sZE7sRCzIEN0YwZ67wi6rk27L4k9NZB4rAz7L0j5rM66bMb682a5sJG6LEm3asy3q0w3q026sqC8cxJ6bYd685U5a457cIn7MBJ8tZW7c1I7c5K7cQ18Msu/v3678tQ3aMq7tNe6chu6rgg79VN8tNH8c0w57Q83akq7dBb9Nld9d5g6cdC8dyb675F/v327NB6////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/LvB3QAAAMFJREFUeNpiqIcAbz0ogwFKm7GgCjgyZMihCLCkc0nkIAnIMVRw2UhDBGp5fcurGOyLfbhVtJwLdJkY8oscZCsFPBk5spiNaoTC4hnqk801Qi2zLQyD2NlcWWP5GepN5TOtSxg1QwrV01itpECG2kaLy3AYiCWxcRozQWyp9pNMDWePDI4QgVpbx5eo7a+mHFOqAxUQVeRhdrLjdFFQggqo5tqVeSS456UEQgWE4/RBboxyC4AKCEI9Wu9lUl8PEGAAV7NY4hyx8voAAAAASUVORK5CYII='

  return (
    <TouchableOpacity
      style={[
        (isCashMode ? styles.cash : styles.chip),
        (!isCashMode ? { width: size * SC.SCALE, height: size * SC.SCALE } : {}),
        style,
        (!isCashMode ? { backgroundColor: colorBg } : {}),
        (isCashMode ? {
          flexBasis: size / 50 * 24 * SC.SCALE,
          marginVertical: size / 50 * 6 * SC.SCALE,
          marginHorizontal: size / 50 * 4 * SC.SCALE,
          paddingVertical: size / 50 * 4 * SC.SCALE,
          paddingHorizontal: size / 50 * 8 * SC.SCALE,
        } : {}),
      ]}
      onPress={onPress}>
      {
        isCashMode ?
        <View style={styles.cashWrapper}>
          <Feather name={'dollar-sign'} size={24} color={'white'} />
          <Text
            style={[
              styles.chipText,
              // { color: colorText },
              styleText,
            ]}
          >
            {title}
          </Text>
        </View> :
        <View style={[
          styles.chipTextWrapper,
          { width: size * SC.SCALE, height: size * SC.SCALE },
          { backgroundColor: color },
        ]}>
          <Image style={styles.image} source={base64image}></Image>
          <Text style={[
            styles.chipText,
            { color: colorText },
            styleText,
           ]}>
            {title}
          </Text>
        </View>
      }
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  chip: {
    width: 40 * SC.SCALE,
    height: 40 * SC.SCALE,
    borderRadius: '50%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  cash: {
    borderRadius: 4,
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: 'white',
    backgroundColor: 'transparent',
    // flexBasis: 24 * SC.SCALE,
    // marginVertical: 6 * SC.SCALE,
    // marginHorizontal: 4 * SC.SCALE,
    // paddingVertical: 2 * SC.SCALE,
    // paddingHorizontal: 12 * SC.SCALE,
  },
  cashWrapper: {
    flexDirection: 'row',
  },
  image: {
    position: 'absolute',
    // maxWidth: '100%',
    // maxHeight: '100%',
    width: '100%',
    height: '100%',
    // width: '100%',
    // height: '100%',
    resizeMode: 'contain',
    // maxWidth: 200,
    // maxHeight: '100%',
    // aspectRatio: 4,
    // aspectRatio: 1,
    // ...(Platform.OS === 'web' ? { aspectRatio: 1 } : {}),
    zIndex: -1,
  },
  chipTextWrapper: {
    width: 40 * SC.SCALE,
    height: 40 * SC.SCALE,
    borderRadius: '50%',
    display: 'absolute',
    top: -2 * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
  chipText: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    fontWeight: 'bold',
  },
})

Chip.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.number,
  image: PropTypes.string,
  color: PropTypes.string,
  colorBg: PropTypes.string,
  colorText: PropTypes.string,
  style: PropTypes.any,
  styleText: PropTypes.any,
  isCashMode: PropTypes.bool,
}

export default Chip
