import React, { } from 'react'
import Tabs from '@components/tabs'
import { StyleSheet, View, } from 'react-native'
import PropTypes from 'prop-types'

const Header = (props) => {
    const {
        tabs,
        selectedItem,
        onChangeTab,
        tabStyle,
    } = props

    return (
        <View style={styles.header}>
            <Tabs
                tabStyle={tabStyle}
                tabs={tabs}
                index={tabs.findIndex(item => (item.id === selectedItem.id))}
                onChangeTab={(i) => onChangeTab(tabs[i])}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
    },
})

Header.propTypes = {
    tabs: PropTypes.any,
    index: PropTypes.any,
    selectedItem: PropTypes.any,
    onChangeTab: PropTypes.any,
    tabStyle: PropTypes.any,
}

export default Header