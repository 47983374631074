import { useState, useCallback, useEffect } from 'react'
import { /* useSelector, */ useDispatch } from 'react-redux'
import { updateCount } from '@actions/loader'

const useLoading = () => {
  let [loading, setLoading] = useState({ count: 0 })

  // store
  const dispatch = useDispatch()
  // const user = useSelector(state => state.user)

  const onSetLoading = useCallback((isLoading) => {
    if (isLoading) {
      setLoading((state) => {
        let count = state.count + 1
        return { count }
      })
    } else {
      setLoading((state) => {
        let count = state.count - 1
        count = count < 0 ? 0 : count
        return { count }
      })
    }
  }, [loading])

  useEffect(() => {
    dispatch(updateCount(loading.count))
  }, [loading])

  const onResetLoading = useCallback(() => {
    setLoading(0)
  }, [loading])

  return [loading.count, onSetLoading, onResetLoading]
}

export default useLoading