const StringUtil = {
  random: (len = 12) => {
    let maxlen = 8
    let min = Math.pow(16,Math.min(len,maxlen) - 1)
    let max = Math.pow(16,Math.min(len,maxlen)) - 1
    let n = Math.floor( Math.random() * (max - min + 1) ) + min
    let r = n.toString(16)
    while ( r.length < len ) {
       r = r + StringUtil.random( len - maxlen )
    }
    return r
  },

  commafy: (value, isPrice = false) => {
    var str = Math.round(parseFloat(value) * 100) / 100
    if (isPrice) {
      str = str.toFixed(2)
    }
    str = str.toString().split('.')
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ')
    } else if (!str[1] && isPrice) {
      str.push('00')
    }
    return str.join('.')
  },

  formatPrice: (value, prefix = 'RM', isPrice = false) => {
    if (value == null || isNaN(parseFloat(value))) return value // passthrough
    value = parseFloat(value)
    let isNeg = (value < 0)
    if (isNeg) value *= -1
    let valueString = StringUtil.commafy(value, isPrice)
    return `${isNeg ? '-' : ''} ${prefix} ${valueString}`
  },
}

export default StringUtil
