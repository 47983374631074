import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import ButtonExpand from '@components/buttonexpand'
// import ButtonHeader from '@components/buttonheader'
// import CheckBox from '@components/checkbox'
// import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import ApiFillCredit from '@apis/fillcredit'
import Toast from '@components/toast'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import {
  CommonStyle as cs,
  scaleSize,
} from '@styles'
import Styles from '@styles'
import ReqChips from '@screens/tms/fillcredit/progresslist/reqchips'
const { SC, Colors } = Styles
import axios from 'axios'
import { useEffect } from 'react'
import { useCallback } from 'react'
// import StringUtil from '@utils/string'

const OverviewItem = (props) => {
  const {
    // navigation,
    isHeader,
    model,
    attrs,
    onPress,
    // onToggleExpand,
    chipsMap,
  } = props

  const [reqChips, setReqChips] = useState([])
  const [isExpand, setIsExpand] = useState(false)
  const [loading, setLoading] = useLoading()

  useEffect(() => {
    if (isExpand) {
      getChips()
    }
  }, [isExpand])

  const getChips = async () => {
    const source = axios.CancelToken.source()

    setLoading(true)
    try {
      const resp = await ApiFillCredit.chips(source.token, model.fill_credit_uuid)
      const reqChips = []
      resp.data.forEach(reqChip => {
        reqChip.chip = chipsMap.get(reqChip.chip_uuid)
        reqChips.push(reqChip)
      })
      setReqChips(reqChips)

    } catch (e) {
      Toast.exception(e, 'Fail to get chips')
    }
    setLoading(false)
  }

  const onToggleExpand = () => setIsExpand(!isExpand)

  return (
    <View style={styles.containerOuter}>
      <TouchableOpacity
        style={[
          styles.container,
          (isHeader ? styles.containerHeader : {}),
          (model?.isSelected ? styles.containerSelected : {}),
        ]}
        onPress={() => onPress(model)}>
        <FlatListItem
          isHeader={isHeader}
          model={model}
          attrs={attrs}
          style={styles.itemContainer}
        />
        {
          isExpand &&
          <View style={styles.itemContent}>
            <ReqChips reqChips={reqChips} />
          </View>
        }
      </TouchableOpacity>
      <View style={styles.containerExpand}>
        {
          !isHeader ?
            <ButtonExpand
              data={model}
              isExpand={isExpand}
              onToggleExpand={onToggleExpand}
            /> : null
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerOuter: {
    // margin: SC.GAP_S * SC.SCALE,
    // marginBottom: 0,
    padding: SC.GAP_S * SC.SCALE,
    paddingBottom: 0,
    flexDirection: 'row',
  },
  containerExpand: {
    width: 20 * SC.SCALE,
    height: '100%',
    alignItems: 'center',
    // justifyContent: 'center',
    marginTop: 8 * SC.SCALE,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    // backgroundColor: 'blue',
    borderRadius: 9,
    backgroundColor: Colors.BUTTON_BG_PRESSED,
    // padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  containerHeader: {
    backgroundColor: 'transparent',
  },
  itemContainer: {
    flex: 1,
    width: '100%',
    // backgroundColor: 'blue',
  },
  itemContent: {
    width: '100%',
    paddingBottom: scaleSize(5),
  },
})

OverviewItem.propTypes = {
  // navigation: PropTypes.any,
  isHeader: PropTypes.bool,
  model: PropTypes.object,
  attrs: PropTypes.array,
  onPress: PropTypes.func,
  onToggleExpand: PropTypes.func,
  chipsMap: PropTypes.any.isRequired,
}

export default OverviewItem
