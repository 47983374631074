import React, {
  // useEffect,
  useCallback,
  // useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'

import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'


import Styles from '@styles'
const { SC, Colors } = Styles
import FlatList from '@components/flatlist'
import FlatListItem from '@components/flatlistitem'
import { scaleSize } from '@styles'

const GridView = (props) => {
  const {
    items,
    attributeKey,
    onItemSelected,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const source = axios.CancelToken.source()

  const renderItem = ({ item }) => {
    return (
      <FlatListItem
        model={item}
        attrs={[{ key: attributeKey }]}
        key={`${item.key}`}
        onPress={() => {
          onItemSelected?.(item)
        }}
        style={styles.itemStyle}
        containerStyle={styles.itemContainer}
        {...props}
      />
    )
  }


  return (
    <View style={[styles.wrapper]}>
      <FlatList
        data={items}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${index}`}
        style={[styles.flatList]}
        numColumns={4}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-start',
    paddingVertical: scaleSize(0),
  },
  itemStyle: {
    paddingTop: 0,
    // flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginVertical: scaleSize(2),
  },
  flatList: {
    // width: '100%',
  },
  itemContainer: {
    backgroundColor: Colors.BUTTON_BG,
    flex: 1,
    textAlign: 'center',
    width: scaleSize(59),
    justifyContent: 'center',
  },
})

GridView.propTypes = {
  attributeKey: PropTypes.string,
  items: PropTypes.any,
  onItemSelected: PropTypes.func,
}

export default GridView
