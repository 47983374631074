
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import TableList from '@screens/base/tablelist'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import { TabHeader } from '@components/tabs'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const TableListPanel = (/* props */) => {
  // const {
  //   navigation,
  // } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [list] = useState([
    { id: 1, label: 'RC01' },
    // { id: 2, label: 'RC02' },
    // { id: 3, label: 'RC03' }, // uncomment to see list
  ])

  const onAddTableItem = (item) => {
    Log.log('on table item slect', item)
  }

  return (
    <View style={[styles.container]}>
      <Text style={[{ color: 'white', fontWeight: 'bold', fontSize: scaleSize(9), textAlign: 'center' }]}>
        Table No.
      </Text>
      <View style={[styles.listContainer, cs.rounded]}>
        <TableList
          data={list}
          withAdd={true}
          onAdd={onAddTableItem}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  listContainer: {
    backgroundColor: Colors.DASH_MAIN,
    flexGrow: 1,
    padding: scaleSize(6),
    marginTop: scaleSize(3),
  },
})

TableListPanel.propTypes = {
  navigation: PropTypes.any,
}

export default TableListPanel
