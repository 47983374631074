import React, {} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  StyleSheet,
  // TextInput,
  View,
} from 'react-native'

import Chip from '@components/chip'
import Input from '@components/input'

import Styles from '@styles'
const { SC } = Styles

const Chips = (props) => {
  const {
    chips,
    onPress,
    onChangeValue,
    isCashMode,
    style,
  } = props
  return (
    <View style={styles.container}>
      {
        chips ?
        _.map(chips, (chip, index) => {
          return (
            <View style={[
              styles.wrapper,
              style,
              (!isCashMode ? styles.wrapperChip : {}),
            ]} key={`${index}`}>
              <Chip
                title={chip.title}
                size={40}
                image={chip.image}
                color={chip.color}
                colorBg={chip.colorBg}
                colorText={chip.colorText}
                onPress={() => onPress(chip, index)}
                style={styles.chip}
                isCashMode={isCashMode}
              />
              <Input
                value={chip.count}
                data={chip}
                style={styles.input}
                onFocus={() => onPress(chip, index)}
                onChangeValue={(chip, value) => onChangeValue(chip, value)}
                keyboardType={'numeric'}
              />
            </View>
          )
        }) : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'space-around',
    marginVertical: '5%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // backgroundColor: 'blue',
  },
  wrapper: {
    // width: 50 * SC.SCALE,
    // height: 50 * SC.SCALE,
    // borderRadius: '50%',
    marginLeft: SC.GAP * SC.SCALE,
    marginRight: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    marginTop: SC.GAP * SC.SCALE,
    // padding: SC.GAP * SC.SCALE,
    // paddingBottom: 0,
    // backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  wrapperChip: {
    marginLeft: SC.GAP * 2 * SC.SCALE,
    marginRight: SC.GAP * 2 * SC.SCALE,
  },
  chip: {
    position: 'relative',
  },
  input: {
    // position: 'absolute',
    // bottom: -14 * SC.SCALE,
    flexWrap: 'wrap',
    width: 32 * SC.SCALE,
  },
})

Chips.propTypes = {
  chips: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  onChangeValue: PropTypes.func,
  isCashMode: PropTypes.bool,
  style: PropTypes.object,
}

export default Chips
