import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Image,
} from 'react-native'
import React, {
    // useEffect,
    useCallback,
    useState,
} from 'react'
import Log from '@utils/log'
import Feather from '@components/icons/feather'
import Styles, { CommonStyle as cs } from '@styles'
import PropTypes from 'prop-types'

const { SC } = Styles

const AddPlayerCard = (props) => {
    const [imageError, setImageError] = useState(false)
    const {
        player,
        onClick,
    } = props

    // const playerName = () => {
    //     let label = 'Empty'
    //     if (player && player.player_uuid) {
    //         if (player.player_name) {
    //             label = player.player_name
    //         }
    //         else {
    //             label = 'Unknown Player'
    //         }
    //     }
    //     return (<Text style={styles.text}>{label}</Text>)
    // }

    const onPress = useCallback(() => {
        onClick?.(player)
    }, [onClick])

    return (
        <TouchableOpacity style={[styles.card, cs.rounded, cs.pa]}
            onPress={() => onPress()}>
            <View style={[styles.border]}>
                <Feather
                    style={[styles.icon]}
                    name='plus'
                    size={15 * SC.SCALE}>
                </Feather>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: '#563D8E',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48 * SC.SCALE,
        height: 48 * SC.SCALE,
    },
    avatarBg: {
        backgroundColor: '#614A9A',
        borderWidth: 0,
    },
    border: {
        width: 30 * SC.SCALE,
        height: 30 * SC.SCALE,
        borderRadius: 60 * SC.SCALE,
        borderWidth: 1 * SC.SCALE,
        borderColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: '#29FB2E',
        fontSize: 11,
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 2,
    },
    icon: {
        color: 'white',
        fontWeight: 'normal',
    },
})

AddPlayerCard.propTypes = {
    player: PropTypes.any,
    onClick: PropTypes.func,
}

export default AddPlayerCard