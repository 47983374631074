import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  ActivityIndicator,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'

// components
import Header from '@screens/base/header'
import LinearGradient from '@components/lineargradient'
import { /* useSelector, useDispatch */ } from 'react-redux'

// styles
import Styles from '@styles'
const { SC, Colors } = Styles

const Container = (props) => {
  const {
    children,
    style,
    loading,
    isHeaderButtonCheck,
    isHeaderButtonDisabled,
    onHeaderButtonPressed,
  } = props

  // const loader = useSelector(state => state.loader)

  return (
    <View style={[styles.container, style]}>
      <LinearGradient
        colors={[Colors.BG_FROM, Colors.BG_TO]}
        style={styles.gradient}
      />
      <View style={[styles.top]}>
        <Header
          isButtonCheck={isHeaderButtonCheck}
          isButtonDisabled={isHeaderButtonDisabled}
          onButtonPressed={onHeaderButtonPressed}
          {...props} />
      </View>
      <View style={styles.bottom}>
        {(children ? children : null)}
      </View>
      {
        loading === true || loading > 0 ?
        <View style={styles.loaderWrap}>
          <ActivityIndicator style={styles.loader} color={'white'} size={12 * SC.SCALE} />
        </View> : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: SC.BORDER_RADIUS,
  },
  gradient: {
    ...StyleSheet.absoluteFill,
    zIndex: -1,
  },
  top: {
    // flex: '0 auto',
    width: '100%',
  },
  bottom: {
    flex: 1,
    width: '100%',
  },
  loaderWrap: {
    ...StyleSheet.absoluteFill,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  loader: {

  },
})

Container.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
  loader: PropTypes.any,
  loading: PropTypes.number,
  isHeaderButtonCheck: PropTypes.func,
  isHeaderButtonDisabled: PropTypes.func,
  onHeaderButtonPressed: PropTypes.func,
}

export default Container
