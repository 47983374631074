import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import Log from '@utils/log'
import DetailView from '@components/detailview'
import Toast from '@components/toast'
// import Container from '@screens/base/container'
import ApiTable from '@apis/table'
import { useSelector /* , useDispatch */ } from 'react-redux'
import useLoading from '@hooks/useLoading'
import axios from 'axios'

import Styles, { CommonStyle as cs } from '@styles'
const { SC, Colors } = Styles


const Dashboard = (props) => {

  const source = axios.CancelToken.source()
  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  const [tableInfo, setTableInfo] = useState({})
  const [loading, setLoading] = useLoading()
  // const {
  //   navigation,
  // } = props
  // const isFocused = useIsFocused()

  // dispatch actions
  // const dispatch = useDispatch()
  // const user = useSelector(state => state.user)

  useEffect(() => { overview() }, [])

  const overview = async () => {
    setLoading(true)
    try {
      const resp = await ApiTable.getOverview(source.token, tableUuid)
      setTableInfo(resp.data)
      // Toast.success(`Fill Credit Successful`)
    } catch (e) {
      Toast.exception(e, 'Fill Get Overview')
    }
    setLoading(false)
  }

  // const tableInfo = {
  //   no_of_game: 0,
  //   opening_amount: 0,
  //   current_float: 0,
  //   drop: 0,
  //   win_lose: 0,
  //   last_drop_time: '0:00:00',
  //   last_drop_amount: 0
  // }


  // useEffect(() => {
  //   if (isFocused) {
  //     init()
  //   }
  // }, [isFocused])

  // const init = () => { // todo: implement
  //   try {
  //     setLoading(true)
  //     // API calls here
  //     setTimeout(() => {
  //       setLoading(false)
  //     }, 200)
  //   } catch (error) {
  //     setLoading(false)
  //     Toast.show(`Init error: ${error}`)
  //   }
  // }


  return (
    <View style={[styles.container, cs.pa]}>
      <View style={[styles.card, cs.rounded, cs.pa]}>
        <DetailView
          model={tableInfo}
          attributes={[
            'number_of_game',
            {
              key: 'opening_float',
              label: 'Opening Amount',
            },
            'current_float',
            'drops',
            'win_lose',
            {
              key: (model) => 'Time/Amount',
              label: '',
            },
            {
              key: (model) => `${model.duration}/${model.last_drop_amount}`,
              label: 'Last Drop Amount',
            },
          ]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  card: {
    flexGrow: 1,
    backgroundColor: Colors.DASH_MAIN,
    paddingHorizontal: 15 * SC.SCALE,
  },
  rowStyle: {

  }
})

Dashboard.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
  onHeaderButtonPressed: PropTypes.func,
}

export default Dashboard
