import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
    // Platform,
    // SafeAreaView,
    // StatusBar,
    StyleSheet,
    Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
import IconButton from '@components/iconbutton'
import Toast from '@components/toast'
import axios from 'axios'
import ApiPlayer from '@apis/player'
import useLoading from '@hooks/useLoading'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const PlayerControl = (props) => {

    const source = axios.CancelToken.source()

    const {
        player,
    } = props

    const [loading, setLoading] = useLoading()

    const onPause = async () => {
        setLoading(true)
        try {
            await ApiPlayer.suspend(source.token, player.player_uuid)
            Toast.success('Pause Successfully')
        } catch (e) {
            Toast.exception(e, 'Pause Failed')
        }
        setLoading(false)
    }
    const onResume = async () => {
        setLoading(true)
        try {
            await ApiPlayer.resume(source.token, player.player_uuid)
            Toast.success('Resume Successfully')
        } catch (e) {
            Toast.exception(e, 'Resume Failed')
        }
        setLoading(false)
    }
    const onCheckout = async () => {
        setLoading(true)
        try {
            await ApiPlayer.checkout(source.token, player.player_uuid)
            Toast.success('Checkout Successfully')
        } catch (e) {
            Toast.exception(e, 'Resume Failed')
        }
        setLoading(false)

    }

    return (
        <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
            <IconButton
                style={styles.icon}
                rounded={true}
                name='pause'
                color='white'
                label='Pause'
                onPress={onPause}
                size={scaleSize(10)} />
            <IconButton
                style={styles.icon}
                rounded={true}
                name='play'
                color='white'
                label='Resume'
                onPress={onResume}
                size={scaleSize(10)} />
            <IconButton
                style={styles.icon}
                rounded={true}
                name='log-out'
                color='white'
                label='Check out'
                onPress={onCheckout}
                size={scaleSize(10)} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignContent: 'stretch',
        flexGrow: 1,
        justifyContent: 'center',
    },
    icon: {
        marginHorizontal: scaleSize(5),
    },
})

PlayerControl.propTypes = {
    player: PropTypes.any,
}

export default PlayerControl