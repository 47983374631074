import React, {
    useEffect,
    useState,
    // useMemo,
    // useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
    // Platform,
    // SafeAreaView,
    // StatusBar,
    // StyleSheet,
    Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
// import Chips from '@components/chips'
import PlayerInfo from './playerinfo'
import Log from '@utils/log'
import ChipCalculate from '@components/chips/calculate'
// import SliderPanel from '@components/chipscontrol/sliderpanel'
import ChipsControl from '@components/chipscontrol'
import ApiChip from '@apis/chip'
import ApiPlayer from '@apis/player'
import ApiDrop from '@apis/drop'
import Toast from '@components/toast'
import axios from 'axios'
import { useSelector } from 'react-redux'
import PlayerControl from './playercontrol'
import useLoading from '@hooks/useLoading'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const PlayerChip = (props) => {

    const table = useSelector(state => state.table)
    const tableUuid = table && table.uuid
    const source = axios.CancelToken.source()
    const [playerDetail, setPlayerDetail] = useState({})

    const [loading, setLoading] = useLoading()

    const {
        player,
    } = props

    const [chips, setChips] = useState([])

    useEffect(() => {
        getChips()
    }, [])

    const getChips = async () => {
        setLoading(true)
        try {
            const resp = await ApiChip.getChipsets(source.token, tableUuid)
            const chipsets = resp.data[0]
            const respChips = await ApiChip.getChips(source.token, chipsets.uuid)
            const chips = []
            respChips.data.forEach(item => {
                chips.push({
                    ...item,
                    count: 0,
                    value: +item.value,
                    colorText: 'black',
                    colorBg: '',
                    title: (+item.value).toString(),
                })
            })
            setChips(chips)

            const respPlayer = await ApiPlayer.detail(source.token, player.player_uuid)
            setPlayerDetail(respPlayer.data)

        } catch (e) {
            Toast.exception(e, 'Fail to get chips')
        }
        setLoading(false)
    }

    const onEnter = async () => {
        setLoading(true)
        try {
            let total = 0
            const chipsQty = chips.map((item) => {
                total += item.count * item.value
                return {
                    chip_uuid: item.uuid,
                    quantity: item.count,
                }
            })

            await ApiDrop.add(
                source.token,
                tableUuid,
                player.player_uuid,
                playerDetail.position,
                total,
                chipsQty,
            )

            chips.forEach(item => item.count = 0)
            setChips([...chips])

            Toast.success(`Successful`)
        } catch (e) {
            Toast.exception(e, 'Fail to enter chips')
        }
        setLoading(false)
    }

    return (
        <ChipsControl
            loading={loading}
            chips={chips}
            onChipsChange={setChips}
            onEnter={onEnter}
            prependLeftPanel={<PlayerInfo player={playerDetail} />}
            appendLeftPanel={<PlayerControl player={player} />}
        />
    )
}

PlayerChip.propTypes = {
    player: PropTypes.any,
}

// const styles = StyleSheet.create({
//     container: {
//         flexDirection: 'row',
//         flexGrow: 1,
//     },
//     leftPanel: {
//         flex: 3,
//         backgroundColor: Colors.DASH_MAIN,
//         flexGrow: 2,
//         paddingHorizontal: scaleSize(15),
//         paddingVertical: scaleSize(5),
//     },
// })

export default PlayerChip