import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  // Text,
  TouchableOpacity,
  View,
} from 'react-native'

// import Slider from '@react-native-community/slider'
import /* RangeSlider, */{ Slider } from 'react-native-range-slider-expo'
import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC } = Styles

const SliderControl = (props) => {
  const {
    minimumValue,
    maximumValue,
    width,
    step,
    value,
    setValue,
    style,
  } = props

  // const [value, setValue] = useState(0)

  const onStep = useCallback((step) => {
    let val = value
    val += step
    if (val < minimumValue) {
      val = minimumValue
    }
    if (val > maximumValue) {
      val = maximumValue
    }
    if (setValue) setValue(val)
  }, [value, setValue, minimumValue, maximumValue])

  return (
    <View style={[styles.container, style]}>
      {/* <View style={styles.sideWrapper}>
        <TouchableOpacity style={styles.button} onPress={() => onStep(-step)}>
          <Feather name={'minus'}/>
        </TouchableOpacity>
      </View> */}
      <View style={styles.sliderWrapper}>
        {/* <Slider
          style={styles.slider}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          step={step}
          value={value}
          onValueChange={value => ((setValue) ? setValue(value) : () => {
            // do nothing
          })}
          minimumTrackTintColor="#000000"
          maximumTrackTintColor="#000000"
          thumbTintColor="#ffffff"
        /> */}
        <Slider
          containerStyle={[styles.slider, (width != null ? { width: (width * SC.SCALE) } : {})]}
          styleSize={'small'}
          knobSize={24}
          min={minimumValue}
          max={maximumValue}
          step={step}
          valueOnChange={value => ((setValue) ? setValue(value) : () => {
            // do nothing
          })}
          initialValue={value}
          knobColor='#ffffff'
          valueLabelsBackgroundColor='black'
          inRangeBarColor='#000000'
          outOfRangeBarColor='#000000'
          showRangeLabels={false}
          labelFormatter={(val) => {
            return `    ${val}`
          }}
        />
      </View>
      {/* <View style={styles.sideWrapper}>
        <TouchableOpacity style={styles.button} onPress={() => onStep(step)}>
          <Feather name={'plus'}/>
        </TouchableOpacity>
      </View> */}
      <View style={styles.buttonsWrapper}>
        <View style={styles.sideWrapper}>
          <TouchableOpacity style={styles.button} onPress={() => onStep(-step)}>
            <Feather name={'minus'}/>
          </TouchableOpacity>
        </View>
        <View style={{flex:1}}></View>
        <View style={styles.sideWrapper}>
          <TouchableOpacity style={styles.button} onPress={() => onStep(step)}>
            <Feather name={'plus'}/>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // flexDirection: 'row',
    flexDirection: 'column',
    width: '100%',
  },
  sideWrapper: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderWrapper: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'pink',
    paddingTop: SC.GAP_S * SC.SCALE,
    paddingLeft: SC.GAP * SC.SCALE,
  },
  slider: {
    width: 40 * SC.SCALE,
    height: '100%',
    flex: 1,
    // flexBasis: 100,
    // flexGrow: 1,
    // backgroundColor: 'blue',
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    // padding: 0,
    // margin: 0,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    paddingTop: SC.GAP_S * SC.SCALE,
  },
  button: {
    borderRadius: 9,
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 3 * SC.SCALE,
    paddingLeft: 6 * SC.SCALE,
    paddingRight: 6 * SC.SCALE,
    margin: 2 * SC.SCALE,
  },
})

SliderControl.propTypes = {
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  width: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  setValue: PropTypes.func,
  style: PropTypes.any,
}

export default SliderControl
