import React, {
  // useEffect,
  // useState,
  useCallback,
  // useMemo,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import LoginView from '@screens/base/loginview'
// import LoadingView from '@components/loadingview'
import Toast from '@components/toast'
// import DetailView /* , { LabelValue } */ from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles

// import useLoading from '@hooks/useLoading'

const source = axios.CancelToken.source()
import ApiUser from '@apis/user'

const StepProgress = (props) => {
  const {
    step,
    setLoading,
    nextStep,
  } = props

  const loginRef = useRef()
  const onLogin = useCallback(async (username, password) => {
    // Log.log('DialogAccount: on login', { username, password })
    try {
      setLoading(true)
      let response = await ApiUser.login(source.token, username, password)
      if (!response || !response.data || !response.data.access_token) {
        throw new Error('invalid login data')
      }
      // let data = response.data
      // let token = data.access_token
      // await dispatch(updateToken(token))
      // await dispatch(updateName(data?.name))
      // Log.log('DialogAccount: login response', response)
      Toast.show('Login successfully.')
      nextStep()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const onLoginRef = useCallback(async () => {
    let username = loginRef?.current?.username
    let password = loginRef?.current?.password
    await onLogin(username, password)
  }, [onLogin])

  return (
    <View style={[styles.container]}>
      <View style={styles.top}>
        <Text style={styles.title}>
          {step.label}
        </Text>
      </View>
      <View style={styles.content}>
        <View style={styles.loaderWrapper}>
          {/* <LoadingView /> */}
          <View style={styles.loginWrapper}>
            <LoginView
              ref={loginRef}
              noButton={true}
              onLogin={onLogin}
              buttonTitle={'Login'} />
            <View style={styles.buttons}>
              {
                <Button
                  title={'Login'}
                  onPress={() => onLoginRef()}
                  style={styles.buttonReset} />
              }
            </View>
          </View>
        </View>
      </View>
      <View style={styles.buttons}>
        <Button
          title={'Cancel'}
          onPress={() => {
            //
          }}
          style={styles.buttonClose} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  top: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  content: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  loginWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderWrapper: {
    flex: 1,
    width: '80%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9,
    marginTop: SC.GAP * SC.SCALE * 2,
  },
  loader: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  buttons: {
    flexDirection: 'row',
  },
  buttonClose: {
    marginTop: SC.GAP * SC.SCALE * 2,
    backgroundColor: Colors.BUTTON_WIZ,
    marginHorizontal: 3 * SC.SCALE,
  },
  buttonReset: {
    marginTop: SC.GAP * SC.SCALE * 2,
    backgroundColor: Colors.BUTTON_RESET,
    marginHorizontal: 3 * SC.SCALE,
  },
})

StepProgress.propTypes = {
  step: PropTypes.object,
  setLoading: PropTypes.func,
  nextStep: PropTypes.func,
}

export default StepProgress
