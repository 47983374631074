import React, { } from 'react'
import PropTypes from 'prop-types'
// import {
//   Platform,
//   // SafeAreaView,
//   // StatusBar,
//   // Text,
//   // TextInput,
//   // TouchableOpacity,
//   // View,
// } from 'react-native'
// import Drawer from '@screens/drawer'
import EnvConstants from '@constants/env'
// import Log from '@utils/log'

// tms
import Splash from '@screens/tms/splash'
import Lock from '@screens/tms/lock'
import HomeTms from '@screens/tms/home'
import WizardOpening from '@screens/tms/wiz-opening'
import WizardSelectTable from '@screens/tms/wiz-selecttable'
import WizardSelectFloat from '@screens/tms/wiz-selectfloat'
import WizardEnterId from '@screens/tms/wiz-enterid'

// pit
import HomePit from '@screens/pit/home'
import Login from '@screens/pit/login'

import NotFound from '@screens/notfound'

import Linking from './linking'

// react-redux
import { useSelector/* , useDispatch */ } from 'react-redux'
// import { updateTable } from '@actions/table'
// import { updateToken } from '@actions/auth'

// navigation
import { /* DefaultTheme, */ NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
// import { createStackNavigator } from '@react-navigation/stack'

import NavOptions from './navoptions'
const Stack = createNativeStackNavigator()

const headerShown = false // (Platform.OS !== 'web')

const Router = ({ colorScheme }) => {
  // dispatch actions
  // const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const table = useSelector(state => state.table)
  const token = auth && auth.token
  const tuuid = table && table.uuid

  const ScreensTms = () => {
    return (
      <Stack.Group>
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='splash'
          component={Splash} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='home'
          component={HomeTms} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-opening'
          component={WizardOpening} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-selecttable'
          component={WizardSelectTable} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-selectfloat'
          component={WizardSelectFloat} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-enterid'
          component={WizardEnterId} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='lock'
          component={Lock} />
      </Stack.Group>
    )
  }

  const ScreensPit = () => {
    return (
      <Stack.Group>
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='home'
          component={HomePit} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='login'
          component={Login} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='lock'
          component={Lock} />
      </Stack.Group>
    )
  }

  let initialRouteName = (
    EnvConstants.APP_TYPE === 'tms' ?
      ((token && token.length > 0 && tuuid && tuuid.length > 0) ? 'home' : 'splash') :
    EnvConstants.APP_TYPE === 'pit' ?
      ((token && token.length > 0 && tuuid && tuuid.length > 0) ? 'home' : 'login') : 'error'
  )

  return (
    <NavigationContainer
      linking={Linking}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <Stack.Navigator
        screenOptions={{ headerShadowVisible: true }}
        initialRouteName={initialRouteName}>
        {/* TMS */}
        {EnvConstants.APP_TYPE === 'tms' ? (() => ScreensTms())() : null}
        {/* PIT */}
        {EnvConstants.APP_TYPE === 'pit' ? (() => ScreensPit())() : null}
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='notfound'
          component={NotFound} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

Router.propTypes = {
  colorScheme: PropTypes.string,
}

export default Router
