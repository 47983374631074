import api from '@apis'

// unauthenticated

const getPitUuid = (cancelToken, serialCode) => {
  var data = {
    serial_code: serialCode,
  }
  return api({
    url: `/api/pit/serial-code`,
    method: 'post',
    data,
    cancelToken,
  })
}

const createFillCredit = (cancelToken, tableUuid, type, chips, accessToken) => {
  var data = {
    table_uuid: tableUuid,
    type: type,
    chips: chips,
  }
  return api({
    url: `/api/fill-credit/add`,
    method: 'post',
    data,
    cancelToken,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

const createFill = (cancelToken, tableUuid, chips, accessToken) => createFillCredit(cancelToken, tableUuid, 'fill', chips, accessToken)

const createCredit = (cancelToken, tableUuid, chips, accessToken) => createFillCredit(cancelToken, tableUuid, 'credit', chips, accessToken)

const fills = (cancelToken, tableUuid) =>
  api({
    url: `/api/fill-credit/fill/all`,
    method: 'post',
    data: { table_uuid: tableUuid },
    cancelToken,
  })

const credits = (cancelToken, tableUuid) =>
  api({
    url: `/api/fill-credit/credit/all`,
    method: 'post',
    data: { table_uuid: tableUuid },
    cancelToken,
  })

const chips = (cancelToken, fillCreditUuid) => {
  return api({
    url: `/api/fill-credit/chips`,
    method: 'post',
    data: { fill_credit_uuid: fillCreditUuid },
    cancelToken,
  })
}

const overview = (cancelToken, pitUuid) => {
  return api({
    url: `/api/fill-credit/overview`,
    method: 'post',
    data: { pit_uuid: pitUuid },
    cancelToken,
  })
}

export default {
  getPitUuid,
  createFill,
  createCredit,
  fills,
  credits,
  chips,
  overview,
}
