import {
    // Platform,
    // SafeAreaView,
    // StatusBar,
    StyleSheet,
    Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import LoadingView from '@components/loadingview'
import PlayerGrid from '@components/player/PlayerGrid'
import DlgAddPlayer from './dlgaddplayer'
import { useSelector, useDispatch } from 'react-redux'
import Toast from '@components/toast'
import Log from '@utils/log'
import ApiTable from '@apis/table'
import axios from 'axios'
import PropTypes from 'prop-types'

import Styles, { CommonStyle as cs } from '@styles'
const { SC, Colors } = Styles

const source = axios.CancelToken.source()

const Standing = (props) => {

    const {
        onPlayerSelect
    } = props

    const table = useSelector(state => state.table)
    const [loading, setLoading] = useState(true)
    const [showAddPlayer, setShowAddPlayer] = useState(false)
    const [players, setPlayers] = useState([{ isLast: true }])
    const isInit = true

    useEffect(() => {
        if (isInit) {
            init()
        }
    }, [isInit])

    const init = async () => { // todo: implement
        setLoading(true)
        try {
            const resp = await ApiTable.getSeat(source.token, table.uuid, 'stand')
            const players = resp.data
            players.push({ isLast: true })
            setPlayers(players)
        } catch (e) {
            Toast.exception(e, 'Get seat failed')
        }
        setLoading(false)
    }

    const onAddPlayer = () => {
        setShowAddPlayer(false)
        init()
    }

    const onAddPlayerClick = () => {
        setShowAddPlayer(true)
    }

    const onPlayerClick = (player) => {
        if (player.player_uuid && onPlayerSelect) {
            onPlayerSelect(player)
        }
        else {
            setSelecteSeatNo(player.seat_no)
            setShowAddPlayer(true)
        }
    }


    return (
        <LoadingView loading={loading} style={[styles.container, cs.roundedEnd, cs.pa]}>
            <PlayerGrid players={players} onPlayerClick={(player) => onPlayerClick(player)} type='stand' onAddPlayerClick={onAddPlayerClick} />
            <DlgAddPlayer
                visible={showAddPlayer}
                title={'Dialog Title'}
                onAddPlayer={onAddPlayer}
                position='stand'
                setVisible={setShowAddPlayer} />
        </LoadingView>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.DASH_MAIN,
        flexGrow: 1,
        borderBottomStartRadius: SC.BORDER_RADIUS,
    },
})

Standing.propTypes = {
    onPlayerSelect: PropTypes.func,
}

export default Standing