import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'

// import Log from '@utils/log'

import Toast from '@components/toast'
import { TabHeader } from '@components/tabs'
import TableList from '@screens/base/tablelist'

import ApiChip from '@apis/chip'
import { useSelector } from 'react-redux'

import Styles, { CommonStyle as cs } from '@styles'
const { SC, Colors } = Styles

import Overview from '@screens/pit/fillcredit/layout/overview'
import Fill from '@components/fillcredit/fill'
import Credit from '@components/fillcredit/credit'
import useLoading from '@hooks/useLoading'

const TAB_FILL = 'fill'
const TAB_CREDIT = 'credit'
const TAB_PROGRESS = 'progress'

const tabs = [
  { id: TAB_FILL, name: 'Fill' },
  { id: TAB_CREDIT, name: 'Credit' },
  { id: TAB_PROGRESS, name: 'Progress' },
]

const FillCredit = (props) => {
  // const {
    // navigation,
    // setLoading,
  // } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const tables = useSelector(state => state.table.tables)
  const pit = useSelector(state => state.pit)

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [creditChipSets, setCreditChipSet] = useState(new Map())
  const [fillChipSets, setFillChipSets] = useState(new Map())
  const [chipsMap, setChipsMap] = useState([])
  const [selectedTable, setSelectedTable] = useState(undefined)
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useLoading()


  useEffect(() => {
    if (tables[0]) {
      onTableSelect(tables[0])
    }
    else {
      setSelectedTab(0)
    }
    if (isFocused) {
      // init()
    }
  }, [isFocused])

  useEffect(() => { getChipsets() }, [selectedTable])

  const getChipsets = async () => {
    if (!selectedTable) {
      return
    }

    setLoading(true)

    try {
      const [chipSetMap, chipMap] = await ApiChip.getAllChipSetAndChipInMap(source.token, selectedTable.uuid)
      setChipsMap(chipMap)
      setCreditChipSet(chipSetMap)
      setFillChipSets(_.cloneDeep(chipSetMap))
      setSelectedTab(tabs[0])
    } catch (error) {
      Toast.exception(error, 'Fail to get chips')
    }
    setLoading(false)
  }

  const onTableSelect = (selected) => {
    setTableList(tables.map((item) => ({
      ...item,
      isSelected: item.uuid === selected.uuid,
    })))
    setSelectedTable(selected)
  }

  return (
    <View style={[styles.container, cs.pa]}>
      {
        selectedTab.id !== TAB_PROGRESS &&
        <View style={styles.containerSide}>
          <TableList
            data={tableList}
            onSelect={onTableSelect}
            canUnselect={false}
            {...props}
          />
        </View>
      }
      <View style={styles.containerMain}>
        <TabHeader
          tabs={tabs}
          selectedItem={selectedTab}
          onChangeTab={setSelectedTab}
        />
        <View style={{ flex: 1 }}>
          {selectedTable && <>
            {selectedTab.id === TAB_FILL && <Fill chipSets={fillChipSets} tableUuid={selectedTable.uuid} />}
            {selectedTab.id === TAB_CREDIT && <Credit chipSets={creditChipSets} tableUuid={selectedTable.uuid} />}
            {selectedTab.id === TAB_PROGRESS && <Overview pitUuid={pit.uuid} chipsMap={chipsMap} />}
          </>
          }
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  containerSide: {
    flex: 1,
    borderRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
    marginRight: SC.GAP_S * SC.SCALE,
    padding: SC.GAP_S * SC.SCALE,
  },
  containerMain: {
    flex: 5,
  },
})

FillCredit.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
}

export default FillCredit
