
import Constants from './constants'
import Colors from './colors'
import Common from './common'

const Styles = {
  SC: Constants, // alias
  Colors,
}

export {
  Common as CommonStyle
}
export const scaleSize = (value) => {
  return value * Styles.SC.SCALE
}

export default Styles