import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  // Text,
  View,
} from 'react-native'
// import Button from '@components/button'
import ButtonHeader from '@components/buttonheader'
import FlatList from '@components/flatlist'

import TableItem from './tableitem'

// import {
//   CommonStyle as cs,
//   // scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC /* , Colors */ } = Styles
import Log from '@utils/log'

const TableList = (props) => {
  const {
    // navigation,
    data,
    withAdd,
    onAdd,
    onSelect,
    canUnselect,
  } = props

  const [items, setItems] = useState(data)

  useEffect(() => {
    let all = _.map(data, (item) => {
      return {
        ...item,
        isSelected: (item.hasOwnProperty('isSelected') ? item.isSelected : false),
      }
    })
    console.log('tablelist: data()', all)
    setItems(all)
  }, [data])

  const isIdentical = (a, b) => {
    return (
      (a.uuid != null && a.uuid === b.uuid)
    )
  }

  // useEffect(() => {
  //   let found = _.find(items, (item) => isIdentical(item, { uuid: initialUuid }))
  //   if (found) {
  //     found.isSelected = true
  //     setItems([].concat(items))
  //   }
  // }, [items, initialUuid])

  const onPress = useCallback((item) => {
    let found = _.find(items, (it) => isIdentical(it, item))
    if (!found) {
      Log.info(`item not found: ${item?.uuid}`)
      return
    }
    found.isSelected = canUnselect ? !found.isSelected : true
    if (found.isSelected) {
      for (let i in items) {
        let item = items[i]
        let isSame = isIdentical(item, found)
        if (!isSame) {
          item.isSelected = false
        }
      }
    }
    setItems([].concat(items))
    onSelect(item)
  }, [items])

  const renderFooter = () => {
    let isAdd = (!items || items.length <= 0) || withAdd
    if (isAdd) {
      return (
        <View style={styles.addWrapper}>
          <ButtonHeader
            iconName={'plus'}
            onPress={onAdd}
            disabled={false}
            style={styles.button}
          />
        </View>
      )
    }
    return null
  }

  const renderItem = ({item}) => {
    return (
      <TableItem
        key={`${item?.uuid}`}
        item={item}
        isSelected={item.isSelected}
        isChecked={item.isMinMax}
        onPress={onPress}
        {...props}
      />
    )
  }
  return (
    <FlatList
      data={items}
      renderItem={renderItem}
      keyExtractor={item => item.uuid}
      ListFooterComponent={renderFooter}
    />
  )
}

const styles = StyleSheet.create({
  addWrapper: {
    width: '100%',
    alignItems: 'center',
    marginTop: SC.GAP * SC.SCALE,
  },
})

TableList.propTypes = {
  // navigation: PropTypes.any,
  data: PropTypes.array,
  withAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func,
  canUnselect: PropTypes.bool,
}

export default TableList
