import {
    StyleSheet,
    Text,
    // TouchableOpacity,
    View,
    Image,
} from 'react-native'
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react'
// import Log from '@utils/log'
import Feather from '@components/icons/feather'
import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
import PropTypes from 'prop-types'
import ChipsControl from '@components/chipscontrol'
import Toast from 'react-native-root-toast'
import ChipCalculate from '@components/chips/calculate'
import DetailView from '@components/detailview'

const { SC, Colors } = Styles


// const STATUS_SENDING = 'sending'
const STATUS_PENDING = 'pending'
const STATUS_PENDING_2 = 'pending2'
const STATUS_COMPLETED = 'completed'
const STATUS_CANCELLED = 'cancelled'

const COLOR_IN_PROGRESS = '#FDA541'
const COLOR_SUCCESS = '#4EBF39'
const COLOR_CANCELLED = '#E72654'


const ProgressBar = (props) => {

    const {
        item,
    } = props

    const [chips, setChips] = useState([{
        uuid: '123',
        colour: 'Blue',
        value: 10,
        count: 0,
    }, {
        uuid: '124',
        colour: 'Black',
        value: 20,
        count: 0,
    }])

    useEffect(() => {
        setChips(ChipCalculate.mapChips(chips, [], 1))
    }, [])

    const textDescription = useMemo(() => {
        if (item.status_name == STATUS_PENDING) {
            return 'Sending to cage'
        }
        else if (item.status_name == STATUS_PENDING_2) {
            return 'Pending from cage'
        }
        else if (item.status_name == STATUS_COMPLETED) {
            return 'Completed'
        }
        else if (item.status_name == STATUS_CANCELLED) {
            return 'Cancelled'
        }

        return ''

    }, [])

    const box1Color = useMemo(() => {
        if (item.status_name === STATUS_COMPLETED) {
            return COLOR_SUCCESS
        }
        else if (item.status_name === STATUS_CANCELLED) {
            return COLOR_CANCELLED
        }
        return COLOR_IN_PROGRESS
    }, [])

    const box2Color = useMemo(() => {
        if (item.status_name === STATUS_COMPLETED) {
            return COLOR_SUCCESS
        }
        else if (item.status_name === STATUS_PENDING_2) {
            return COLOR_IN_PROGRESS
        }
        return Colors.BUTTON_BG_PRESSED
    }, [])

    const box3Color = useMemo(() => {
        if (item.status_name === STATUS_COMPLETED) {
            return COLOR_SUCCESS
        }
        return Colors.BUTTON_BG_PRESSED
    }, [])

    return (
        <View style={styles.container}>
            <View style={styles.statusDescriptionContainer}>
                <Text style={styles.statusDescription}>{textDescription}</Text>
            </View>
            <View style={styles.statusBarContainer}>
                <View style={[styles.box, styles.borderEnd, styles.roundedStart, { backgroundColor: box1Color }]}>
                    <Text style={[styles.text]}>{item.requested_at}</Text>
                </View>
                <View style={[styles.box, styles.borderEnd, { backgroundColor: box2Color }]}>
                    <Text style={[styles.text]}>{item.receive_at}</Text>
                </View>
                <View style={[styles.box, styles.roundedEnd, { backgroundColor: box3Color }]}>
                    <Text style={[styles.text]}>{item.complete_at}</Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignContent: 'stretch',
        flexGrow: 1,
        justifyContent: 'center',
    },
    statusDescriptionContainer: {
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: scaleSize(3),
    },
    statusDescription: {
        color: 'white',
        fontWeight: 'bold',
    },
    statusBarContainer: {
        flexDirection: 'row',
        alignContent: 'stretch',
        flexGrow: 3,
        paddingHorizontal: scaleSize(5),
    },
    box: {
        flex: 1,
        justifyContent: 'center',
        paddingVertical: scaleSize(2),
        alignItems: 'center',
        backgroundColor: Colors.BUTTON_BG_PRESSED,
    },
    borderEnd: {
        borderColor: 'black',
        borderEndWidth: scaleSize(0.5),
    },
    roundedStart: {
        borderTopStartRadius: 5,
        borderBottomStartRadius: 5,
    },
    roundedEnd: {
        borderTopEndRadius: 5,
        borderBottomEndRadius: 5,
    },
    text: {
        color: 'white',
    },
})

ProgressBar.propTypes = {
    item: PropTypes.any.isRequired,
}

export default ProgressBar