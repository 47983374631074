// expo constants
import ExConstants from 'expo-constants'
const ExpoConstants  = ExConstants?.expoConfig?.extra || {}

// process constants
const ProcessConstants = process.env?.APP_MANIFEST?.extra || {}
const Env = {
  ...ExpoConstants,
  ...ProcessConstants,
}

export default Env
