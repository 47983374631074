import api from '@apis'
import _ from 'lodash'

// unauthenticated

const ChipsetTypes = {
  CASH: 'cash chip',
  NN: 'nn chip',
}

const getAllChipSetInMap = async (cancelToken, tableUuid) => {
  const resp = await getChipsets(cancelToken, tableUuid)
  const chipSetsMap = new Map()
  resp.data.forEach((item) => {
    chipSetsMap.set(item.uuid, { ...item, chips: [] })
  })

  return chipSetsMap
}

const getAllChipSetAndChipInMap = async (cancelToken, tableUuid) => {
  const chipSetMap = await getAllChipSetInMap(cancelToken, tableUuid)
  const chipMap = new Map()

  for (const [key, value] of chipSetMap) {
    const resp = await getChips(cancelToken, value.uuid)
    const chips = resp.data
    for (const chip of chips) {
      const chipInfo = {
        ...chip,
        count: 0,
        value: +chip.value,
        colorText: 'black',
        colorBg: '',
        title: (+chip.value).toString(),
      }

      chipMap.set(chip.uuid, chipInfo)
      chipSetMap.get(key).chips.push(chipInfo)
    }
  }

  return [chipSetMap, chipMap]
}

const getChipsets = async (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  let response = await api({
    url: `/api/chipset/all`,
    method: 'post',
    data,
    cancelToken,
  })
  let list = response && response.data
  if (list) {
    list = _.orderBy(list, ['name'], ['asc'])
  }
  response.data = list
  return response
}

const getChips = async (cancelToken, chipSetUuid) => {
  var data = {
    'chip_set_uuid': chipSetUuid,
  }
  let response = await api({
    url: `/api/chips/all`,
    method: 'post',
    data,
    cancelToken,
  })
  if (response && response.data) {
    let list = response.data
    list = _.map(list, (item) => {
      item.value = parseFloat(item.value)
      return item
    })
    list = _.orderBy(list, ['value'], ['asc'])
    response.data = list
  }
  return response
}

// authenticated

export default {
  ChipsetTypes,
  getChipsets,
  getChips,
  getAllChipSetAndChipInMap,
}
