import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
// import ButtonHeader from '@components/buttonheader'
// import CheckBox from '@components/checkbox'
import Feather from '@components/icons/feather'
// import FlatList from '@components/flatlist'

// import {
//   CommonStyle as cs,
//   scaleSize,
// } from '@styles'
import Styles from '@styles'
const { SC/* , Colors */ } = Styles
// import StringUtil from '@utils/string'

const FlatListMinMax = (props) => {
  const {
    // navigation,
    // isHeader,
    model,
    attr,
    value,
    // min-max
    onMinMaxPress,
  } = props

  return (
    <View
      style={[
        styles.minmaxWrapper,
        attr.style,
      ]}>
      <Button
        disabled={false}
        style={styles.iconButton}
        onPress={() => onMinMaxPress('min', model, attr)}>
        <Feather style={[styles.icon]} name={'arrow-left'}>
        </Feather>
      </Button>
      <Text
        style={[
          styles.label,
          attr.style,
        ]}>
        {value(model, attr)}
      </Text>
      <Button
        disabled={false}
        style={styles.iconButton}
        onPress={() => onMinMaxPress('max', model, attr)}>
        <Feather style={[styles.icon]} name={'arrow-right'}>
        </Feather>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    // flex: 1,
    color: 'white',
    fontSize: 9 * SC.SCALE,
    padding: SC.GAP_S * SC.SCALE,
  },
  minmaxWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  icon: {
    color: 'white',
    fontSize: 9 * SC.SCALE,
  },
  iconButton: {
    backgroundColor: 'transparent',
  },
  iconDisabled: {
    color: 'rgba(255, 255, 255, 0.3)',
    fontSize: 12 * SC.SCALE,
  },
})

FlatListMinMax.propTypes = {
  // isHeader: PropTypes.bool,
  model: PropTypes.object,
  attr: PropTypes.object,
  value: PropTypes.func,
  onMinMaxPress: PropTypes.func,
}

export default FlatListMinMax
