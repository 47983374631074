import _ from 'lodash'
import { UPDATE_COUNT } from '@actions/loader'

const loaderDefault = _.assign({}, {
  count: 0,
})

const loaderReducer  = (loader = loaderDefault, action) => {
  switch (action.type) {
    case UPDATE_COUNT:
      return {
        ...loader,
        count: action.count,
      }
    default:
      return loader
  }
}

export default loaderReducer
