
import { Dimensions/* , PixelRatio */ } from 'react-native'

const { width, height } = Dimensions.get('window')
const [shortDimension, longDimension] = width < height ? [width, height] : [height, width]

const guidelineBaseWidth = 350
const guidelineBaseHeight = 680

const scale = (size) => shortDimension / guidelineBaseWidth * size
const verticalScale = (size) => longDimension / guidelineBaseHeight * size
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor
const moderateVerticalScale = (size, factor = 0.5) => size + (verticalScale(size) - size) * factor

const SCALE = scale(1)
const SCALE_V = verticalScale(1)

// const screenHeight = Dimensions.get('window').height
// const screenWidth = Dimensions.get('window').width
// let SCALE = screenWidth / 380
// if (SCALE > 1.25) {
//   SCALE = 1.25
// }
// let SCALE = 1
// if (PixelRatio.get() <= 2) {
//   SCALE = 0.7
// }

const StyleConstants = {
  SCALE,
  SCALE_V,
  scale,
  verticalScale,
  moderateScale,
  moderateVerticalScale,
  SCREEN_WIDTH: width,
  SCREEN_HEIGHT: height,
  GAP_S: 3,
  GAP: 6,
  BORDER_RADIUS: 16,
}

export default StyleConstants
