
import React, {
  useEffect,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import Button from '@components/button'
import Toast from '@components/toast'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'
import DetailView from '@components/detailview'
import Button from '@components/button'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

// import { useDispatch, useSelector } from 'react-redux'
// import { clearAuth } from '@actions/auth'

const JackpotWinner = (props) => {
  const {
    onTrigger,
    onReset,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    // Log.log('jackpotmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    // Log.log('jackpotmanagement: init')
    try {
      setLoading(true)
      // stub
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  const [winnerDetail, setWinnerDetail] = useState(() => {
    return {
      player_name: 'Harper Williams',
      player_id: '7402760',
      table_no: 'RL02',
      preset: '66',
    }
  })

  return (
    <View style={[styles.container, cs.roundedEnd, cs.roundedBottom]}>
      <Text style={[styles.title]}>Congratulations!</Text>
      <Text style={[styles.text]}>Barracat 66</Text>
      <Text style={[styles.text, cs.rounded, styles.value]}>RM 100,000.00</Text>
      <DetailView
        style={{ width: '70%', paddingTop: scaleSize(5) }}
        model={winnerDetail}
        labelStyle={{ fontSize: 20 }}
        valueStyle={{ fontSize: 20 }}
        attributes={[
          'player_name',
          'player_id',
          'table_no',
          'preset',
          // 'current_float',
          // {
          //   key: 'total_amount',
          //   label: 'Total Float',
          // },
        ]} />
      <View style={[{ flexDirection: 'row', paddingTop: scaleSize(5), justifyContent: 'space-evenly' }]}>
        <Button
          title="Trigger"
          onPress={onTrigger}
          style={styles.button} />
        <Button
          title="Reset Value"
          disabled={false}
          onPress={onReset}
          style={[styles.button, styles.buttonReset]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: Colors.DASH_MAIN,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: scaleSize(25),
    textAlign: 'center',
  },
  text: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: scaleSize(13),
    textAlign: 'center',
  },
  value: {
    marginTop: scaleSize(5),
    color: 'white',
    fontWeight: 'normal',
    borderWidth: scaleSize(1),
    borderColor: 'white',
    paddingHorizontal: scaleSize(40),
    paddingVertical: scaleSize(2),
  },
  buttonReset: {
    backgroundColor: Colors.GREY,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  button: {
    marginTop: scaleSize(8),
    marginHorizontal: scaleSize(10),
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
    width: scaleSize(80),
  },
})

JackpotWinner.propTypes = {
  navigation: PropTypes.any,
  onTrigger: PropTypes.func,
  onReset: PropTypes.func,
}

export default JackpotWinner
