import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'
import Log from '@utils/log'

import Toast from '@components/toast'
import Container from '@screens/base/container'
import Button from '@components/button'

import Styles from '@styles'
const { SC } = Styles

import SplashImage from '@assets/present.png'

// react-redux
import { useSelector, useDispatch } from 'react-redux'
import { clearTable } from '@actions/table'

const Splash = (props) => {
  const {
    navigation,
  } = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()

  // dispatch actions
  const dispatch = useDispatch()
  // const table = useSelector(state => state.table)

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  const auth = useSelector(state => state.auth)
  const token = auth && auth.token

  // header controls

  const isHeaderButtonCheck = useCallback((name) => {
    switch (name) {
      case 'back':
      case 'closing':
      case 'home':
        return false
      case 'account':
        return true
      default:
        return false
    }
  })

  const onHeaderButtonPressed = useCallback((info) => {
    switch (info?.name) {
      case 'home':
        return onHome()
      default:
        Log.log(`not implemented yet: ${info?.name}`)
        break
    }
  }, [onHome])

  // components

  useEffect(() => {
    Log.log('splash: init', { isFocused })
    if (isFocused) {
      preload()
      init()
    }
  }, [isFocused])

  const preload = () => {
    if (token && token.length > 0 && tableUuid && tableUuid.length > 0) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const init = () => { // todo: implement
    try {
      setLoading(true)
      // API calls here
      setTimeout(() => {
        setLoading(false)
      }, 200)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const onHome = () => {
    navigation.navigate('home')
  }

  const onWizardNext = async () => {
    await dispatch(clearTable())
    navigation.navigate('wizard-selecttable')
  }

  return (
    <Container
      style={styles.container}
      loading={loading}
      isHeaderButtonCheck={isHeaderButtonCheck}
      onHeaderButtonPressed={onHeaderButtonPressed}>
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.imageWrapper} onPress={onHome}>
          <Image source={SplashImage} style={styles.image} />
        </TouchableOpacity>
        <Button
          title="Open Table"
          onPress={onWizardNext}
          style={styles.button} />
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
  },
  image: {
    width: 300 * SC.SCALE,
    height: 180 * SC.SCALE,
  },
  button: {
    width: 300 * SC.SCALE,
    marginTop: SC.GAP,
  },
})

Splash.propTypes = {
  navigation: PropTypes.any,
}

export default Splash
