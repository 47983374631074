import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from 'react-native'

// import Button from '@components/button'
// import Input from '@components/input'
import Dialog from '@components/dialog'
import Toast from '@components/toast'
// import LoginView from '@screens/base/loginview'
import { extractChipsWithQty, resetChipQty } from '@components/chipscontrol/chipcontrolutil'

import Styles, { scaleSize } from '@styles'
const { SC, Colors } = Styles

// import Log from '@utils/log'
import { useIsFocused } from '@react-navigation/native'

// import { useSelector } from 'react-redux'
import useLoading from '@hooks/useLoading'

import ApiUser from '@apis/user'
import ApiTable from '@apis/table'

// const source = axios.CancelToken.source()
const DialogUpdate = (props) => {
  const {
    visible,
    setVisible,
    tableUuid,
    total,
    chipSets,
    onUpdateSuccessPress,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useLoading()

  const [state, setState] = useState()

  // dispatch actions
  // const dispatch = useDispatch()

  // const table = useSelector(state => state.table)
  // const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  useEffect(() => {
    if (isFocused) {
      init()
    }
    return deinit
  }, [init, deinit])

  const init = useCallback(() => {
    // setState(undefined)
  }, [])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  // useEffect(() => {
  //   setState(undefined)
  // }, [visible])

  // const onDialogMessagePressed = useCallback(() => {
  //   switch (dialogStatus) {
  //     case 'message':
  //       setDialogMessage('Update Successful!')
  //       setDialogStatus('success')
  //       break
  //     case 'success':
  //       setDialogMessage('')
  //       setDialogStatus(undefined)
  //       setDialogEnterIdVisible(false)
  //       break
  //     default:
  //       // initial visible
  //       setDialogMessage('Please Swipe/Scan Supervisor or Manager ID')
  //       setDialogStatus('message')
  //       setDialogEnterIdVisible(true)
  //       break
  //   }
  // }, [dialogStatus])

  const onLoginAndUpdate = useCallback(async (/* username, password */) => {
    try {
      setLoading(true)

      if (total == null || isNaN(parseInt(total, 10))) {
        throw new Error('Invalid float amount')
      }

      let amount = parseInt(total, 10)
      if (amount <= 0) {
        throw new Error('Invalid float amount <= 0')
      }

      let response
      // let roles = []
      // response = await ApiUser.getRoles(source.token, tableUuid)
      response = await ApiUser.login(source.token, 'admin', '11111111')
      if (!response || !response.data || !response.data.access_token) {
        throw new Error('invalid login response or data or token')
      }
      let data = response.data
      // Log.log('dialogupdate: logged in', data)
      // let token = data.access_token
      // await dispatch(updateToken(token))

      // save role info
      // let all = roles
      // let found = _.find(all, (a) => a.id === tableRoleId)
      // if (found) {
      //   found.user = {
      //     ...data,
      //     'role_id': tableRoleId,
      //   }
      // }
      // setRoles([].concat(all))
      // let isAllLogged = isRolesLoggedIn()
      // Toast.show(`Role ID: ${tableRoleId} Logged In!, isAllLogged?: ${isAllLogged ? 'true' : 'false'}`)
      const chipsQty = extractChipsWithQty(chipSets)
      response = await ApiTable.setFloat(source.token, tableUuid, chipsQty, data.access_token)
      resetChipQty(chipSets)
      setState('success')

    } catch (error) {
      Toast.exception(error, 'Update Failed')
    }
    setLoading(false)
  }, [total/* tableRoleId, roles */])

  // const onClose = useCallback(() => {
  //   setVisible(false)
  //   setState(undefined)
  // }, [])

  const renderLogin = () => {
    return (
      <TouchableOpacity style={styles.messageWrapper} onPress={onLoginAndUpdate}>
        <Text style={styles.message}>Please Swipe/Scan</Text>
        <Text style={styles.message}>Supervisor or Manager ID</Text>
      </TouchableOpacity>
    )
  }

  const renderSuccess = () => {
    return (
      <TouchableOpacity style={styles.messageWrapper} onPress={onUpdateSuccessPress}>
        <Text style={styles.message}>Update Successful</Text>
      </TouchableOpacity>
    )
  }

  return (
    <Dialog
      visible={visible}
      minWidth={'60%'}
      minHeight={'50%'}
      setVisible={setVisible}
      showHeader={false}
      style={styles.dialog}>
      <View style={[styles.wrapper]}>
        {
          state == null || (state && state.length <= 0) ?
            renderLogin() : null
        }
        {
          state === 'success' ?
            renderSuccess() : null
        }
      </View>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_BG,
    padding: scaleSize(10),
  },
  // content
  wrapper: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    // height: '100%',
    // justifyContent: 'center',
    alignItems: 'stretch',
    // flex: 1,
    flexGrow: 1,
    paddingHorizontal: scaleSize(50),
    paddingVertical: scaleSize(20),
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
  },
  messageWrapper: {
    // width: '70%',
    height: '100%',
    // marginTop: 10 * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
    // marginRight: SC.GAP_S * SC.SCALE,
    borderRadius: scaleSize(9),
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    // alignContent: 'stretch',
    flex: 1,
    flexGrow: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  message: {
    color: 'white',
    fontSize: scaleSize(18),
    fontWeight: 'bold',
  },
  button: {
    paddingVertical: 3 * SC.SCALE,
    marginTop: 4 * SC.SCALE,
  },
})

DialogUpdate.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  tableUuid: PropTypes.string,
  total: PropTypes.number,
  chipSets: PropTypes.any,
  onUpdateSuccessPress: PropTypes.func,
}

export default DialogUpdate
